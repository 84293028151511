import { Interest, InterestQuestion } from '@career-scope/models';

export const testerlyInterestQuestions: InterestQuestion[] = [
  {
    "id": 1000,
    "question": "Wait tables in a restaurant.",
    "order": 0
  },
  {
    "id": 1001,
    "question": "Build custom wood cabinets.",
    "order": 1
  },
  {
    "id": 1002,
    "question": "Study how people in different societies live and understand the world.",
    "order": 2
  },
  {
    "id": 1003,
    "question": "Run a branch of a larger company.",
    "order": 3
  },
  {
    "id": 1004,
    "question": "Operate machines in a factory.",
    "order": 4
  },
  {
    "id": 1005,
    "question": "Greet visitors in an office reception area.",
    "order": 5
  },
  {
    "id": 1006,
    "question": "Provide nursing care to hospital patients in critical condition.",
    "order": 6
  },
  {
    "id": 1007,
    "question": "Drive a truck transporting packages or freight.",
    "order": 7
  },
  {
    "id": 1008,
    "question": "Design scientific studies to test a new medical treatment.",
    "order": 8
  },
  {
    "id": 1009,
    "question": "Design graphics for websites.",
    "order": 9
  },
  {
    "id": 1010,
    "question": "Persuade people to purchase a product.",
    "order": 10
  },
  {
    "id": 1011,
    "question": "Fight fires.",
    "order": 11
  },
  {
    "id": 1012,
    "question": "Write novels.",
    "order": 12
  },
  {
    "id": 1013,
    "question": "Drive equipment to till soil and harvest crops.",
    "order": 13
  },
  {
    "id": 1014,
    "question": "Conduct social science experiments.",
    "order": 14
  },
  {
    "id": 1015,
    "question": "Audit a company's accounting records.",
    "order": 15
  },
  {
    "id": 1016,
    "question": "Clean hotel rooms.",
    "order": 16
  },
  {
    "id": 1017,
    "question": "Rehearse lines for a play.",
    "order": 17
  },
  {
    "id": 1018,
    "question": "Take a leadership role in a company.",
    "order": 18
  },
  {
    "id": 1019,
    "question": "Repair vehicles (e.g., cars, trucks, bikes).",
    "order": 19
  },
  {
    "id": 1020,
    "question": "Perform experiments in quantum physics.",
    "order": 20
  },
  {
    "id": 1021,
    "question": "Help customers find products in a store.",
    "order": 21
  },
  {
    "id": 1022,
    "question": "Inspect restaurants for health code violations.",
    "order": 22
  },
  {
    "id": 1023,
    "question": "Advise clients on what choices to make.",
    "order": 23
  },
  {
    "id": 1024,
    "question": "Apply statistics to interpret data.",
    "order": 24
  },
  {
    "id": 1025,
    "question": "Teach students in a classroom.",
    "order": 25
  },
  {
    "id": 1026,
    "question": "Enlist in the armed forces.",
    "order": 26
  },
  {
    "id": 1027,
    "question": "Serve as your community's political representative.",
    "order": 27
  },
  {
    "id": 1028,
    "question": "Cook meals for customers.",
    "order": 28
  },
  {
    "id": 1029,
    "question": "Administer medical exams (e.g., checkups, hearing tests, X-rays, CT scans, MRI scans).",
    "order": 29
  },
  {
    "id": 1030,
    "question": "Rescue stranded people.",
    "order": 30
  },
  {
    "id": 1031,
    "question": "Report on important news stories.",
    "order": 31
  },
  {
    "id": 1032,
    "question": "Prepare tissue samples for analysis.",
    "order": 32
  },
  {
    "id": 1033,
    "question": "Develop online help documentation.",
    "order": 33
  },
  {
    "id": 1034,
    "question": "Build fences.",
    "order": 34
  },
  {
    "id": 1035,
    "question": "Provide physical therapy to people with injuries.",
    "order": 35
  },
  {
    "id": 1036,
    "question": "Coach a sports team.",
    "order": 36
  },
  {
    "id": 1037,
    "question": "Oversee the hiring and recruiting activities for a company.",
    "order": 37
  },
  {
    "id": 1038,
    "question": "Examine someone's teeth or gums for signs of problems.",
    "order": 38
  },
  {
    "id": 1039,
    "question": "Manage a public forest to conserve wildlife habitats.",
    "order": 39
  },
  {
    "id": 1040,
    "question": "Provide after-school care for children.",
    "order": 40
  },
  {
    "id": 1041,
    "question": "Work in an animal shelter.",
    "order": 41
  },
  {
    "id": 1042,
    "question": "Administer eye exams in an optometrist's office.",
    "order": 42
  },
  {
    "id": 1043,
    "question": "Represent clients in lawsuits.",
    "order": 43
  },
  {
    "id": 1044,
    "question": "Create detailed blueprints.",
    "order": 44
  },
  {
    "id": 1045,
    "question": "Write apps for phones and tablets.",
    "order": 45
  },
  {
    "id": 1046,
    "question": "Sketch designs for a costume.",
    "order": 46
  },
  {
    "id": 1047,
    "question": "Provide counselling to families.",
    "order": 47
  },
  {
    "id": 1048,
    "question": "Create ads to sell a product.",
    "order": 48
  },
  {
    "id": 1049,
    "question": "Pilot an airplane.",
    "order": 49
  },
  {
    "id": 1050,
    "question": "Give a presentation.",
    "order": 50
  },
  {
    "id": 1051,
    "question": "Take a course about navigating at sea.",
    "order": 51
  },
  {
    "id": 1052,
    "question": "Assess environmental damage and identify restoration needs.",
    "order": 52
  },
  {
    "id": 1053,
    "question": "Play music in a band.",
    "order": 53
  },
  {
    "id": 1054,
    "question": "Be a stay-at-home parent.",
    "order": 54
  },
  {
    "id": 1055,
    "question": "Guide people in prayer.",
    "order": 55
  },
  {
    "id": 1056,
    "question": "Invest a client's money in stocks and bonds.",
    "order": 56
  },
  {
    "id": 1057,
    "question": "Be part of a railway crew working on a train.",
    "order": 57
  },
  {
    "id": 1058,
    "question": "Spoon-feed someone in a nursing home.",
    "order": 58
  },
  {
    "id": 1059,
    "question": "Enter data into a computer.",
    "order": 59
  },
  {
    "id": 1060,
    "question": "Examine insurance claims to determine if they are valid.",
    "order": 60
  },
  {
    "id": 1061,
    "question": "Install light fixtures and electrical outlets in buildings.",
    "order": 61
  },
  {
    "id": 1062,
    "question": "Assemble items on an assembly line.",
    "order": 62
  },
  {
    "id": 1063,
    "question": "Install electrical equipment.",
    "order": 63
  },
  {
    "id": 1064,
    "question": "Investigate rock and mineral formations.",
    "order": 64
  },
  {
    "id": 1065,
    "question": "Stitch a wound.",
    "order": 65
  },
  {
    "id": 1066,
    "question": "Design structures like bridges or dams.",
    "order": 66
  },
  {
    "id": 1067,
    "question": "Administer doctor-prescribed medication in a hospital.",
    "order": 67
  },
  {
    "id": 1068,
    "question": "Conduct security screens at an airport.",
    "order": 68
  },
  {
    "id": 1069,
    "question": "Help people bathe, dress, and use the toilet.",
    "order": 69
  },
  {
    "id": 1070,
    "question": "Research historical documents and sources.",
    "order": 70
  },
  {
    "id": 1071,
    "question": "Cut hair in a salon.",
    "order": 71
  },
  {
    "id": 1072,
    "question": "Explore options to save a failing business.",
    "order": 72
  },
  {
    "id": 1073,
    "question": "Organize files in an office.",
    "order": 73
  },
  {
    "id": 1074,
    "question": "Operate a backhoe at a construction site.",
    "order": 74
  },
  {
    "id": 1075,
    "question": "Investigate how a gene alters a drug's effect on cells.",
    "order": 75
  },
  {
    "id": 1076,
    "question": "Create life-like artificial body parts for people who need them.",
    "order": 76
  },
  {
    "id": 1077,
    "question": "Sell advertising space.",
    "order": 77
  },
  {
    "id": 1078,
    "question": "Edit short stories.",
    "order": 78
  },
  {
    "id": 1079,
    "question": "Feed farm animals.",
    "order": 79
  },
  {
    "id": 1080,
    "question": "Study trends in the economy to make predictions.",
    "order": 80
  },
  {
    "id": 1081,
    "question": "Prepare financial statements.",
    "order": 81
  },
  {
    "id": 1082,
    "question": "Polish floors in a hotel lobby.",
    "order": 82
  },
  {
    "id": 1083,
    "question": "Contribute behind the stage in shows.",
    "order": 83
  },
  {
    "id": 1084,
    "question": "Direct staff at a hotel.",
    "order": 84
  },
  {
    "id": 1085,
    "question": "Resolve customer complaints.",
    "order": 85
  },
  {
    "id": 1086,
    "question": "Give career advice to job seekers.",
    "order": 86
  },
  {
    "id": 1087,
    "question": "Study advanced mathematics.",
    "order": 87
  },
  {
    "id": 1088,
    "question": "Grade tests and assignments.",
    "order": 88
  },
  {
    "id": 1089,
    "question": "Serve as a peacekeeper in areas experiencing conflict.",
    "order": 89
  },
  {
    "id": 1090,
    "question": "Convince voters to support legislation.",
    "order": 90
  },
  {
    "id": 1091,
    "question": "Bake bread and deserts in a bakery.",
    "order": 91
  },
  {
    "id": 1092,
    "question": "Do work that is physically dangerous.",
    "order": 92
  },
  {
    "id": 1093,
    "question": "Produce digital media content for clients.",
    "order": 93
  },
  {
    "id": 1094,
    "question": "Study the diet of an animal species.",
    "order": 94
  },
  {
    "id": 1095,
    "question": "Correct errors in product descriptions.",
    "order": 95
  },
  {
    "id": 1096,
    "question": "Fetch items in a warehouse.",
    "order": 96
  },
  {
    "id": 1097,
    "question": "Give radiation therapy to people with cancer.",
    "order": 97
  },
  {
    "id": 1098,
    "question": "Direct exercises in a fitness class.",
    "order": 98
  },
  {
    "id": 1099,
    "question": "Conduct surveys to find out what people really think.",
    "order": 99
  },
  {
    "id": 1100,
    "question": "Advise dental patients on how to keep their teeth healthy.",
    "order": 100
  },
  {
    "id": 1101,
    "question": "Grow plants in a nursery.",
    "order": 101
  },
  {
    "id": 1102,
    "question": "Run activities for teenagers and young adults.",
    "order": 102
  },
  {
    "id": 1103,
    "question": "Provide care to animals in a zoo.",
    "order": 103
  },
  {
    "id": 1104,
    "question": "Diagnose and treat vision problems.",
    "order": 104
  },
  {
    "id": 1105,
    "question": "Prepare legal documents.",
    "order": 105
  },
  {
    "id": 1106,
    "question": "Troubleshoot computer problems.",
    "order": 106
  },
  {
    "id": 1107,
    "question": "Create works of art.",
    "order": 107
  },
  {
    "id": 1108,
    "question": "Find support resources for students.",
    "order": 108
  },
  {
    "id": 1109,
    "question": "Conduct market research, checking consumer demand for a product or service.",
    "order": 109
  },
  {
    "id": 1110,
    "question": "Direct air traffic around an airport.",
    "order": 110
  },
  {
    "id": 1111,
    "question": "Record your voice for a podcast.",
    "order": 111
  },
  {
    "id": 1112,
    "question": "Operate a boat.",
    "order": 112
  },
  {
    "id": 1113,
    "question": "Convert industrial sites back into natural habitats.",
    "order": 113
  },
  {
    "id": 1114,
    "question": "Write music.",
    "order": 114
  },
  {
    "id": 1115,
    "question": "Arrange play dates for your kids.",
    "order": 115
  },
  {
    "id": 1116,
    "question": "Explore your spirituality.",
    "order": 116
  },
  {
    "id": 1117,
    "question": "Analyze credit history to make loan decisions.",
    "order": 117
  },
  {
    "id": 1118,
    "question": "Operate subway cars.",
    "order": 118
  },
  {
    "id": 1119,
    "question": "Perform quality control checks on products.",
    "order": 119
  },
  {
    "id": 1120,
    "question": "Fix broken plumbing fixtures.",
    "order": 120
  },
  {
    "id": 1121,
    "question": "Operate equipment in a power plant.",
    "order": 121
  },
  {
    "id": 1122,
    "question": "Repair broken equipment in a factory.",
    "order": 122
  },
  {
    "id": 1123,
    "question": "Perform chemistry research for new battery types.",
    "order": 123
  },
  {
    "id": 1124,
    "question": "Administer a hearing test.",
    "order": 124
  },
  {
    "id": 1125,
    "question": "Develop diagnostic routines for a power plant.",
    "order": 125
  },
  {
    "id": 1126,
    "question": "Book meeting times for office executives.",
    "order": 126
  },
  {
    "id": 1127,
    "question": "Monitor the condition of patients in a hospital.",
    "order": 127
  },
  {
    "id": 1128,
    "question": "Patrol the street to enforce laws.",
    "order": 128
  },
  {
    "id": 1129,
    "question": "Perform a security audit to find vulnerabilities.",
    "order": 129
  },
  {
    "id": 1130,
    "question": "Serve food and drink to patients in a hospital.",
    "order": 130
  },
  {
    "id": 1131,
    "question": "Build a wall out of bricks or stone.",
    "order": 131
  },
  {
    "id": 1132,
    "question": "Paint items in a factory.",
    "order": 132
  },
  {
    "id": 1133,
    "question": "Install and maintain high-tech equipment.",
    "order": 133
  },
  {
    "id": 1134,
    "question": "Forecast weather using data from satellites and ground stations.",
    "order": 134
  },
  {
    "id": 1135,
    "question": "Perform surgery in a hospital.",
    "order": 135
  },
  {
    "id": 1136,
    "question": "Create maps and charts.",
    "order": 136
  },
  {
    "id": 1137,
    "question": "Study philosophy (e.g., the nature of knowledge, ethics, reality, and existence).",
    "order": 137
  },
  {
    "id": 1138,
    "question": "Provide advice to tourists.",
    "order": 138
  },
  {
    "id": 1139,
    "question": "Learn about how to make a successful business.",
    "order": 139
  },
  {
    "id": 1140,
    "question": "Take messages and forward calls in an office.",
    "order": 140
  },
  {
    "id": 1141,
    "question": "Operate a crane, moving cargo or construction materials.",
    "order": 141
  },
  {
    "id": 1142,
    "question": "Research the cause and treatment of diseases.",
    "order": 142
  },
  {
    "id": 1143,
    "question": "Design indoor and outdoor spaces.",
    "order": 143
  },
  {
    "id": 1144,
    "question": "Increase sales in your territory.",
    "order": 144
  },
  {
    "id": 1145,
    "question": "Write social media posts for clients.",
    "order": 145
  },
  {
    "id": 1146,
    "question": "Prune trees in an orchard.",
    "order": 146
  },
  {
    "id": 1147,
    "question": "Conduct research on political subjects (e.g., policies, legislation, ideologies).",
    "order": 147
  },
  {
    "id": 1148,
    "question": "Prepare tax returns for clients.",
    "order": 148
  },
  {
    "id": 1149,
    "question": "Clean and fold towels at a spa.",
    "order": 149
  },
  {
    "id": 1150,
    "question": "Entertain an audience.",
    "order": 150
  },
  {
    "id": 1151,
    "question": "Supervise employees.",
    "order": 151
  },
  {
    "id": 1152,
    "question": "Listen to customer needs and propose solutions.",
    "order": 152
  },
  {
    "id": 1153,
    "question": "Consult on how businesses can solve their most pressing issues.",
    "order": 153
  },
  {
    "id": 1154,
    "question": "Produce charts to interpret data.",
    "order": 154
  },
  {
    "id": 1155,
    "question": "Design activities for student learning.",
    "order": 155
  },
  {
    "id": 1156,
    "question": "Train as a soldier for combat operations.",
    "order": 156
  },
  {
    "id": 1157,
    "question": "Serve on political committees and counsels.",
    "order": 157
  },
  {
    "id": 1158,
    "question": "Mix and serve drinks to customers.",
    "order": 158
  },
  {
    "id": 1159,
    "question": "Save people from drowning.",
    "order": 159
  },
  {
    "id": 1160,
    "question": "Be an influencer on social media.",
    "order": 160
  },
  {
    "id": 1161,
    "question": "View cell samples under a microscope.",
    "order": 161
  },
  {
    "id": 1162,
    "question": "Write a scientific article for publication in academic journals.",
    "order": 162
  },
  {
    "id": 1163,
    "question": "Operate a machine that mines metal from rock.",
    "order": 163
  },
  {
    "id": 1164,
    "question": "Provide massage therapy to help people relax and maintain health.",
    "order": 164
  },
  {
    "id": 1165,
    "question": "Be a professional athlete.",
    "order": 165
  },
  {
    "id": 1166,
    "question": "Train new employees.",
    "order": 166
  },
  {
    "id": 1167,
    "question": "Clean the teeth of patients visiting a dentist office.",
    "order": 167
  },
  {
    "id": 1168,
    "question": "Set traps to catch wildlife.",
    "order": 168
  },
  {
    "id": 1169,
    "question": "Supervise children during recess.",
    "order": 169
  },
  {
    "id": 1170,
    "question": "Clean animals and their living spaces.",
    "order": 170
  },
  {
    "id": 1171,
    "question": "Prescribe glasses or contact lenses.",
    "order": 171
  },
  {
    "id": 1172,
    "question": "Judge disputes in small claims court.",
    "order": 172
  },
  {
    "id": 1173,
    "question": "Develop websites.",
    "order": 173
  },
  {
    "id": 1174,
    "question": "Take photos at weddings.",
    "order": 174
  },
  {
    "id": 1175,
    "question": "Assess mental health problems.",
    "order": 175
  },
  {
    "id": 1176,
    "question": "Create interest in an upcoming product or service.",
    "order": 176
  },
  {
    "id": 1177,
    "question": "Navigate an aircraft along scheduled routes.",
    "order": 177
  },
  {
    "id": 1178,
    "question": "Speak in front of an audience.",
    "order": 178
  },
  {
    "id": 1179,
    "question": "Spend months at a time at sea.",
    "order": 179
  },
  {
    "id": 1180,
    "question": "Advise businesses on how to produce less pollution.",
    "order": 180
  },
  {
    "id": 1181,
    "question": "Play a musical instrument.",
    "order": 181
  },
  {
    "id": 1182,
    "question": "Grocery shop for your family.",
    "order": 182
  },
  {
    "id": 1183,
    "question": "Provide spiritual guidance.",
    "order": 183
  },
  {
    "id": 1184,
    "question": "Study economics - how society distributes its resources to produce goods and services.",
    "order": 184
  },
  {
    "id": 1185,
    "question": "Operate railroad track switches.",
    "order": 185
  },
  {
    "id": 1186,
    "question": "Verify that plans meet legal requirements.",
    "order": 186
  },
  {
    "id": 1187,
    "question": "Install flooring in homes.",
    "order": 187
  },
  {
    "id": 1188,
    "question": "Operate equipment that cooks or bakes large quantities of food.",
    "order": 188
  },
  {
    "id": 1189,
    "question": "Prepare basic reports of monthly office activity.",
    "order": 189
  },
  {
    "id": 1190,
    "question": "Log daily medications given to a patient.",
    "order": 190
  },
  {
    "id": 1191,
    "question": "Investigate crimes.",
    "order": 191
  },
  {
    "id": 1192,
    "question": "Repair appliances.",
    "order": 192
  },
  {
    "id": 1193,
    "question": "Study planets in other solar systems.",
    "order": 193
  },
  {
    "id": 1194,
    "question": "Advise people on how to maintain or improve their health.",
    "order": 194
  },
  {
    "id": 1195,
    "question": "Design a machine using modeling software.",
    "order": 195
  },
  {
    "id": 1196,
    "question": "Use an overhead lift to help patients get out of bed.",
    "order": 196
  },
  {
    "id": 1197,
    "question": "Verify that contents of a shipping container match its manifest.",
    "order": 197
  },
  {
    "id": 1198,
    "question": "Investigate ancient cultures.",
    "order": 198
  },
  {
    "id": 1199,
    "question": "Plan weddings and other events for clients.",
    "order": 199
  },
  {
    "id": 1200,
    "question": "Negotiate business contracts.",
    "order": 200
  },
  {
    "id": 1201,
    "question": "Process payments at the front desk of an office.",
    "order": 201
  },
  {
    "id": 1202,
    "question": "Drive a bus.",
    "order": 202
  },
  {
    "id": 1203,
    "question": "Research how proteins fold.",
    "order": 203
  },
  {
    "id": 1204,
    "question": "Design packaging for products.",
    "order": 204
  },
  {
    "id": 1205,
    "question": "Convince people to sign up for a service.",
    "order": 205
  },
  {
    "id": 1206,
    "question": "Write dialog for movies.",
    "order": 206
  },
  {
    "id": 1207,
    "question": "Grow food in a greenhouse.",
    "order": 207
  },
  {
    "id": 1208,
    "question": "Study how people interact in a team.",
    "order": 208
  },
  {
    "id": 1209,
    "question": "Check financial records to detect fraud.",
    "order": 209
  },
  {
    "id": 1210,
    "question": "Vacuum carpets in an office building.",
    "order": 210
  },
  {
    "id": 1211,
    "question": "Dance on stage.",
    "order": 211
  },
  {
    "id": 1212,
    "question": "Be in command of a team.",
    "order": 212
  },
  {
    "id": 1213,
    "question": "Handle disgruntled customers.",
    "order": 213
  },
  {
    "id": 1214,
    "question": "Be a consultant hired to give advice.",
    "order": 214
  },
  {
    "id": 1215,
    "question": "Apply math theories to solve practical problems.",
    "order": 215
  },
  {
    "id": 1216,
    "question": "Supervise a student project.",
    "order": 216
  },
  {
    "id": 1217,
    "question": "Operate military equipment.",
    "order": 217
  },
  {
    "id": 1218,
    "question": "Serve on a politician's staff, helping them govern.",
    "order": 218
  },
  {
    "id": 1219,
    "question": "Prepare a variety of meals all at once.",
    "order": 219
  },
  {
    "id": 1220,
    "question": "Rescue or save people.",
    "order": 220
  },
  {
    "id": 1221,
    "question": "Film a commercial for an upcoming product.",
    "order": 221
  },
  {
    "id": 1222,
    "question": "Investigate how each organism fits together in an ecosystem.",
    "order": 222
  },
  {
    "id": 1223,
    "question": "Proofread a user guide, correcting errors.",
    "order": 223
  },
  {
    "id": 1224,
    "question": "Perform physical labor at a construction site.",
    "order": 224
  },
  {
    "id": 1225,
    "question": "Show people how to perform exercises that will help them recover.",
    "order": 225
  },
  {
    "id": 1226,
    "question": "Talk with professional athletes every day.",
    "order": 226
  },
  {
    "id": 1227,
    "question": "Interview people applying for a job or loan.",
    "order": 227
  },
  {
    "id": 1228,
    "question": "Adjust dental braces for straightening teeth.",
    "order": 228
  },
  {
    "id": 1229,
    "question": "Work outside in all weather.",
    "order": 229
  },
  {
    "id": 1230,
    "question": "Provide care to children and infants.",
    "order": 230
  },
  {
    "id": 1231,
    "question": "Train animals to perform complex tasks.",
    "order": 231
  },
  {
    "id": 1232,
    "question": "Adjust glasses to correct someone's vision.",
    "order": 232
  },
  {
    "id": 1233,
    "question": "Review prior case law.",
    "order": 233
  },
  {
    "id": 1234,
    "question": "Increase the efficiency of computer networks.",
    "order": 234
  },
  {
    "id": 1235,
    "question": "Make decorative pottery.",
    "order": 235
  },
  {
    "id": 1236,
    "question": "Provide social services to children in need.",
    "order": 236
  },
  {
    "id": 1237,
    "question": "Gather information about a company's target audience.",
    "order": 237
  },
  {
    "id": 1238,
    "question": "Work around airplanes.",
    "order": 238
  },
  {
    "id": 1239,
    "question": "Speak on live radio.",
    "order": 239
  },
  {
    "id": 1240,
    "question": "Work on a cruise ship.",
    "order": 240
  },
  {
    "id": 1241,
    "question": "Conduct an energy audit to find ways to help companies use less energy.",
    "order": 241
  },
  {
    "id": 1242,
    "question": "Sing for an audience.",
    "order": 242
  },
  {
    "id": 1243,
    "question": "Stay at home to raise your children.",
    "order": 243
  },
  {
    "id": 1244,
    "question": "Help people interpret holy texts.",
    "order": 244
  },
  {
    "id": 1245,
    "question": "Evaluate insurance needs.",
    "order": 245
  },
  {
    "id": 1246,
    "question": "Drive trains transporting passengers or freight.",
    "order": 246
  },
  {
    "id": 1247,
    "question": "Perform routine maintenance tasks around a building.",
    "order": 247
  },
  {
    "id": 1248,
    "question": "Set up and operate factory equipment.",
    "order": 248
  },
  {
    "id": 1249,
    "question": "Install renewable energy systems (e.g., solar, wind).",
    "order": 249
  },
  {
    "id": 1250,
    "question": "Research the movement of water underground.",
    "order": 250
  },
  {
    "id": 1251,
    "question": "Diagnose illness based on test results and symptoms.",
    "order": 251
  },
  {
    "id": 1252,
    "question": "Modify a building's design to improve its earthquake resistance.",
    "order": 252
  },
  {
    "id": 1253,
    "question": "Pass tools to a doctor performing a surgery.",
    "order": 253
  },
  {
    "id": 1254,
    "question": "Guard inmates in a prison.",
    "order": 254
  },
  {
    "id": 1255,
    "question": "Inspect vehicles and equipment to ensure they meet safety standards.",
    "order": 255
  },
  {
    "id": 1256,
    "question": "Take a long-term care resident for a walk around the facility.",
    "order": 256
  },
  {
    "id": 1257,
    "question": "Sort mail.",
    "order": 257
  },
  {
    "id": 1258,
    "question": "Install tile in a bathroom.",
    "order": 258
  },
  {
    "id": 1259,
    "question": "Cut and trim meat.",
    "order": 259
  },
  {
    "id": 1260,
    "question": "Fix broken vehicles.",
    "order": 260
  },
  {
    "id": 1261,
    "question": "Analyze the structure and performance of new materials.",
    "order": 261
  },
  {
    "id": 1262,
    "question": "Prescribe pain medication.",
    "order": 262
  },
  {
    "id": 1263,
    "question": "Design an intersection that reduces traffic accidents.",
    "order": 263
  },
  {
    "id": 1264,
    "question": "Inspect buildings to ensure compliance with bylaws.",
    "order": 264
  },
  {
    "id": 1265,
    "question": "Conduct research on the structure and grammar of languages.",
    "order": 265
  },
  {
    "id": 1266,
    "question": "Do chores for customers (e.g., run errands, carry bags, park cars).",
    "order": 266
  },
  {
    "id": 1267,
    "question": "Develop business plans for a company.",
    "order": 267
  },
  {
    "id": 1268,
    "question": "Be the note taker for a meeting, sharing your notes with all attendees.",
    "order": 268
  },
  {
    "id": 1269,
    "question": "Drive a paving machine to level a road.",
    "order": 269
  },
  {
    "id": 1270,
    "question": "Investigate the patterns and causes of a disease.",
    "order": 270
  },
  {
    "id": 1271,
    "question": "Create visual designs for clients.",
    "order": 271
  },
  {
    "id": 1272,
    "question": "Upsell a customer, getting them to buy more.",
    "order": 272
  },
  {
    "id": 1273,
    "question": "Write poetry.",
    "order": 273
  },
  {
    "id": 1274,
    "question": "Raise a variety of farm animals.",
    "order": 274
  },
  {
    "id": 1275,
    "question": "Research economic topics for businesses and governments.",
    "order": 275
  },
  {
    "id": 1276,
    "question": "Maintain a client's financial records.",
    "order": 276
  },
  {
    "id": 1277,
    "question": "Scrub and disinfect bathrooms.",
    "order": 277
  },
  {
    "id": 1278,
    "question": "Control the sound equipment in a live show.",
    "order": 278
  },
  {
    "id": 1279,
    "question": "Manage other workers.",
    "order": 279
  },
  {
    "id": 1280,
    "question": "Help customers find the right solution for their needs.",
    "order": 280
  },
  {
    "id": 1281,
    "question": "Provide advice to businesses on how to solve specific challenges.",
    "order": 281
  },
  {
    "id": 1282,
    "question": "Run statistical analyses on a dataset.",
    "order": 282
  },
  {
    "id": 1283,
    "question": "Develop teaching materials.",
    "order": 283
  },
  {
    "id": 1284,
    "question": "Operate weapons in ground combat operations.",
    "order": 284
  },
  {
    "id": 1285,
    "question": "Negotiate with politicians.",
    "order": 285
  },
  {
    "id": 1286,
    "question": "Oversee food supplies of a restaurant.",
    "order": 286
  },
  {
    "id": 1287,
    "question": "Perform work that is dangerous and exciting.",
    "order": 287
  },
  {
    "id": 1288,
    "question": "Work on the set of a movie.",
    "order": 288
  },
  {
    "id": 1289,
    "question": "Conduct research on plants and crops.",
    "order": 289
  },
  {
    "id": 1290,
    "question": "Translate documents into other languages.",
    "order": 290
  },
  {
    "id": 1291,
    "question": "Maintain lawns and gardens.",
    "order": 291
  },
  {
    "id": 1292,
    "question": "Evaluate someone's range of motion after an injury.",
    "order": 292
  },
  {
    "id": 1293,
    "question": "Develop exercise routines for clients wanting to lose weight.",
    "order": 293
  },
  {
    "id": 1294,
    "question": "Administer a company's compensation and benefits program, ensuring employees get paid fairly.",
    "order": 294
  },
  {
    "id": 1295,
    "question": "Fill cavities in teeth.",
    "order": 295
  },
  {
    "id": 1296,
    "question": "Cut down trees using a chainsaw.",
    "order": 296
  },
  {
    "id": 1297,
    "question": "Run activities for young children.",
    "order": 297
  },
  {
    "id": 1298,
    "question": "Treat injured or sick animals.",
    "order": 298
  },
  {
    "id": 1299,
    "question": "Examine someone's eyes, looking for the cause of vision problems.",
    "order": 299
  },
  {
    "id": 1300,
    "question": "Write legal opinions.",
    "order": 300
  },
  {
    "id": 1301,
    "question": "Maintain computer hardware in a datacenter.",
    "order": 301
  },
  {
    "id": 1302,
    "question": "Curate a collection of historic objects.",
    "order": 302
  },
  {
    "id": 1303,
    "question": "Provide therapy to people who have a mental illness.",
    "order": 303
  },
  {
    "id": 1304,
    "question": "Create social media content promoting a product or service.",
    "order": 304
  },
  {
    "id": 1305,
    "question": "Ensure the safety and comfort of passengers during flights.",
    "order": 305
  },
  {
    "id": 1306,
    "question": "Read announcements into a microphone.",
    "order": 306
  },
  {
    "id": 1307,
    "question": "Learn to navigate large ships.",
    "order": 307
  },
  {
    "id": 1308,
    "question": "Help a company reduce its environmental footprint, using fewer resources.",
    "order": 308
  },
  {
    "id": 1309,
    "question": "Conduct an orchestra.",
    "order": 309
  },
  {
    "id": 1310,
    "question": "Do your family's household chores.",
    "order": 310
  },
  {
    "id": 1311,
    "question": "Participate in group meetings exploring a religion.",
    "order": 311
  },
  {
    "id": 1312,
    "question": "Create financial plans for clients.",
    "order": 312
  },
  {
    "id": 1313,
    "question": "Perform maintenance on a train.",
    "order": 313
  },
  {
    "id": 1314,
    "question": "Replace the bandages on a patient's wound.",
    "order": 314
  },
  {
    "id": 1315,
    "question": "Collect evidence at crime scenes.",
    "order": 315
  },
  {
    "id": 1316,
    "question": "Monitor patients for any problems that should be reported to a nurse.",
    "order": 316
  },
  {
    "id": 1317,
    "question": "Build the frame of a house.",
    "order": 317
  },
  {
    "id": 1318,
    "question": "Tailor clothes to adjust how they fit.",
    "order": 318
  },
  {
    "id": 1319,
    "question": "Repair electronics.",
    "order": 319
  },
  {
    "id": 1320,
    "question": "Investigate chemical reactions.",
    "order": 320
  },
  {
    "id": 1321,
    "question": "Suggest naturopathic herbs and treatments.",
    "order": 321
  },
  {
    "id": 1322,
    "question": "Design chemical plant equipment.",
    "order": 322
  },
  {
    "id": 1323,
    "question": "Read guages to monitor usage of gas, electricity, or water.",
    "order": 323
  },
  {
    "id": 1324,
    "question": "Perform routine bookkeeping for an office.",
    "order": 324
  }
]

export const spanishTesterlyInterestQuestions: InterestQuestion[] = [
  // Add Spanish versions of Testerly questions here
  {
    id: 1001,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here',
    order: 1
  },
  {
    id: 1002,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 2',
    order: 2
  },
  {
    id: 1003,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 3',
    order: 3
  },
  {
    id: 1004,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 4',
    order: 4
  },
  {
    id: 1005,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 5',
    order: 5
  },
  // ... more questions ...
]; 

export const testerlyInterests: Interest[] = [
  {
    interestCategory: 'Art',
    name: 'Artistic - Testerly',
    description: 'An interest in the creative expression of feelings or ideas.',
    jobTasks: 'An interest in creative expression of feeling or ideas through literary arts, visual arts, performing arts, or crafts.',
    jobExamples: 'Writer, Painter, Actor, Editor, Dancer, Singer, Graphic Designer, Set Designer',
    color: '#ee509c',
    svgLogo: '/assets/images/icons/report_interest_artistic_icon.svg',
    whiteSvgLogo: '/assets/images/icons/report_interest_artistic_icon_white.svg',
    totalQuestions: 14,
  },
];

export const spanishTesterlyInterests: Interest[] = [
  {
    interestCategory: 'Art',
    name: 'Artistic - Spanish',
    description: 'An interest in the creative expression of feelings or ideas.',
    jobTasks: 'An interest in creative expression of feeling or ideas through literary arts, visual arts, performing arts, or crafts.',
    jobExamples: 'Writer, Painter, Actor, Editor, Dancer, Singer, Graphic Designer, Set Designer',
    color: '#ee509c',
    svgLogo: '/assets/images/icons/report_interest_artistic_icon.svg',
    whiteSvgLogo: '/assets/images/icons/report_interest_artistic_icon_white.svg',
    totalQuestions: 14,
  },
]; 