export const testerlyItems = [
  {
    "id": "e99b227d-f136-4a77-9579-763d76b448d1",
    "stem": "Wait tables in a restaurant.",
    "administration_position": 0
  },
  {
    "id": "ca5753cc-17e9-4758-8b4e-f2079c32f877",
    "stem": "Build custom wood cabinets.",
    "administration_position": 1
  },
  {
    "id": "732434bd-a18f-4cbe-8c7a-16b1f36256f0",
    "stem": "Study how people in different societies live and understand the world.",
    "administration_position": 2
  },
  {
    "id": "e095c698-dfb9-421a-b7b1-6b51651b615a",
    "stem": "Run a branch of a larger company.",
    "administration_position": 3
  },
  {
    "id": "da7ca668-4861-4bb3-a165-9b3331e0009a",
    "stem": "Operate machines in a factory.",
    "administration_position": 4
  },
  {
    "id": "0621df3d-f96d-41af-a8a3-87f65e885d0e",
    "stem": "Greet visitors in an office reception area.",
    "administration_position": 5
  },
  {
    "id": "3e6fbaa4-ecd3-46a5-8120-8f2d63627fbe",
    "stem": "Provide nursing care to hospital patients in critical condition.",
    "administration_position": 6
  },
  {
    "id": "16be043f-f3e3-4a35-be7a-9ff73a231554",
    "stem": "Drive a truck transporting packages or freight.",
    "administration_position": 7
  },
  {
    "id": "ef819cb4-6ea7-464b-aef1-2fbb9daf6b5e",
    "stem": "Design scientific studies to test a new medical treatment.",
    "administration_position": 8
  },
  {
    "id": "607acea2-20b9-4852-9923-8677d7808c8a",
    "stem": "Design graphics for websites.",
    "administration_position": 9
  },
  {
    "id": "64b46c6d-4693-4762-821b-9e2638bc98c7",
    "stem": "Persuade people to purchase a product.",
    "administration_position": 10
  },
  {
    "id": "eadd64b2-c627-407e-ac9f-6bcf9219610c",
    "stem": "Fight fires.",
    "administration_position": 11
  },
  {
    "id": "170e0913-c832-4ec7-a4f4-baac94e8676f",
    "stem": "Write novels.",
    "administration_position": 12
  },
  {
    "id": "34a9991b-2a14-4702-aa8e-e0ce35becca5",
    "stem": "Drive equipment to till soil and harvest crops.",
    "administration_position": 13
  },
  {
    "id": "b1955552-5012-4523-865a-83c2aea886a2",
    "stem": "Conduct social science experiments.",
    "administration_position": 14
  },
  {
    "id": "b86dff0b-7dc5-4506-aded-0133d803183b",
    "stem": "Audit a company's accounting records.",
    "administration_position": 15
  },
  {
    "id": "0169b5fc-deed-460e-aed1-7fddae5f4479",
    "stem": "Clean hotel rooms.",
    "administration_position": 16
  },
  {
    "id": "44188d78-1c5c-45f9-9c61-e1d5bba4472e",
    "stem": "Rehearse lines for a play.",
    "administration_position": 17
  },
  {
    "id": "89dfe104-ed09-4147-9356-3c248af5a458",
    "stem": "Take a leadership role in a company.",
    "administration_position": 18
  },
  {
    "id": "b118b2fe-5c84-43ac-b10f-92b3a61342a2",
    "stem": "Repair vehicles (e.g., cars, trucks, bikes).",
    "administration_position": 19
  },
  {
    "id": "5d6d5301-2931-4aa0-a749-a38b46a7db09",
    "stem": "Perform experiments in quantum physics.",
    "administration_position": 20
  },
  {
    "id": "9ffb66ce-e05a-4e1a-9b3c-5254de4256d0",
    "stem": "Help customers find products in a store.",
    "administration_position": 21
  },
  {
    "id": "db6c0540-cb97-4b58-bb42-f9690d9b8684",
    "stem": "Inspect restaurants for health code violations.",
    "administration_position": 22
  },
  {
    "id": "a637af41-9b51-4a63-90cf-f320d79afbdb",
    "stem": "Advise clients on what choices to make.",
    "administration_position": 23
  },
  {
    "id": "3d17b258-9c5f-49e2-a5c0-3df37dfb4aa1",
    "stem": "Apply statistics to interpret data.",
    "administration_position": 24
  },
  {
    "id": "b8e2d3fb-51b9-4a4c-b3af-1bbe1292872a",
    "stem": "Teach students in a classroom.",
    "administration_position": 25
  },
  {
    "id": "86fe34a7-37c5-4441-a033-c04360596c5a",
    "stem": "Enlist in the armed forces.",
    "administration_position": 26
  },
  {
    "id": "c505e36a-acf6-429d-a7e4-33e33399cb7c",
    "stem": "Serve as your community's political representative.",
    "administration_position": 27
  },
  {
    "id": "2c3dabb5-108b-43d8-ab0c-90860be2c2a6",
    "stem": "Cook meals for customers.",
    "administration_position": 28
  },
  {
    "id": "f2a9e591-e260-49af-99bd-41f54a77b6b4",
    "stem": "Administer medical exams (e.g., checkups, hearing tests, X-rays, CT scans, MRI scans).",
    "administration_position": 29
  },
  {
    "id": "01aeb394-7473-47e1-9518-8deca35fc494",
    "stem": "Rescue stranded people.",
    "administration_position": 30
  },
  {
    "id": "f565fd19-702f-4090-a2c5-8136ec846966",
    "stem": "Report on important news stories.",
    "administration_position": 31
  },
  {
    "id": "efa09935-ab5c-48c3-9674-34038ee4cd09",
    "stem": "Prepare tissue samples for analysis.",
    "administration_position": 32
  },
  {
    "id": "dc3fa938-b1db-4fc4-8ceb-304cccd6927b",
    "stem": "Develop online help documentation.",
    "administration_position": 33
  },
  {
    "id": "2e8c6ede-7337-464f-8a61-e89598f9a3a0",
    "stem": "Build fences.",
    "administration_position": 34
  },
  {
    "id": "f9e6321a-8108-4397-89f6-81db1f9cb277",
    "stem": "Provide physical therapy to people with injuries.",
    "administration_position": 35
  },
  {
    "id": "f96e075b-9679-4988-bacb-251741873cdb",
    "stem": "Coach a sports team.",
    "administration_position": 36
  },
  {
    "id": "105501c2-8cf7-4a62-b5ec-bb1035ceaf2c",
    "stem": "Oversee the hiring and recruiting activities for a company.",
    "administration_position": 37
  },
  {
    "id": "0de22ce4-5582-4bb1-809c-afac8fab9b6d",
    "stem": "Examine someone's teeth or gums for signs of problems.",
    "administration_position": 38
  },
  {
    "id": "34702fa2-97a8-4591-9455-9c492cfeb205",
    "stem": "Manage a public forest to conserve wildlife habitats.",
    "administration_position": 39
  },
  {
    "id": "b15dd828-e19d-4363-8c03-bd52128b8727",
    "stem": "Provide after-school care for children.",
    "administration_position": 40
  },
  {
    "id": "9b4611e2-b44f-4709-92ad-3f781e25a2e8",
    "stem": "Work in an animal shelter.",
    "administration_position": 41
  },
  {
    "id": "32f332a8-678f-4981-9abc-f53fcc70bb50",
    "stem": "Administer eye exams in an optometrist's office.",
    "administration_position": 42
  },
  {
    "id": "1c27dc8f-c092-4dbd-b3ef-ff6bc1dcfce5",
    "stem": "Represent clients in lawsuits.",
    "administration_position": 43
  },
  {
    "id": "40202bc1-48b4-4a58-ad34-0c48ea772d35",
    "stem": "Create detailed blueprints.",
    "administration_position": 44
  },
  {
    "id": "0874cebb-b9aa-4613-b2f8-38ae63d6a412",
    "stem": "Write apps for phones and tablets.",
    "administration_position": 45
  },
  {
    "id": "67eb68bb-1195-4b61-b523-bff499f2e16a",
    "stem": "Sketch designs for a costume.",
    "administration_position": 46
  },
  {
    "id": "d47ec00c-34f9-49ce-916a-cdeef975b2d3",
    "stem": "Provide counselling to families.",
    "administration_position": 47
  },
  {
    "id": "6e1be2d6-8567-44ee-a859-26bd145c689c",
    "stem": "Create ads to sell a product.",
    "administration_position": 48
  },
  {
    "id": "0eae00cb-9488-4eca-8d5e-7f2aaf43471f",
    "stem": "Pilot an airplane.",
    "administration_position": 49
  },
  {
    "id": "9bed28f5-bbd8-42ce-b92b-465d9f2d1f93",
    "stem": "Give a presentation.",
    "administration_position": 50
  },
  {
    "id": "f77340cc-b9b6-46fc-a1b7-d567cb28f0cb",
    "stem": "Take a course about navigating at sea.",
    "administration_position": 51
  },
  {
    "id": "8510e998-cb2e-4f57-b7d5-6b7493922f81",
    "stem": "Assess environmental damage and identify restoration needs.",
    "administration_position": 52
  },
  {
    "id": "6fde457d-9cce-488c-a926-9ab2009981cd",
    "stem": "Play music in a band.",
    "administration_position": 53
  },
  {
    "id": "a58b3a95-b04f-4ae6-8d13-550a919b2040",
    "stem": "Be a stay-at-home parent.",
    "administration_position": 54
  },
  {
    "id": "e8839fb3-eaf7-41fa-b338-b84730d2a790",
    "stem": "Guide people in prayer.",
    "administration_position": 55
  },
  {
    "id": "f1e349c8-2dd5-47b8-bf65-7b9e0c39f006",
    "stem": "Invest a client's money in stocks and bonds.",
    "administration_position": 56
  },
  {
    "id": "212c55ec-1380-4529-a35f-9d3227163ba4",
    "stem": "Be part of a railway crew working on a train.",
    "administration_position": 57
  },
  {
    "id": "c49e7b6f-4b67-4bf6-852c-20e4a97e682c",
    "stem": "Spoon-feed someone in a nursing home.",
    "administration_position": 58
  },
  {
    "id": "ace6a3c3-8a76-45ae-91f6-8d12f0c87e57",
    "stem": "Enter data into a computer.",
    "administration_position": 59
  },
  {
    "id": "e68f07e5-822f-4225-a71a-badbdf857cef",
    "stem": "Examine insurance claims to determine if they are valid.",
    "administration_position": 60
  },
  {
    "id": "0b71341e-25ed-41f2-8850-109de4458c3a",
    "stem": "Install light fixtures and electrical outlets in buildings.",
    "administration_position": 61
  },
  {
    "id": "31c4d2ee-65ef-4919-a37c-fd0f9c9a5068",
    "stem": "Assemble items on an assembly line.",
    "administration_position": 62
  },
  {
    "id": "c64a0c84-c4d9-46dd-a09c-8f79be3e00a9",
    "stem": "Install electrical equipment.",
    "administration_position": 63
  },
  {
    "id": "01a005ab-610a-4918-8092-2706fec093be",
    "stem": "Investigate rock and mineral formations.",
    "administration_position": 64
  },
  {
    "id": "c3adc771-90e5-4d02-a978-95389f111d5d",
    "stem": "Stitch a wound.",
    "administration_position": 65
  },
  {
    "id": "e1c51074-f94c-433f-a70f-12971a8b7576",
    "stem": "Design structures like bridges or dams.",
    "administration_position": 66
  },
  {
    "id": "f91081a3-8a98-481f-b6ad-6ad9a0c51d5f",
    "stem": "Administer doctor-prescribed medication in a hospital.",
    "administration_position": 67
  },
  {
    "id": "37f8c367-8aae-4f6f-ba64-d076fbfc006c",
    "stem": "Conduct security screens at an airport.",
    "administration_position": 68
  },
  {
    "id": "944281b8-be20-4ab1-abc2-8d04c29e875b",
    "stem": "Help people bathe, dress, and use the toilet.",
    "administration_position": 69
  },
  {
    "id": "8056048d-ce82-483c-be53-076d231cb21b",
    "stem": "Research historical documents and sources.",
    "administration_position": 70
  },
  {
    "id": "8d707962-9c6d-40fb-afb9-972fb694b01e",
    "stem": "Cut hair in a salon.",
    "administration_position": 71
  },
  {
    "id": "e7d3642e-322d-467b-8eb2-225c9536a448",
    "stem": "Explore options to save a failing business.",
    "administration_position": 72
  },
  {
    "id": "cb341c70-0536-43b4-84bc-12a2909ab126",
    "stem": "Organize files in an office.",
    "administration_position": 73
  },
  {
    "id": "63096a5c-fc2f-423f-ad7f-226aec0d3301",
    "stem": "Operate a backhoe at a construction site.",
    "administration_position": 74
  },
  {
    "id": "1fe36f19-c3e0-460e-b5a5-1a312d5b489e",
    "stem": "Investigate how a gene alters a drug's effect on cells.",
    "administration_position": 75
  },
  {
    "id": "c025c379-676e-4645-954f-3ffecb293142",
    "stem": "Create life-like artificial body parts for people who need them.",
    "administration_position": 76
  },
  {
    "id": "dc5527b9-ce66-4f09-add5-5cc815cb0cff",
    "stem": "Sell advertising space.",
    "administration_position": 77
  },
  {
    "id": "ba2b6a95-b68f-467c-9d1e-ccd634041863",
    "stem": "Edit short stories.",
    "administration_position": 78
  },
  {
    "id": "5ecab28b-4da8-41b4-94c1-0b2f04a5ee9c",
    "stem": "Feed farm animals.",
    "administration_position": 79
  },
  {
    "id": "573613f0-ce58-4ebe-a495-097799c0d3e6",
    "stem": "Study trends in the economy to make predictions.",
    "administration_position": 80
  },
  {
    "id": "43fc39e3-9dc6-4bb7-8fde-78f7f32de9ee",
    "stem": "Prepare financial statements.",
    "administration_position": 81
  },
  {
    "id": "8a0705fb-6a52-452e-b153-a56e2788ba4e",
    "stem": "Polish floors in a hotel lobby.",
    "administration_position": 82
  },
  {
    "id": "93f62ebd-cdee-4e77-8192-94ce69566e71",
    "stem": "Contribute behind the stage in shows.",
    "administration_position": 83
  },
  {
    "id": "7a8d4fab-2921-415d-8409-f9556f8a08fd",
    "stem": "Direct staff at a hotel.",
    "administration_position": 84
  },
  {
    "id": "f186a0dc-371c-4381-b7d4-251e0f6cf452",
    "stem": "Resolve customer complaints.",
    "administration_position": 85
  },
  {
    "id": "256da737-1590-462b-9b3b-f3db30e968f1",
    "stem": "Give career advice to job seekers.",
    "administration_position": 86
  },
  {
    "id": "b540e5d5-623f-4043-96b4-1428537504d4",
    "stem": "Study advanced mathematics.",
    "administration_position": 87
  },
  {
    "id": "f8c3f6c7-bfaf-4a88-ab76-98a24e76df15",
    "stem": "Grade tests and assignments.",
    "administration_position": 88
  },
  {
    "id": "03d1e84e-63df-4fe9-bcec-80148f2897e3",
    "stem": "Serve as a peacekeeper in areas experiencing conflict.",
    "administration_position": 89
  },
  {
    "id": "4af509df-b289-4aad-8d5c-d80be366b8af",
    "stem": "Convince voters to support legislation.",
    "administration_position": 90
  },
  {
    "id": "075fd3cd-5014-400a-ac01-96855c577f50",
    "stem": "Bake bread and deserts in a bakery.",
    "administration_position": 91
  },
  {
    "id": "ad6414d8-593e-4147-a202-5bc699e3dae6",
    "stem": "Do work that is physically dangerous.",
    "administration_position": 92
  },
  {
    "id": "650387fe-7606-4a98-af1d-0d00a4148ab2",
    "stem": "Produce digital media content for clients.",
    "administration_position": 93
  },
  {
    "id": "0fd8f40b-8663-4690-af33-19a6ea97ebcf",
    "stem": "Study the diet of an animal species.",
    "administration_position": 94
  },
  {
    "id": "c0b8531d-c1de-4312-aecc-48cb85d76c9f",
    "stem": "Correct errors in product descriptions.",
    "administration_position": 95
  },
  {
    "id": "4cc14d7e-1792-4d73-b7dc-60657208f401",
    "stem": "Fetch items in a warehouse.",
    "administration_position": 96
  },
  {
    "id": "f781975d-80a9-477e-9698-0367447c4310",
    "stem": "Give radiation therapy to people with cancer.",
    "administration_position": 97
  },
  {
    "id": "fc0c72a4-06d5-4dd8-b8f7-a5990fc2fd7a",
    "stem": "Direct exercises in a fitness class.",
    "administration_position": 98
  },
  {
    "id": "095b8902-34c7-40e9-a3f2-8be3c20410d5",
    "stem": "Conduct surveys to find out what people really think.",
    "administration_position": 99
  },
  {
    "id": "6454d472-7b39-48b4-9032-568b1f2c2fd1",
    "stem": "Advise dental patients on how to keep their teeth healthy.",
    "administration_position": 100
  },
  {
    "id": "aa10ad4d-364a-4670-ac2d-581015d3bbe8",
    "stem": "Grow plants in a nursery.",
    "administration_position": 101
  },
  {
    "id": "54dcfd1e-7401-470c-8e67-40d7b64bdeee",
    "stem": "Run activities for teenagers and young adults.",
    "administration_position": 102
  },
  {
    "id": "9c62fdd1-70cc-4c26-b433-6aa1113355ff",
    "stem": "Provide care to animals in a zoo.",
    "administration_position": 103
  },
  {
    "id": "617e4bbd-cc0a-4021-bb2c-642123234eb7",
    "stem": "Diagnose and treat vision problems.",
    "administration_position": 104
  },
  {
    "id": "b0b83245-82d7-4bc6-9d4e-6a6214dd3219",
    "stem": "Prepare legal documents.",
    "administration_position": 105
  },
  {
    "id": "d7fcef43-49cc-4c2f-9df4-a916d9b277d1",
    "stem": "Troubleshoot computer problems.",
    "administration_position": 106
  },
  {
    "id": "f5f78786-9ebd-4c33-9374-b76a7ce98eb6",
    "stem": "Create works of art.",
    "administration_position": 107
  },
  {
    "id": "d0a78108-c1aa-448a-bc94-2797124f29e3",
    "stem": "Find support resources for students.",
    "administration_position": 108
  },
  {
    "id": "6b3da794-e8cf-40b4-b364-a06cb4ad009b",
    "stem": "Conduct market research, checking consumer demand for a product or service.",
    "administration_position": 109
  },
  {
    "id": "ef20211d-c328-4968-ab3c-0c86ca0611c8",
    "stem": "Direct air traffic around an airport.",
    "administration_position": 110
  },
  {
    "id": "cc2cee2d-0801-49ab-a613-607ad473684f",
    "stem": "Record your voice for a podcast.",
    "administration_position": 111
  },
  {
    "id": "904e64eb-e562-4968-9e94-fecc77daae45",
    "stem": "Operate a boat.",
    "administration_position": 112
  },
  {
    "id": "b11cc884-e26a-4905-afad-37fb3f0bbc2f",
    "stem": "Convert industrial sites back into natural habitats.",
    "administration_position": 113
  },
  {
    "id": "93fce7ed-f9dd-453f-9aa2-4a6bf0f34d75",
    "stem": "Write music.",
    "administration_position": 114
  },
  {
    "id": "8534a3e9-1f8e-4bab-951f-b02f5973b64d",
    "stem": "Arrange play dates for your kids.",
    "administration_position": 115
  },
  {
    "id": "1db291b3-cac5-46ec-8c2e-fc3089a90b22",
    "stem": "Explore your spirituality.",
    "administration_position": 116
  },
  {
    "id": "1a8baf1e-720c-4b31-9ae6-1dd979d13daa",
    "stem": "Analyze credit history to make loan decisions.",
    "administration_position": 117
  },
  {
    "id": "08926df4-112f-4868-9b85-12209af516d4",
    "stem": "Operate subway cars.",
    "administration_position": 118
  },
  {
    "id": "9a68670e-ba83-4178-b01b-33360ddfdf38",
    "stem": "Perform quality control checks on products.",
    "administration_position": 119
  },
  {
    "id": "939a789f-5c09-4910-a5f2-0538bf025dd0",
    "stem": "Fix broken plumbing fixtures.",
    "administration_position": 120
  },
  {
    "id": "e9da6481-653b-4f69-ba06-29c15a62f325",
    "stem": "Operate equipment in a power plant.",
    "administration_position": 121
  },
  {
    "id": "e75d49c9-4a3a-48ed-8e49-402b1f3a43ab",
    "stem": "Repair broken equipment in a factory.",
    "administration_position": 122
  },
  {
    "id": "6292ee37-ed2f-4007-9ed3-6cc88b64cbed",
    "stem": "Perform chemistry research for new battery types.",
    "administration_position": 123
  },
  {
    "id": "0071774a-7127-4108-abb0-5331a4673c20",
    "stem": "Administer a hearing test.",
    "administration_position": 124
  },
  {
    "id": "cce61a5f-21e3-4573-9d17-acb1ab7053a9",
    "stem": "Develop diagnostic routines for a power plant.",
    "administration_position": 125
  },
  {
    "id": "495255cf-188f-43be-9bfa-c4b996e1aa9f",
    "stem": "Book meeting times for office executives.",
    "administration_position": 126
  },
  {
    "id": "4e865e05-6101-492c-ae7f-0ed81473af27",
    "stem": "Monitor the condition of patients in a hospital.",
    "administration_position": 127
  },
  {
    "id": "b47815db-411d-4667-adce-e7b5f40fcf59",
    "stem": "Patrol the street to enforce laws.",
    "administration_position": 128
  },
  {
    "id": "c334ff18-9b8c-4827-bbb6-27eb3a804e94",
    "stem": "Perform a security audit to find vulnerabilities.",
    "administration_position": 129
  },
  {
    "id": "55583b27-1eba-4ff3-b9e7-fd8efa9c8a1b",
    "stem": "Serve food and drink to patients in a hospital.",
    "administration_position": 130
  },
  {
    "id": "0bed3692-7237-4c3b-b9b2-8e59be4d3c2a",
    "stem": "Build a wall out of bricks or stone.",
    "administration_position": 131
  },
  {
    "id": "1e4b586b-3961-4c7d-91cd-25948df03381",
    "stem": "Paint items in a factory.",
    "administration_position": 132
  },
  {
    "id": "51a913db-e9e9-47b6-817a-b9657ec709ec",
    "stem": "Install and maintain high-tech equipment.",
    "administration_position": 133
  },
  {
    "id": "bcbefa16-630d-4a4c-ad24-37a92491aa0d",
    "stem": "Forecast weather using data from satellites and ground stations.",
    "administration_position": 134
  },
  {
    "id": "a3bc1552-f258-4615-a69b-4aa300c14127",
    "stem": "Perform surgery in a hospital.",
    "administration_position": 135
  },
  {
    "id": "7647847d-ca1a-461c-8e3e-575871ff379d",
    "stem": "Create maps and charts.",
    "administration_position": 136
  },
  {
    "id": "05bbe56c-1586-428e-ba0d-8edbb868a12a",
    "stem": "Study philosophy (e.g., the nature of knowledge, ethics, reality, and existence).",
    "administration_position": 137
  },
  {
    "id": "273e8a7c-6901-4ed3-8a3d-8d5857ddebd7",
    "stem": "Provide advice to tourists.",
    "administration_position": 138
  },
  {
    "id": "ea26981a-1e68-4d93-95ea-40f356a3d6ec",
    "stem": "Learn about how to make a successful business.",
    "administration_position": 139
  },
  {
    "id": "8c53eeae-3b0a-45be-968e-49700954d2e4",
    "stem": "Take messages and forward calls in an office.",
    "administration_position": 140
  },
  {
    "id": "a39d1fd3-40b4-4634-bdd5-54326dec2fc8",
    "stem": "Operate a crane, moving cargo or construction materials.",
    "administration_position": 141
  },
  {
    "id": "c8139e8c-6fb0-4b32-84cc-b44a3442c855",
    "stem": "Research the cause and treatment of diseases.",
    "administration_position": 142
  },
  {
    "id": "5c06c8ee-a8d8-45eb-812e-99cc91177f1f",
    "stem": "Design indoor and outdoor spaces.",
    "administration_position": 143
  },
  {
    "id": "61b07bd2-621a-4f62-addc-30c04ff4d3f3",
    "stem": "Increase sales in your territory.",
    "administration_position": 144
  },
  {
    "id": "52e253d8-3e0a-4493-9301-813878c4942d",
    "stem": "Write social media posts for clients.",
    "administration_position": 145
  },
  {
    "id": "a54b9aff-b07a-4df7-a47a-ded925f4b73a",
    "stem": "Prune trees in an orchard.",
    "administration_position": 146
  },
  {
    "id": "c3536db0-cf8b-4b1a-85d6-d5adacc97394",
    "stem": "Conduct research on political subjects (e.g., policies, legislation, ideologies).",
    "administration_position": 147
  },
  {
    "id": "b0b4c7e9-0edc-4058-a398-4b8b937ac52d",
    "stem": "Prepare tax returns for clients.",
    "administration_position": 148
  },
  {
    "id": "5a579639-2b9f-402a-99a0-32805333b9ff",
    "stem": "Clean and fold towels at a spa.",
    "administration_position": 149
  },
  {
    "id": "16e9b2af-8ad9-44ff-aaa3-5b904462d767",
    "stem": "Entertain an audience.",
    "administration_position": 150
  },
  {
    "id": "965d7aee-f347-4264-b26b-c07c230010bb",
    "stem": "Supervise employees.",
    "administration_position": 151
  },
  {
    "id": "22373c06-0236-4953-be75-45389dc92953",
    "stem": "Listen to customer needs and propose solutions.",
    "administration_position": 152
  },
  {
    "id": "a60f41d8-bcf3-47fc-aabf-afad28f080eb",
    "stem": "Consult on how businesses can solve their most pressing issues.",
    "administration_position": 153
  },
  {
    "id": "e6378fe2-fdae-44d0-9ed3-79ea379b13f7",
    "stem": "Produce charts to interpret data.",
    "administration_position": 154
  },
  {
    "id": "462a6896-5b34-45c8-a4ff-7f4d2f398fa7",
    "stem": "Design activities for student learning.",
    "administration_position": 155
  },
  {
    "id": "d71eb4d2-1800-4cd1-9137-0040ca7d692f",
    "stem": "Train as a soldier for combat operations.",
    "administration_position": 156
  },
  {
    "id": "7f449b33-5cce-4f49-8d93-722896d965b6",
    "stem": "Serve on political committees and counsels.",
    "administration_position": 157
  },
  {
    "id": "6b0c2bc6-6cfd-41fe-828d-32fdf433dcab",
    "stem": "Mix and serve drinks to customers.",
    "administration_position": 158
  },
  {
    "id": "b0383970-3d82-4b78-b794-0995482f35b9",
    "stem": "Save people from drowning.",
    "administration_position": 159
  },
  {
    "id": "ee8b9c4a-1be8-4a68-b2a0-28757e29db99",
    "stem": "Be an influencer on social media.",
    "administration_position": 160
  },
  {
    "id": "132fb911-06c6-4c1a-94a0-f9dbc499a86f",
    "stem": "View cell samples under a microscope.",
    "administration_position": 161
  },
  {
    "id": "2146bbfd-ec9f-4382-9069-df9fd9f5d25d",
    "stem": "Write a scientific article for publication in academic journals.",
    "administration_position": 162
  },
  {
    "id": "b763258e-d9f3-490e-af8d-82162081d4fc",
    "stem": "Operate a machine that mines metal from rock.",
    "administration_position": 163
  },
  {
    "id": "5fad4a47-c5a3-4ad5-af9d-070d4c0fcfeb",
    "stem": "Provide massage therapy to help people relax and maintain health.",
    "administration_position": 164
  },
  {
    "id": "fcd3324c-63fd-434b-8243-7d09a47ddf45",
    "stem": "Be a professional athlete.",
    "administration_position": 165
  },
  {
    "id": "5b9ace85-104b-41af-b70a-e3ee9accc10b",
    "stem": "Train new employees.",
    "administration_position": 166
  },
  {
    "id": "cf3a00a6-2d14-4802-86b5-75d1f9755cf7",
    "stem": "Clean the teeth of patients visiting a dentist office.",
    "administration_position": 167
  },
  {
    "id": "a8f6ed41-9f36-4a38-bcdf-9c65253762a1",
    "stem": "Set traps to catch wildlife.",
    "administration_position": 168
  },
  {
    "id": "134eb0ba-1a89-428c-873b-4a933fb9ceee",
    "stem": "Supervise children during recess.",
    "administration_position": 169
  },
  {
    "id": "fb70ef8b-5b37-4a08-8774-4527ad3d5f70",
    "stem": "Clean animals and their living spaces.",
    "administration_position": 170
  },
  {
    "id": "f8c0ca13-669c-4e2d-8cca-fc746784bbcd",
    "stem": "Prescribe glasses or contact lenses.",
    "administration_position": 171
  },
  {
    "id": "3eb8c920-4aee-48d4-9f9e-3884e632aac5",
    "stem": "Judge disputes in small claims court.",
    "administration_position": 172
  },
  {
    "id": "02fc99e8-90f8-4136-b516-095b3152d4ab",
    "stem": "Develop websites.",
    "administration_position": 173
  },
  {
    "id": "32a1e037-a488-4eec-b565-272418209f25",
    "stem": "Take photos at weddings.",
    "administration_position": 174
  },
  {
    "id": "ae8b7a12-3d3e-4d71-8bab-6d867b910134",
    "stem": "Assess mental health problems.",
    "administration_position": 175
  },
  {
    "id": "f257572a-8668-42fd-83b9-fe8d077b0bf5",
    "stem": "Create interest in an upcoming product or service.",
    "administration_position": 176
  },
  {
    "id": "23213aef-dd3f-44a7-88f3-760a30f7479f",
    "stem": "Navigate an aircraft along scheduled routes.",
    "administration_position": 177
  },
  {
    "id": "efb63325-c81e-461c-8d8d-6b359832d11e",
    "stem": "Speak in front of an audience.",
    "administration_position": 178
  },
  {
    "id": "3f279f5d-72d4-4ecc-b04d-5f2ec0d6fcae",
    "stem": "Spend months at a time at sea.",
    "administration_position": 179
  },
  {
    "id": "822a610e-2c8a-4143-af41-a11a34a38f05",
    "stem": "Advise businesses on how to produce less pollution.",
    "administration_position": 180
  },
  {
    "id": "5572dd14-32a3-4bf9-8dae-25f1c77bd6b7",
    "stem": "Play a musical instrument.",
    "administration_position": 181
  },
  {
    "id": "ffa5b274-48d9-4c48-be50-563334b993eb",
    "stem": "Grocery shop for your family.",
    "administration_position": 182
  },
  {
    "id": "8d11c716-d2e4-4f13-8319-244b8a102f88",
    "stem": "Provide spiritual guidance.",
    "administration_position": 183
  },
  {
    "id": "db2215cc-cf36-4897-87ae-bcba50e0117e",
    "stem": "Study economics - how society distributes its resources to produce goods and services.",
    "administration_position": 184
  },
  {
    "id": "5fb4ed3b-8669-4c7a-9430-9df63824b49a",
    "stem": "Operate railroad track switches.",
    "administration_position": 185
  },
  {
    "id": "c18d47ec-e723-403c-be4f-309c29e89adc",
    "stem": "Verify that plans meet legal requirements.",
    "administration_position": 186
  },
  {
    "id": "aaa169c6-3490-4adf-ad69-8e3c966f3d7e",
    "stem": "Install flooring in homes.",
    "administration_position": 187
  },
  {
    "id": "ed0349dc-0abd-4862-bbff-918a1afaab30",
    "stem": "Operate equipment that cooks or bakes large quantities of food.",
    "administration_position": 188
  },
  {
    "id": "8f3ad208-8f79-47ca-8eda-222db885284b",
    "stem": "Prepare basic reports of monthly office activity.",
    "administration_position": 189
  },
  {
    "id": "bd9af64f-51c9-449c-848e-334acee411a9",
    "stem": "Log daily medications given to a patient.",
    "administration_position": 190
  },
  {
    "id": "36e0e12c-7b36-4413-88aa-8fe73e0f7596",
    "stem": "Investigate crimes.",
    "administration_position": 191
  },
  {
    "id": "92312951-5d72-4388-b29e-e004cb0886ad",
    "stem": "Repair appliances.",
    "administration_position": 192
  },
  {
    "id": "de90ff0b-89df-4551-9f14-3f813332e24d",
    "stem": "Study planets in other solar systems.",
    "administration_position": 193
  },
  {
    "id": "d69feae3-1f56-48be-80d1-3f3293e6c472",
    "stem": "Advise people on how to maintain or improve their health.",
    "administration_position": 194
  },
  {
    "id": "d73efd7e-2234-4ada-a863-9ecf86c3f966",
    "stem": "Design a machine using modeling software.",
    "administration_position": 195
  },
  {
    "id": "a32c34a4-e7f1-4e45-b3e6-ac59c2095ffb",
    "stem": "Use an overhead lift to help patients get out of bed.",
    "administration_position": 196
  },
  {
    "id": "75fd4a9d-ae44-4e97-8767-56a4ba23c9f6",
    "stem": "Verify that contents of a shipping container match its manifest.",
    "administration_position": 197
  },
  {
    "id": "e4077a8e-f251-4913-97da-fb8fd91622bd",
    "stem": "Investigate ancient cultures.",
    "administration_position": 198
  },
  {
    "id": "2d79342e-0c70-4603-8363-6f9b1da463e9",
    "stem": "Plan weddings and other events for clients.",
    "administration_position": 199
  },
  {
    "id": "a6a8e178-370a-4cb7-9aeb-72c79ab366d0",
    "stem": "Negotiate business contracts.",
    "administration_position": 200
  },
  {
    "id": "a1a6f90c-8dc8-4d57-a64f-9e86a0e2e430",
    "stem": "Process payments at the front desk of an office.",
    "administration_position": 201
  },
  {
    "id": "045edd35-d991-4099-b79e-75c71ddfa41e",
    "stem": "Drive a bus.",
    "administration_position": 202
  },
  {
    "id": "65d78db9-f764-4db0-9f57-cc0a3b6d5e7d",
    "stem": "Research how proteins fold.",
    "administration_position": 203
  },
  {
    "id": "7174507b-c167-4002-b9fe-af343f73c63b",
    "stem": "Design packaging for products.",
    "administration_position": 204
  },
  {
    "id": "501ce460-b335-4e04-8e00-c3144464f696",
    "stem": "Convince people to sign up for a service.",
    "administration_position": 205
  },
  {
    "id": "a189e8d3-b365-45ce-8d77-1e50be181088",
    "stem": "Write dialog for movies.",
    "administration_position": 206
  },
  {
    "id": "341cdc9e-3c78-4d51-b11a-632b73cbb875",
    "stem": "Grow food in a greenhouse.",
    "administration_position": 207
  },
  {
    "id": "822d34ad-675e-4d0d-a829-ba107dcf1ab5",
    "stem": "Study how people interact in a team.",
    "administration_position": 208
  },
  {
    "id": "8460ebb1-8079-4612-8970-2fb7523bdda3",
    "stem": "Check financial records to detect fraud.",
    "administration_position": 209
  },
  {
    "id": "329f1abc-f0d6-47ee-aab2-bcf118db966b",
    "stem": "Vacuum carpets in an office building.",
    "administration_position": 210
  },
  {
    "id": "668f524c-9c4c-4563-8bae-46a04908ad39",
    "stem": "Dance on stage.",
    "administration_position": 211
  },
  {
    "id": "c384b0ff-951d-4204-867d-f9e19884271c",
    "stem": "Be in command of a team.",
    "administration_position": 212
  },
  {
    "id": "06e0193f-ddb5-4a11-9e67-cde6ddc306c1",
    "stem": "Handle disgruntled customers.",
    "administration_position": 213
  },
  {
    "id": "5fe2ded5-cfd0-4658-b445-0f5f76ed2b6a",
    "stem": "Be a consultant hired to give advice.",
    "administration_position": 214
  },
  {
    "id": "472eacf1-3f29-4ddd-b5ea-c3a9fda1fe63",
    "stem": "Apply math theories to solve practical problems.",
    "administration_position": 215
  },
  {
    "id": "a3a8b788-3892-47d0-878a-ee8829ee66a3",
    "stem": "Supervise a student project.",
    "administration_position": 216
  },
  {
    "id": "58e6581d-0a0e-4e3b-beb5-c9cfb8ef2652",
    "stem": "Operate military equipment.",
    "administration_position": 217
  },
  {
    "id": "243ce49e-ca18-4c49-a45e-89f574fbc98b",
    "stem": "Serve on a politician's staff, helping them govern.",
    "administration_position": 218
  },
  {
    "id": "d4fd8f72-ddfe-4e9e-a358-fb29da0e501c",
    "stem": "Prepare a variety of meals all at once.",
    "administration_position": 219
  },
  {
    "id": "7e181b5c-c8ee-41f4-ae0e-ecaf2b538bcd",
    "stem": "Rescue or save people.",
    "administration_position": 220
  },
  {
    "id": "7ec75fc0-078d-42b6-af02-ce8af81d8cda",
    "stem": "Film a commercial for an upcoming product.",
    "administration_position": 221
  },
  {
    "id": "f44174a7-6843-498b-b404-43da30c33c6e",
    "stem": "Investigate how each organism fits together in an ecosystem.",
    "administration_position": 222
  },
  {
    "id": "f38ad16d-54a8-4b45-b271-621b8e60a6d8",
    "stem": "Proofread a user guide, correcting errors.",
    "administration_position": 223
  },
  {
    "id": "53fe4df5-2fbb-4a37-aeb3-549d8a74b021",
    "stem": "Perform physical labor at a construction site.",
    "administration_position": 224
  },
  {
    "id": "3fa71eb0-5045-4f13-b624-9b8551e7fd06",
    "stem": "Show people how to perform exercises that will help them recover.",
    "administration_position": 225
  },
  {
    "id": "227a388c-7855-473a-bfa4-4a5d940bbdf7",
    "stem": "Talk with professional athletes every day.",
    "administration_position": 226
  },
  {
    "id": "18c12c7d-f230-402a-9f1f-da45edf0504d",
    "stem": "Interview people applying for a job or loan.",
    "administration_position": 227
  },
  {
    "id": "5ddbc8e3-1aff-4348-9c64-3ba06739880a",
    "stem": "Adjust dental braces for straightening teeth.",
    "administration_position": 228
  },
  {
    "id": "f8d4eae1-e59e-45e0-ab7d-f01d5d00a716",
    "stem": "Work outside in all weather.",
    "administration_position": 229
  },
  {
    "id": "e07613ae-ae24-485f-ac3c-222504402cbe",
    "stem": "Provide care to children and infants.",
    "administration_position": 230
  },
  {
    "id": "1df24cb0-1b27-40a4-a92a-3ce07c8d7bf7",
    "stem": "Train animals to perform complex tasks.",
    "administration_position": 231
  },
  {
    "id": "ac9aafec-2ffe-4644-9183-ef255ca445da",
    "stem": "Adjust glasses to correct someone's vision.",
    "administration_position": 232
  },
  {
    "id": "bc320387-8144-46b5-9d9c-7b4c3a7828db",
    "stem": "Review prior case law.",
    "administration_position": 233
  },
  {
    "id": "87d90adc-dcb0-4c73-9a1e-2fabaa119583",
    "stem": "Increase the efficiency of computer networks.",
    "administration_position": 234
  },
  {
    "id": "e5f39688-7326-4023-adad-c3b8d80ebdd3",
    "stem": "Make decorative pottery.",
    "administration_position": 235
  },
  {
    "id": "34ed6965-334b-4a46-b088-90d6b02e6e80",
    "stem": "Provide social services to children in need.",
    "administration_position": 236
  },
  {
    "id": "67c15ff7-5635-48e4-acde-108eaca63d73",
    "stem": "Gather information about a company's target audience.",
    "administration_position": 237
  },
  {
    "id": "4250aaae-2e00-4fa4-b713-b37437182e9e",
    "stem": "Work around airplanes.",
    "administration_position": 238
  },
  {
    "id": "0da69247-9945-41d4-8cca-18c0cae52909",
    "stem": "Speak on live radio.",
    "administration_position": 239
  },
  {
    "id": "e9318e8d-5d80-4214-8388-1c060767f58d",
    "stem": "Work on a cruise ship.",
    "administration_position": 240
  },
  {
    "id": "ccba9247-3154-455a-82e2-f30cd36b91e6",
    "stem": "Conduct an energy audit to find ways to help companies use less energy.",
    "administration_position": 241
  },
  {
    "id": "81094357-7796-4eb9-9295-2e8655fb3de7",
    "stem": "Sing for an audience.",
    "administration_position": 242
  },
  {
    "id": "947ad57a-424a-4415-8b91-8d499f29d87a",
    "stem": "Stay at home to raise your children.",
    "administration_position": 243
  },
  {
    "id": "a4c099fe-37d6-4ec9-9c45-bd4953536c96",
    "stem": "Help people interpret holy texts.",
    "administration_position": 244
  },
  {
    "id": "336fdd82-6973-480e-9d85-b23c6d786f29",
    "stem": "Evaluate insurance needs.",
    "administration_position": 245
  },
  {
    "id": "4d5ca309-9ceb-464a-a3bb-d98e441604f4",
    "stem": "Drive trains transporting passengers or freight.",
    "administration_position": 246
  },
  {
    "id": "75ab2bcd-c7b3-4c54-b0ca-d194a452d8fe",
    "stem": "Perform routine maintenance tasks around a building.",
    "administration_position": 247
  },
  {
    "id": "9e509d75-1870-4fce-be37-f6a7583d498b",
    "stem": "Set up and operate factory equipment.",
    "administration_position": 248
  },
  {
    "id": "666ce5d3-63ae-4a30-aec5-639bcd541585",
    "stem": "Install renewable energy systems (e.g., solar, wind).",
    "administration_position": 249
  },
  {
    "id": "735fdd90-d6f3-4e48-a96f-e33f7bd07e60",
    "stem": "Research the movement of water underground.",
    "administration_position": 250
  },
  {
    "id": "8863d32e-e5d1-4541-9570-f862cafa5fe4",
    "stem": "Diagnose illness based on test results and symptoms.",
    "administration_position": 251
  },
  {
    "id": "279a3312-01d9-4e10-afcf-300faab44742",
    "stem": "Modify a building's design to improve its earthquake resistance.",
    "administration_position": 252
  },
  {
    "id": "b0993869-b4f1-4890-bb64-fc3f86aa8ba8",
    "stem": "Pass tools to a doctor performing a surgery.",
    "administration_position": 253
  },
  {
    "id": "b759624f-8bcf-4a7c-9968-8c36acd6aeb7",
    "stem": "Guard inmates in a prison.",
    "administration_position": 254
  },
  {
    "id": "292869b1-de71-413e-9778-85e641eaa50d",
    "stem": "Inspect vehicles and equipment to ensure they meet safety standards.",
    "administration_position": 255
  },
  {
    "id": "1feba774-cfe0-49c3-b84c-c82a8cb1cf48",
    "stem": "Take a long-term care resident for a walk around the facility.",
    "administration_position": 256
  },
  {
    "id": "4374d069-cc5e-42e7-874d-7dcd25dbc272",
    "stem": "Sort mail.",
    "administration_position": 257
  },
  {
    "id": "4bad8145-c09f-4645-96a7-9bae70293225",
    "stem": "Install tile in a bathroom.",
    "administration_position": 258
  },
  {
    "id": "a2c3c691-87be-4325-8c1a-ae64a48718e2",
    "stem": "Cut and trim meat.",
    "administration_position": 259
  },
  {
    "id": "7ab15966-5282-41ea-8970-1c61e2fa7157",
    "stem": "Fix broken vehicles.",
    "administration_position": 260
  },
  {
    "id": "1db1b9b9-587d-46a8-9618-09a4b6cc138d",
    "stem": "Analyze the structure and performance of new materials.",
    "administration_position": 261
  },
  {
    "id": "4c9b012b-4469-4bff-a0bd-0d5bdf2709a3",
    "stem": "Prescribe pain medication.",
    "administration_position": 262
  },
  {
    "id": "49aa1565-350c-4bf7-9542-4b9001fa7d01",
    "stem": "Design an intersection that reduces traffic accidents.",
    "administration_position": 263
  },
  {
    "id": "bb8eb1c0-8dac-4015-a863-1855ac96f123",
    "stem": "Inspect buildings to ensure compliance with bylaws.",
    "administration_position": 264
  },
  {
    "id": "880fc787-0140-4444-a798-bade7f2c9d61",
    "stem": "Conduct research on the structure and grammar of languages.",
    "administration_position": 265
  },
  {
    "id": "30a69675-0dfc-4d20-97ef-434f1157fb05",
    "stem": "Do chores for customers (e.g., run errands, carry bags, park cars).",
    "administration_position": 266
  },
  {
    "id": "06a44f85-9a4f-4f5d-92fe-7fca3aecc847",
    "stem": "Develop business plans for a company.",
    "administration_position": 267
  },
  {
    "id": "4c086681-88e3-43ea-aff5-31c1deecfa3d",
    "stem": "Be the note taker for a meeting, sharing your notes with all attendees.",
    "administration_position": 268
  },
  {
    "id": "9bd0bbf0-4c30-4237-8391-e256be85edf2",
    "stem": "Drive a paving machine to level a road.",
    "administration_position": 269
  },
  {
    "id": "4686fede-8885-49a2-a1be-f475605aa1a7",
    "stem": "Investigate the patterns and causes of a disease.",
    "administration_position": 270
  },
  {
    "id": "f4306d53-6fb3-43e5-8eb7-757b8b886747",
    "stem": "Create visual designs for clients.",
    "administration_position": 271
  },
  {
    "id": "44ac26f4-c7e3-4063-b7db-7610d046aca3",
    "stem": "Upsell a customer, getting them to buy more.",
    "administration_position": 272
  },
  {
    "id": "e1d797ab-7a06-462b-a807-8703bf196820",
    "stem": "Write poetry.",
    "administration_position": 273
  },
  {
    "id": "72442f2c-b014-4dc1-a3d4-9dabf7da984a",
    "stem": "Raise a variety of farm animals.",
    "administration_position": 274
  },
  {
    "id": "a9953f39-9fa5-444e-852e-a7450252e203",
    "stem": "Research economic topics for businesses and governments.",
    "administration_position": 275
  },
  {
    "id": "bf56bcee-d1fc-46ba-beb6-ecfce30088df",
    "stem": "Maintain a client's financial records.",
    "administration_position": 276
  },
  {
    "id": "53d83fe8-6940-4520-aa95-3f9baa82f342",
    "stem": "Scrub and disinfect bathrooms.",
    "administration_position": 277
  },
  {
    "id": "46647dd0-8fba-45f1-bfe6-e2dfa8a7bc5d",
    "stem": "Control the sound equipment in a live show.",
    "administration_position": 278
  },
  {
    "id": "f9b9c54b-2f48-4362-946f-e563716ee018",
    "stem": "Manage other workers.",
    "administration_position": 279
  },
  {
    "id": "1c4e1bdf-42e6-4a74-9121-d95da142247c",
    "stem": "Help customers find the right solution for their needs.",
    "administration_position": 280
  },
  {
    "id": "b3f7fd57-a7c0-4082-af6b-517dd6bb42c3",
    "stem": "Provide advice to businesses on how to solve specific challenges.",
    "administration_position": 281
  },
  {
    "id": "6856f835-a131-4145-b045-9691499a07bc",
    "stem": "Run statistical analyses on a dataset.",
    "administration_position": 282
  },
  {
    "id": "d6ee457a-eac1-4ba9-984e-0efa63be4e14",
    "stem": "Develop teaching materials.",
    "administration_position": 283
  },
  {
    "id": "e6658ce2-ba70-4213-b43f-30f15354b438",
    "stem": "Operate weapons in ground combat operations.",
    "administration_position": 284
  },
  {
    "id": "0cd31f86-f35a-419a-a53d-bc6e79314196",
    "stem": "Negotiate with politicians.",
    "administration_position": 285
  },
  {
    "id": "bdee7811-917b-4ecf-a6e5-e23c0b7a616a",
    "stem": "Oversee food supplies of a restaurant.",
    "administration_position": 286
  },
  {
    "id": "0dfb9b43-f183-44aa-b470-f3e09cd88981",
    "stem": "Perform work that is dangerous and exciting.",
    "administration_position": 287
  },
  {
    "id": "adbd9a6d-e6a9-446b-b59b-2ff3f8c9bbf0",
    "stem": "Work on the set of a movie.",
    "administration_position": 288
  },
  {
    "id": "b2751813-f596-4932-aa8e-f3e501170f3b",
    "stem": "Conduct research on plants and crops.",
    "administration_position": 289
  },
  {
    "id": "4501107d-fde3-43b8-92d8-d45986a2c16d",
    "stem": "Translate documents into other languages.",
    "administration_position": 290
  },
  {
    "id": "414fe4b7-b394-4e4c-9f73-9eb9b5e82fdd",
    "stem": "Maintain lawns and gardens.",
    "administration_position": 291
  },
  {
    "id": "d9e87b64-f016-4139-b63e-a8edd399be75",
    "stem": "Evaluate someone's range of motion after an injury.",
    "administration_position": 292
  },
  {
    "id": "edc23070-db9f-416f-94c2-b6bfa4a63927",
    "stem": "Develop exercise routines for clients wanting to lose weight.",
    "administration_position": 293
  },
  {
    "id": "d7d1a0d8-39ec-4151-ad2f-05bbb356627a",
    "stem": "Administer a company's compensation and benefits program, ensuring employees get paid fairly.",
    "administration_position": 294
  },
  {
    "id": "cb9998fb-2ac1-4bd4-9f5b-8a4c2f18eb91",
    "stem": "Fill cavities in teeth.",
    "administration_position": 295
  },
  {
    "id": "8fe824c6-a36e-4482-bf33-43a3eb81586b",
    "stem": "Cut down trees using a chainsaw.",
    "administration_position": 296
  },
  {
    "id": "ae8dc2c2-d388-493f-8e64-e2f7632d5f69",
    "stem": "Run activities for young children.",
    "administration_position": 297
  },
  {
    "id": "0fdd2e49-f941-47d1-aba0-28e3f3700986",
    "stem": "Treat injured or sick animals.",
    "administration_position": 298
  },
  {
    "id": "3f745c89-0d19-4e3e-a2c4-de438d5cfd20",
    "stem": "Examine someone's eyes, looking for the cause of vision problems.",
    "administration_position": 299
  },
  {
    "id": "49632476-c335-48fc-888a-87e0d89960a2",
    "stem": "Write legal opinions.",
    "administration_position": 300
  },
  {
    "id": "6179dac3-2496-44c5-bd7b-e5ce5c903251",
    "stem": "Maintain computer hardware in a datacenter.",
    "administration_position": 301
  },
  {
    "id": "f60b84cb-fe03-40ee-a026-17a9b43c287c",
    "stem": "Curate a collection of historic objects.",
    "administration_position": 302
  },
  {
    "id": "2ae1024e-b565-418f-83d7-15bfa8797a22",
    "stem": "Provide therapy to people who have a mental illness.",
    "administration_position": 303
  },
  {
    "id": "3ce90668-dee4-4c35-8770-9bc060354511",
    "stem": "Create social media content promoting a product or service.",
    "administration_position": 304
  },
  {
    "id": "f4cee1c3-a6f9-42f8-9aff-b483a076d4bb",
    "stem": "Ensure the safety and comfort of passengers during flights.",
    "administration_position": 305
  },
  {
    "id": "994ff2d9-95a0-44a7-a54f-ff6c33ba18b8",
    "stem": "Read announcements into a microphone.",
    "administration_position": 306
  },
  {
    "id": "4e9be477-e6ee-4cd4-bc29-52820b05d816",
    "stem": "Learn to navigate large ships.",
    "administration_position": 307
  },
  {
    "id": "9ff8a701-79e5-47ba-9158-d367a512811d",
    "stem": "Help a company reduce its environmental footprint, using fewer resources.",
    "administration_position": 308
  },
  {
    "id": "1cb613c5-ae50-489c-a4a0-973f883df8d9",
    "stem": "Conduct an orchestra.",
    "administration_position": 309
  },
  {
    "id": "6228049e-124f-4a3a-b536-cf60af9f568c",
    "stem": "Do your family's household chores.",
    "administration_position": 310
  },
  {
    "id": "1caaf3da-609d-4caf-ac73-b8ea9b5c93f3",
    "stem": "Participate in group meetings exploring a religion.",
    "administration_position": 311
  },
  {
    "id": "fdfecdee-2630-4662-8ea4-3639a2e18b3f",
    "stem": "Create financial plans for clients.",
    "administration_position": 312
  },
  {
    "id": "1e7b12bb-51c9-437a-a925-391367e66eed",
    "stem": "Perform maintenance on a train.",
    "administration_position": 313
  },
  {
    "id": "41ceb4cf-b819-4900-ae23-c001f29d6391",
    "stem": "Replace the bandages on a patient's wound.",
    "administration_position": 314
  },
  {
    "id": "e6b81b3c-6bf7-420d-819d-95c488747dd6",
    "stem": "Collect evidence at crime scenes.",
    "administration_position": 315
  },
  {
    "id": "f6101f83-58c2-43cd-8826-a005ff757cd6",
    "stem": "Monitor patients for any problems that should be reported to a nurse.",
    "administration_position": 316
  },
  {
    "id": "80f90b44-618f-45ec-8646-9d903d6eb138",
    "stem": "Build the frame of a house.",
    "administration_position": 317
  },
  {
    "id": "69f1d9e2-ea1c-479f-ab5b-75755e48f02c",
    "stem": "Tailor clothes to adjust how they fit.",
    "administration_position": 318
  },
  {
    "id": "d0a15f80-18cb-403f-ad2c-210a270a7cfb",
    "stem": "Repair electronics.",
    "administration_position": 319
  },
  {
    "id": "dec75360-0a8a-4317-8691-8ab3dca462b0",
    "stem": "Investigate chemical reactions.",
    "administration_position": 320
  },
  {
    "id": "cedf0255-38d3-4a0e-a016-a6c6c981d212",
    "stem": "Suggest naturopathic herbs and treatments.",
    "administration_position": 321
  },
  {
    "id": "35d6b2f2-be0c-4efd-816b-a65fc9a374e1",
    "stem": "Design chemical plant equipment.",
    "administration_position": 322
  },
  {
    "id": "7bb7de77-126b-485e-8d54-a2def4c8bb44",
    "stem": "Read guages to monitor usage of gas, electricity, or water.",
    "administration_position": 323
  },
  {
    "id": "d36f6fb8-30e4-4d72-af9b-e3cd59e94cc8",
    "stem": "Perform routine bookkeeping for an office.",
    "administration_position": 324
  }
]
