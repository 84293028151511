
export const OCCUPATIONS = [
  {
    "name": "Farm Management Educator",
    "onet_occupation_id": "25-9021.00",
    "job_zone_id": 5,
    "hourly_income": "40",
    "annual_income": "83880",
    "career_growth": -2
  },
  {
    "name": "Geological Engineer",
    "onet_occupation_id": "17-2151.00",
    "job_zone_id": 4,
    "hourly_income": "64",
    "annual_income": "132430",
    "career_growth": 0.3
  },
  {
    "name": "Refractory Materials Repairers",
    "onet_occupation_id": "49-9045.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "62460",
    "career_growth": -21.4
  },
  {
    "name": "Online Merchant",
    "onet_occupation_id": "13-1199.06",
    "job_zone_id": 4,
    "hourly_income": "52",
    "annual_income": "108370",
    "career_growth": 4.1
  },
  {
    "name": "Groundskeeper",
    "onet_occupation_id": "37-3011.00",
    "job_zone_id": 1,
    "hourly_income": "23",
    "annual_income": "48650",
    "career_growth": 3.5
  },
  {
    "name": "Information Technology Project Manager",
    "onet_occupation_id": "15-1299.09",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Precision Agriculture Technician",
    "onet_occupation_id": "19-4012.01",
    "job_zone_id": 3,
    "hourly_income": "26",
    "annual_income": "55010",
    "career_growth": 4.5
  },
  {
    "name": "School Bus Monitor",
    "onet_occupation_id": "33-9094.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "40720",
    "career_growth": -3.6
  },
  {
    "name": "Forest and Conservation Worker",
    "onet_occupation_id": "45-4011.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "50040",
    "career_growth": -8.7
  },
  {
    "name": "Gambling Manager",
    "onet_occupation_id": "11-9071.00",
    "job_zone_id": 2,
    "hourly_income": "67",
    "annual_income": "138570",
    "career_growth": 2.6
  },
  {
    "name": "Wood Patternmaker",
    "onet_occupation_id": "51-7032.00",
    "job_zone_id": 3,
    "hourly_income": "26",
    "annual_income": "53660",
    "career_growth": -5.1
  },
  {
    "name": "Cashier",
    "onet_occupation_id": "41-2011.00",
    "job_zone_id": 2,
    "hourly_income": "17",
    "annual_income": "35940",
    "career_growth": -10.4
  },
  {
    "name": "Tool Maker",
    "onet_occupation_id": "51-4111.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "74110",
    "career_growth": -8.5
  },
  {
    "name": "Architectural Drafter",
    "onet_occupation_id": "17-3011.00",
    "job_zone_id": 3,
    "hourly_income": "37",
    "annual_income": "77100",
    "career_growth": 0.6
  },
  {
    "name": "Exercise Physiologist",
    "onet_occupation_id": "29-1128.00",
    "job_zone_id": 4,
    "hourly_income": "38",
    "annual_income": "79210",
    "career_growth": 10.2
  },
  {
    "name": "Industrial Designer",
    "onet_occupation_id": "27-1021.00",
    "job_zone_id": 4,
    "hourly_income": "58",
    "annual_income": "120380",
    "career_growth": 2
  },
  {
    "name": "Wood Model Maker",
    "onet_occupation_id": "51-7031.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "75570",
    "career_growth": -3.6
  },
  {
    "name": "Measurer/Recordkeeper",
    "onet_occupation_id": "43-5111.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51690",
    "career_growth": -2.8
  },
  {
    "name": "Wind Energy Operations Manager",
    "onet_occupation_id": "11-9199.09",
    "job_zone_id": 3,
    "hourly_income": "89",
    "annual_income": "184990",
    "career_growth": 3.3
  },
  {
    "name": "Delivery Driver",
    "onet_occupation_id": "53-3033.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52710",
    "career_growth": 11.5
  },
  {
    "name": "Health Services Manager",
    "onet_occupation_id": "11-9111.00",
    "job_zone_id": 4,
    "hourly_income": "83",
    "annual_income": "171620",
    "career_growth": 28.4
  },
  {
    "name": "Electronics Repairer for Commercial Equipment",
    "onet_occupation_id": "49-2094.00",
    "job_zone_id": 3,
    "hourly_income": "43",
    "annual_income": "89580",
    "career_growth": -0.7
  },
  {
    "name": "Coroner",
    "onet_occupation_id": "13-1041.06",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "Home Appliance Repairer",
    "onet_occupation_id": "49-9031.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "65520",
    "career_growth": -4.3
  },
  {
    "name": "Gas Attendant",
    "onet_occupation_id": "53-6031.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "40630",
    "career_growth": 0.2
  },
  {
    "name": "Loan Officer",
    "onet_occupation_id": "13-2072.00",
    "job_zone_id": 4,
    "hourly_income": "68",
    "annual_income": "141180",
    "career_growth": 2.9
  },
  {
    "name": "Locomotive Engineer",
    "onet_occupation_id": "53-4011.00",
    "job_zone_id": 2,
    "hourly_income": "45",
    "annual_income": "93030",
    "career_growth": 0.8
  },
  {
    "name": "Blockchain Engineer",
    "onet_occupation_id": "15-1299.07",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Probation Officer",
    "onet_occupation_id": "21-1092.00",
    "job_zone_id": 4,
    "hourly_income": "47",
    "annual_income": "97300",
    "career_growth": 2.5
  },
  {
    "name": "Film Editor",
    "onet_occupation_id": "27-4032.00",
    "job_zone_id": 4,
    "hourly_income": "55",
    "annual_income": "113370",
    "career_growth": 9.4
  },
  {
    "name": "Music Therapist",
    "onet_occupation_id": "29-1129.02",
    "job_zone_id": 4,
    "hourly_income": "65",
    "annual_income": "135180",
    "career_growth": 12.2
  },
  {
    "name": "Instructional Coordinator",
    "onet_occupation_id": "25-9031.00",
    "job_zone_id": 5,
    "hourly_income": "48",
    "annual_income": "98930",
    "career_growth": 2.5
  },
  {
    "name": "Fraud Analyst",
    "onet_occupation_id": "13-2099.04",
    "job_zone_id": 4,
    "hourly_income": "56",
    "annual_income": "115850",
    "career_growth": 6.1
  },
  {
    "name": "Brokerage Clerk",
    "onet_occupation_id": "43-4011.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "80630",
    "career_growth": -8.9
  },
  {
    "name": "Logistics Engineer",
    "onet_occupation_id": "13-1081.01",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "109860",
    "career_growth": 18.3
  },
  {
    "name": "Proofreader",
    "onet_occupation_id": "43-9081.00",
    "job_zone_id": 4,
    "hourly_income": "33",
    "annual_income": "68980",
    "career_growth": -3.9
  },
  {
    "name": "Passenger Attendant",
    "onet_occupation_id": "53-6061.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47520",
    "career_growth": 4.1
  },
  {
    "name": "Rail Car Repairer",
    "onet_occupation_id": "49-3043.00",
    "job_zone_id": 2,
    "hourly_income": "37",
    "annual_income": "77510",
    "career_growth": 3.6
  },
  {
    "name": "Software Developer",
    "onet_occupation_id": "15-1252.00",
    "job_zone_id": 4,
    "hourly_income": "88",
    "annual_income": "182570",
    "career_growth": 25.7
  },
  {
    "name": "Financial Manager",
    "onet_occupation_id": "11-3031.00",
    "job_zone_id": 4,
    "hourly_income": "110",
    "annual_income": "228160",
    "career_growth": 16
  },
  {
    "name": "Loan Interviewer",
    "onet_occupation_id": "43-4131.00",
    "job_zone_id": 3,
    "hourly_income": "34",
    "annual_income": "70660",
    "career_growth": -1
  },
  {
    "name": "Biology Professor",
    "onet_occupation_id": "25-1042.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "125730",
    "career_growth": 8.6
  },
  {
    "name": "Pattern Maker",
    "onet_occupation_id": "51-4062.00",
    "job_zone_id": 3,
    "hourly_income": "34",
    "annual_income": "70640",
    "career_growth": -21.6
  },
  {
    "name": "Coil Winder",
    "onet_occupation_id": "51-2021.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "56340",
    "career_growth": -16.6
  },
  {
    "name": "Physical Therapist Assistant",
    "onet_occupation_id": "31-2021.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "78920",
    "career_growth": 26.1
  },
  {
    "name": "Social Work Professor",
    "onet_occupation_id": "25-1113.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "93430",
    "career_growth": 4
  },
  {
    "name": "History Professor",
    "onet_occupation_id": "25-1125.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "128010",
    "career_growth": 1.3
  },
  {
    "name": "Geothermal Production Manager",
    "onet_occupation_id": "11-3051.02",
    "job_zone_id": 3,
    "hourly_income": "74",
    "annual_income": "153570",
    "career_growth": 1.6
  },
  {
    "name": "Digital Forensics Analyst",
    "onet_occupation_id": "15-1299.06",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Fitness and Wellness Coordinator",
    "onet_occupation_id": "11-9179.01",
    "job_zone_id": 4,
    "hourly_income": "49",
    "annual_income": "102470",
    "career_growth": 4.3
  },
  {
    "name": "Farm Laborer",
    "onet_occupation_id": "45-2092.00",
    "job_zone_id": 1,
    "hourly_income": "21",
    "annual_income": "43430",
    "career_growth": -2.2
  },
  {
    "name": "Legislator",
    "onet_occupation_id": "11-1031.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "127380",
    "career_growth": 3.4
  },
  {
    "name": "Occupational Therapy Assistant",
    "onet_occupation_id": "31-2011.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "80670",
    "career_growth": 24
  },
  {
    "name": "Tire Builder",
    "onet_occupation_id": "51-9197.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53440",
    "career_growth": -4.3
  },
  {
    "name": "Financial Quantitative Analyst",
    "onet_occupation_id": "13-2099.01",
    "job_zone_id": 5,
    "hourly_income": "56",
    "annual_income": "115850",
    "career_growth": 6.1
  },
  {
    "name": "Power Plant Operator",
    "onet_occupation_id": "51-8013.00",
    "job_zone_id": 2,
    "hourly_income": "56",
    "annual_income": "116410",
    "career_growth": -12.5
  },
  {
    "name": "Book Binder",
    "onet_occupation_id": "51-5113.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52150",
    "career_growth": -16.4
  },
  {
    "name": "Environmental Compliance Inspector",
    "onet_occupation_id": "13-1041.01",
    "job_zone_id": 4,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "Hydroelectric Production Manager",
    "onet_occupation_id": "11-3051.06",
    "job_zone_id": 3,
    "hourly_income": "74",
    "annual_income": "153570",
    "career_growth": 1.6
  },
  {
    "name": "Aircraft Mechanic",
    "onet_occupation_id": "49-3011.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "86340",
    "career_growth": 4.3
  },
  {
    "name": "Investment Fund Manager",
    "onet_occupation_id": "11-3031.03",
    "job_zone_id": 5,
    "hourly_income": "110",
    "annual_income": "228160",
    "career_growth": 16
  },
  {
    "name": "Medical Transcriptionist",
    "onet_occupation_id": "31-9094.00",
    "job_zone_id": 3,
    "hourly_income": "24",
    "annual_income": "50000",
    "career_growth": -3.8
  },
  {
    "name": "Equal Opportunity Officer",
    "onet_occupation_id": "13-1041.03",
    "job_zone_id": 4,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "Cardiovascular Technician",
    "onet_occupation_id": "29-2031.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "92180",
    "career_growth": 3.7
  },
  {
    "name": "Carpet Installer",
    "onet_occupation_id": "47-2041.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72420",
    "career_growth": -11.1
  },
  {
    "name": "Refrigeration Technician",
    "onet_occupation_id": "51-9193.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "65530",
    "career_growth": 5.1
  },
  {
    "name": "Sheet Metal Worker",
    "onet_occupation_id": "47-2211.00",
    "job_zone_id": 2,
    "hourly_income": "41",
    "annual_income": "86110",
    "career_growth": -0.1
  },
  {
    "name": "Production Supervisor",
    "onet_occupation_id": "51-1011.00",
    "job_zone_id": 3,
    "hourly_income": "49",
    "annual_income": "101890",
    "career_growth": 0.3
  },
  {
    "name": "Lifeguard",
    "onet_occupation_id": "33-9092.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53870",
    "career_growth": 6.2
  },
  {
    "name": "Bill and Account Collector",
    "onet_occupation_id": "43-3011.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58210",
    "career_growth": -9.6
  },
  {
    "name": "Fast Food Cook",
    "onet_occupation_id": "35-2011.00",
    "job_zone_id": 1,
    "hourly_income": "20",
    "annual_income": "41620",
    "career_growth": -13.7
  },
  {
    "name": "Sawing Machine Operator",
    "onet_occupation_id": "51-7041.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46680",
    "career_growth": 0.1
  },
  {
    "name": "Highway Maintenance Worker",
    "onet_occupation_id": "47-4051.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60530",
    "career_growth": 7.9
  },
  {
    "name": "Aircraft Line Assembler",
    "onet_occupation_id": "51-2011.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72070",
    "career_growth": -15.2
  },
  {
    "name": "Laundry and Dry-Cleaning Worker",
    "onet_occupation_id": "51-6011.00",
    "job_zone_id": 1,
    "hourly_income": "19",
    "annual_income": "38650",
    "career_growth": -1.3
  },
  {
    "name": "Art Director",
    "onet_occupation_id": "27-1011.00",
    "job_zone_id": 4,
    "hourly_income": "74",
    "annual_income": "154000",
    "career_growth": 6.1
  },
  {
    "name": "Extruding Machine Operator",
    "onet_occupation_id": "51-6091.00",
    "job_zone_id": 2,
    "hourly_income": "36",
    "annual_income": "74570",
    "career_growth": -2.3
  },
  {
    "name": "Massage Therapist",
    "onet_occupation_id": "31-9011.00",
    "job_zone_id": 3,
    "hourly_income": "50",
    "annual_income": "104020",
    "career_growth": 18.3
  },
  {
    "name": "Correspondence Clerk",
    "onet_occupation_id": "43-4021.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49430",
    "career_growth": -5.9
  },
  {
    "name": "Remote Sensing Technician",
    "onet_occupation_id": "19-4099.03",
    "job_zone_id": 4,
    "hourly_income": "43",
    "annual_income": "90440",
    "career_growth": 3.8
  },
  {
    "name": "Cultural Studies Professor",
    "onet_occupation_id": "25-1062.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "123070",
    "career_growth": 3.9
  },
  {
    "name": "Public Relations Specialist",
    "onet_occupation_id": "27-3031.00",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "119270",
    "career_growth": 6.1
  },
  {
    "name": "Log Grader",
    "onet_occupation_id": "45-4023.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58350",
    "career_growth": -1.6
  },
  {
    "name": "Ambulance Driver",
    "onet_occupation_id": "53-3011.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "70890",
    "career_growth": 0.2
  },
  {
    "name": "Political Science Professor",
    "onet_occupation_id": "25-1065.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "142210",
    "career_growth": 3.7
  },
  {
    "name": "Control Valve Mechanic",
    "onet_occupation_id": "49-9012.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "93530",
    "career_growth": -0.6
  },
  {
    "name": "Real Estate Broker",
    "onet_occupation_id": "41-9021.00",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "140390",
    "career_growth": 3.5
  },
  {
    "name": "Operations Research Analyst",
    "onet_occupation_id": "15-2031.00",
    "job_zone_id": 5,
    "hourly_income": "58",
    "annual_income": "121460",
    "career_growth": 22.5
  },
  {
    "name": "Gambling Surveillance Officer",
    "onet_occupation_id": "33-9031.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "54660",
    "career_growth": 0.6
  },
  {
    "name": "Textile Twisting Machine Operator",
    "onet_occupation_id": "51-6064.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "41310",
    "career_growth": -8.6
  },
  {
    "name": "Aerospace Engineering Technician",
    "onet_occupation_id": "17-3021.00",
    "job_zone_id": 3,
    "hourly_income": "58",
    "annual_income": "121050",
    "career_growth": 8.3
  },
  {
    "name": "Farm Manager",
    "onet_occupation_id": "45-1011.00",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98080",
    "career_growth": 2.7
  },
  {
    "name": "Hydrologic Technician",
    "onet_occupation_id": "19-4044.00",
    "job_zone_id": 3,
    "hourly_income": "54",
    "annual_income": "111940",
    "career_growth": 0.4
  },
  {
    "name": "Microbiologist",
    "onet_occupation_id": "19-1022.00",
    "job_zone_id": 5,
    "hourly_income": "56",
    "annual_income": "116970",
    "career_growth": 5.2
  },
  {
    "name": "Heat Treating Equipment Operator",
    "onet_occupation_id": "51-4191.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60100",
    "career_growth": -9.1
  },
  {
    "name": "Bioinformatics Technician",
    "onet_occupation_id": "15-2099.01",
    "job_zone_id": 4,
    "hourly_income": "59",
    "annual_income": "122500",
    "career_growth": 6.2
  },
  {
    "name": "Business Continuity Planner",
    "onet_occupation_id": "13-1199.04",
    "job_zone_id": 4,
    "hourly_income": "52",
    "annual_income": "108370",
    "career_growth": 4.1
  },
  {
    "name": "Computer Numerically Controlled (CNC) Tool Operator",
    "onet_occupation_id": "51-9161.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "71070",
    "career_growth": -8.1
  },
  {
    "name": "Bartender",
    "onet_occupation_id": "35-3011.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51900",
    "career_growth": 3.3
  },
  {
    "name": "Artillery Crew Member",
    "onet_occupation_id": "55-3014.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Security Guard",
    "onet_occupation_id": "33-9032.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52940",
    "career_growth": -1.3
  },
  {
    "name": "Budget Analyst",
    "onet_occupation_id": "13-2031.00",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "109670",
    "career_growth": 3.3
  },
  {
    "name": "Health Education Specialist",
    "onet_occupation_id": "21-1091.00",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "103260",
    "career_growth": 7.2
  },
  {
    "name": "Purchasing Agent",
    "onet_occupation_id": "13-1023.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Pediatric Surgeon",
    "onet_occupation_id": "29-1243.00",
    "job_zone_id": 5,
    "hourly_income": "176",
    "annual_income": "365130",
    "career_growth": 1
  },
  {
    "name": "Taxi Driver",
    "onet_occupation_id": "53-3054.00",
    "job_zone_id": 2,
    "hourly_income": "19",
    "annual_income": "40020",
    "career_growth": 20.6
  },
  {
    "name": "Chief Sustainability Officer",
    "onet_occupation_id": "11-1011.03",
    "job_zone_id": 5,
    "hourly_income": "156",
    "annual_income": "324370",
    "career_growth": -8.2
  },
  {
    "name": "Electronics Repairer for Power Stations",
    "onet_occupation_id": "49-2095.00",
    "job_zone_id": 3,
    "hourly_income": "57",
    "annual_income": "118660",
    "career_growth": -2.7
  },
  {
    "name": "Watch and Clock Repairer",
    "onet_occupation_id": "49-9064.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "81590",
    "career_growth": -29.8
  },
  {
    "name": "Purchasing Manager",
    "onet_occupation_id": "11-3061.00",
    "job_zone_id": 4,
    "hourly_income": "85",
    "annual_income": "177080",
    "career_growth": 4.4
  },
  {
    "name": "Biochemist/Biophysicist",
    "onet_occupation_id": "19-1021.00",
    "job_zone_id": 5,
    "hourly_income": "65",
    "annual_income": "135220",
    "career_growth": 6.7
  },
  {
    "name": "Special Education Teaching Assistant",
    "onet_occupation_id": "25-9043.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Family Sciences Teacher",
    "onet_occupation_id": "25-1192.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "154720",
    "career_growth": 3.9
  },
  {
    "name": "Product Demonstrator",
    "onet_occupation_id": "41-9011.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49090",
    "career_growth": 4
  },
  {
    "name": "Loss Prevention Manager",
    "onet_occupation_id": "11-9199.08",
    "job_zone_id": 4,
    "hourly_income": "89",
    "annual_income": "184990",
    "career_growth": 3.3
  },
  {
    "name": "Cabinetmaker",
    "onet_occupation_id": "51-7011.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "56510",
    "career_growth": -1.9
  },
  {
    "name": "Booth Cashier",
    "onet_occupation_id": "41-2012.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53430",
    "career_growth": -5.6
  },
  {
    "name": "Dietetic Technician",
    "onet_occupation_id": "29-2051.00",
    "job_zone_id": 3,
    "hourly_income": "24",
    "annual_income": "49580",
    "career_growth": 3.5
  },
  {
    "name": "Embalmer",
    "onet_occupation_id": "39-4011.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "78940",
    "career_growth": 0.9
  },
  {
    "name": "Solar Energy Systems Engineer",
    "onet_occupation_id": "17-2199.11",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Mobile Heavy Equipment Mechanic",
    "onet_occupation_id": "49-3042.00",
    "job_zone_id": 3,
    "hourly_income": "41",
    "annual_income": "85160",
    "career_growth": 5.1
  },
  {
    "name": "Floor Finisher",
    "onet_occupation_id": "47-2043.00",
    "job_zone_id": 1,
    "hourly_income": "32",
    "annual_income": "66490",
    "career_growth": 0.1
  },
  {
    "name": "Neurologist",
    "onet_occupation_id": "29-1217.00",
    "job_zone_id": 5,
    "hourly_income": "141",
    "annual_income": "293990",
    "career_growth": 3.2
  },
  {
    "name": "Occupational Therapist",
    "onet_occupation_id": "29-1122.00",
    "job_zone_id": 5,
    "hourly_income": "53",
    "annual_income": "109220",
    "career_growth": 11.5
  },
  {
    "name": "Naval Architect",
    "onet_occupation_id": "17-2121.00",
    "job_zone_id": 4,
    "hourly_income": "70",
    "annual_income": "146600",
    "career_growth": 0.7
  },
  {
    "name": "Aerospace Engineer",
    "onet_occupation_id": "17-2011.00",
    "job_zone_id": 4,
    "hourly_income": "68",
    "annual_income": "141420",
    "career_growth": 6.1
  },
  {
    "name": "Forest Fire Prevention Specialist",
    "onet_occupation_id": "33-2022.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "91630",
    "career_growth": 14.8
  },
  {
    "name": "Personal Care Aide",
    "onet_occupation_id": "31-1122.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Rigger",
    "onet_occupation_id": "49-9096.00",
    "job_zone_id": 2,
    "hourly_income": "41",
    "annual_income": "86150",
    "career_growth": 1.4
  },
  {
    "name": "Psychiatric Technician",
    "onet_occupation_id": "29-2053.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "61050",
    "career_growth": 9.7
  },
  {
    "name": "Astronomer",
    "onet_occupation_id": "19-2011.00",
    "job_zone_id": 5,
    "hourly_income": "81",
    "annual_income": "167590",
    "career_growth": 4.6
  },
  {
    "name": "Water Treatment Plant Operator",
    "onet_occupation_id": "51-8031.00",
    "job_zone_id": 3,
    "hourly_income": "37",
    "annual_income": "77890",
    "career_growth": -6.3
  },
  {
    "name": "Segmental Paver",
    "onet_occupation_id": "47-4091.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Recreational Therapist",
    "onet_occupation_id": "29-1125.00",
    "job_zone_id": 4,
    "hourly_income": "38",
    "annual_income": "78190",
    "career_growth": 3.6
  },
  {
    "name": "Outdoor Power Equipment Mechanic",
    "onet_occupation_id": "49-3053.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52900",
    "career_growth": 1.2
  },
  {
    "name": "Quality Control Systems Manager",
    "onet_occupation_id": "11-3051.01",
    "job_zone_id": 4,
    "hourly_income": "74",
    "annual_income": "153570",
    "career_growth": 1.6
  },
  {
    "name": "Special Effects Artist/Animator",
    "onet_occupation_id": "27-1014.00",
    "job_zone_id": 4,
    "hourly_income": "65",
    "annual_income": "134820",
    "career_growth": 8.2
  },
  {
    "name": "Garbage Collector",
    "onet_occupation_id": "53-7081.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "64320",
    "career_growth": 3.2
  },
  {
    "name": "Air Crew Officer",
    "onet_occupation_id": "55-1011.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Historian",
    "onet_occupation_id": "19-3093.00",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "119000",
    "career_growth": 3.1
  },
  {
    "name": "Kindergarten Special Education Teacher",
    "onet_occupation_id": "25-2055.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Epidemiologist",
    "onet_occupation_id": "19-1041.00",
    "job_zone_id": 5,
    "hourly_income": "62",
    "annual_income": "128860",
    "career_growth": 26.7
  },
  {
    "name": "Restaurant Host/Hostess",
    "onet_occupation_id": "35-9031.00",
    "job_zone_id": 2,
    "hourly_income": "18",
    "annual_income": "38270",
    "career_growth": 0.5
  },
  {
    "name": "Event Planner",
    "onet_occupation_id": "13-1121.00",
    "job_zone_id": 4,
    "hourly_income": "37",
    "annual_income": "76650",
    "career_growth": 7.5
  },
  {
    "name": "Technical Education High School Teacher",
    "onet_occupation_id": "25-2032.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "94780",
    "career_growth": -0.2
  },
  {
    "name": "Extraction Worker's Helper",
    "onet_occupation_id": "47-5081.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55940",
    "career_growth": 0.8
  },
  {
    "name": "Food Service Manager",
    "onet_occupation_id": "11-9051.00",
    "job_zone_id": 2,
    "hourly_income": "43",
    "annual_income": "88770",
    "career_growth": 0.5
  },
  {
    "name": "Aircraft Launch and Recovery Specialist",
    "onet_occupation_id": "55-3012.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Printing Press Operator",
    "onet_occupation_id": "51-5112.00",
    "job_zone_id": 2,
    "hourly_income": "46",
    "annual_income": "95900",
    "career_growth": -8.5
  },
  {
    "name": "Pump Operator",
    "onet_occupation_id": "53-7072.00",
    "job_zone_id": 2,
    "hourly_income": "36",
    "annual_income": "75050",
    "career_growth": 3.6
  },
  {
    "name": "Hairstylist",
    "onet_occupation_id": "39-5012.00",
    "job_zone_id": 3,
    "hourly_income": "34",
    "annual_income": "70260",
    "career_growth": 7.7
  },
  {
    "name": "Painter's Helper",
    "onet_occupation_id": "47-3014.00",
    "job_zone_id": 1,
    "hourly_income": "26",
    "annual_income": "54870",
    "career_growth": 1.5
  },
  {
    "name": "Computer Numerically Controlled (CNC) Tool Programmer",
    "onet_occupation_id": "51-9162.00",
    "job_zone_id": 3,
    "hourly_income": "41",
    "annual_income": "86050",
    "career_growth": 16
  },
  {
    "name": "Furniture Finisher",
    "onet_occupation_id": "51-7021.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55700",
    "career_growth": -0.7
  },
  {
    "name": "Childcare Worker",
    "onet_occupation_id": "39-9011.00",
    "job_zone_id": 2,
    "hourly_income": "19",
    "annual_income": "40370",
    "career_growth": -2
  },
  {
    "name": "Radiologic Technologist",
    "onet_occupation_id": "29-2034.00",
    "job_zone_id": 3,
    "hourly_income": "48",
    "annual_income": "99680",
    "career_growth": 5.5
  },
  {
    "name": "Packaging Machine Operator",
    "onet_occupation_id": "51-9111.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47160",
    "career_growth": 4.6
  },
  {
    "name": "Magnetic Resonance Imaging Technologist",
    "onet_occupation_id": "29-2035.00",
    "job_zone_id": 3,
    "hourly_income": "52",
    "annual_income": "108990",
    "career_growth": 7.7
  },
  {
    "name": "Steel Worker",
    "onet_occupation_id": "47-2221.00",
    "job_zone_id": 2,
    "hourly_income": "46",
    "annual_income": "95250",
    "career_growth": 1.8
  },
  {
    "name": "Geographer",
    "onet_occupation_id": "19-3092.00",
    "job_zone_id": 4,
    "hourly_income": "58",
    "annual_income": "120530",
    "career_growth": 1.1
  },
  {
    "name": "Recreation Worker",
    "onet_occupation_id": "39-9032.00",
    "job_zone_id": 4,
    "hourly_income": "21",
    "annual_income": "43170",
    "career_growth": 4.6
  },
  {
    "name": "Motion Picture Projectionist",
    "onet_occupation_id": "39-3021.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53540",
    "career_growth": -9.6
  },
  {
    "name": "Dredge Operator",
    "onet_occupation_id": "53-7031.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "68970",
    "career_growth": 0.3
  },
  {
    "name": "Cytogenetic Technologist",
    "onet_occupation_id": "29-2011.01",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Optician",
    "onet_occupation_id": "29-2081.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67460",
    "career_growth": 2.7
  },
  {
    "name": "Telecommunications Equipment Installer",
    "onet_occupation_id": "49-2022.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "87540",
    "career_growth": 6.3
  },
  {
    "name": "High School Special Education Teacher",
    "onet_occupation_id": "25-2058.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "97690",
    "career_growth": 0.5
  },
  {
    "name": "Bailiff",
    "onet_occupation_id": "33-3011.00",
    "job_zone_id": 3,
    "hourly_income": "48",
    "annual_income": "99220",
    "career_growth": -2.3
  },
  {
    "name": "Compliance Officer",
    "onet_occupation_id": "13-1041.00",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "Rancher",
    "onet_occupation_id": "45-2093.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "48490",
    "career_growth": -5.3
  },
  {
    "name": "Production Clerk",
    "onet_occupation_id": "43-5061.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "63890",
    "career_growth": 4.2
  },
  {
    "name": "Structural Metal Fitter",
    "onet_occupation_id": "51-2041.00",
    "job_zone_id": 3,
    "hourly_income": "27",
    "annual_income": "55960",
    "career_growth": -16.4
  },
  {
    "name": "Information Security Engineer",
    "onet_occupation_id": "15-1299.05",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Autobody Repairer",
    "onet_occupation_id": "49-3021.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "65170",
    "career_growth": 1.2
  },
  {
    "name": "Brickmason's Helper",
    "onet_occupation_id": "47-3011.00",
    "job_zone_id": 2,
    "hourly_income": "41",
    "annual_income": "84250",
    "career_growth": -10.9
  },
  {
    "name": "Technical Writer",
    "onet_occupation_id": "27-3042.00",
    "job_zone_id": 4,
    "hourly_income": "58",
    "annual_income": "120940",
    "career_growth": 6.9
  },
  {
    "name": "Driver Salesperson",
    "onet_occupation_id": "53-3031.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46160",
    "career_growth": 7.7
  },
  {
    "name": "Oil and Gas Service Unit Operator",
    "onet_occupation_id": "47-5013.00",
    "job_zone_id": 2,
    "hourly_income": "47",
    "annual_income": "96940",
    "career_growth": 0.1
  },
  {
    "name": "Teaching Assistant",
    "onet_occupation_id": "25-9042.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Sound Engineering Technician",
    "onet_occupation_id": "27-4014.00",
    "job_zone_id": 3,
    "hourly_income": "48",
    "annual_income": "99710",
    "career_growth": -1.2
  },
  {
    "name": "Chiropractor",
    "onet_occupation_id": "29-1011.00",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "119210",
    "career_growth": 8.8
  },
  {
    "name": "Job Analyst",
    "onet_occupation_id": "13-1141.00",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "95680",
    "career_growth": 7
  },
  {
    "name": "Flight Attendant",
    "onet_occupation_id": "53-2031.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "108310",
    "career_growth": 11.4
  },
  {
    "name": "Aviation Inspector",
    "onet_occupation_id": "53-6051.01",
    "job_zone_id": 3,
    "hourly_income": "57",
    "annual_income": "117970",
    "career_growth": 2.7
  },
  {
    "name": "Logistician",
    "onet_occupation_id": "13-1081.00",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "109860",
    "career_growth": 18.3
  },
  {
    "name": "Chemical Technician",
    "onet_occupation_id": "19-4031.00",
    "job_zone_id": 3,
    "hourly_income": "33",
    "annual_income": "69490",
    "career_growth": 3.2
  },
  {
    "name": "Financial Examiner",
    "onet_occupation_id": "13-2061.00",
    "job_zone_id": 4,
    "hourly_income": "72",
    "annual_income": "150590",
    "career_growth": 19.5
  },
  {
    "name": "Courier",
    "onet_occupation_id": "43-5021.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51180",
    "career_growth": 1.6
  },
  {
    "name": "Ophthalmic Medical Technologist",
    "onet_occupation_id": "29-2099.05",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67380",
    "career_growth": 6.6
  },
  {
    "name": "Building Insulation Worker",
    "onet_occupation_id": "47-2131.00",
    "job_zone_id": 2,
    "hourly_income": "40",
    "annual_income": "84140",
    "career_growth": 1.7
  },
  {
    "name": "Photonics Technician",
    "onet_occupation_id": "17-3029.08",
    "job_zone_id": 3,
    "hourly_income": "50",
    "annual_income": "103110",
    "career_growth": 3.1
  },
  {
    "name": "Avionics Technician",
    "onet_occupation_id": "49-2091.00",
    "job_zone_id": 3,
    "hourly_income": "54",
    "annual_income": "112600",
    "career_growth": 4.3
  },
  {
    "name": "Locomotive Switch Operator",
    "onet_occupation_id": "53-4022.00",
    "job_zone_id": 2,
    "hourly_income": "38",
    "annual_income": "78870",
    "career_growth": 1.2
  },
  {
    "name": "Penetration Tester",
    "onet_occupation_id": "15-1299.04",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Quarry Rock Splitter",
    "onet_occupation_id": "47-5051.00",
    "job_zone_id": 1,
    "hourly_income": "32",
    "annual_income": "66940",
    "career_growth": 1.9
  },
  {
    "name": "Referee",
    "onet_occupation_id": "27-2023.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "62760",
    "career_growth": 9.7
  },
  {
    "name": "Economist",
    "onet_occupation_id": "19-3011.00",
    "job_zone_id": 5,
    "hourly_income": "73",
    "annual_income": "151850",
    "career_growth": 6.3
  },
  {
    "name": "Supervisor of Correctional Officers",
    "onet_occupation_id": "33-1011.00",
    "job_zone_id": 3,
    "hourly_income": "55",
    "annual_income": "113750",
    "career_growth": -2.7
  },
  {
    "name": "Funeral Attendant",
    "onet_occupation_id": "39-4021.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46500",
    "career_growth": 2.8
  },
  {
    "name": "Clinical Neuropsychologist",
    "onet_occupation_id": "19-3039.03",
    "job_zone_id": 5,
    "hourly_income": "58",
    "annual_income": "120220",
    "career_growth": 5.1
  },
  {
    "name": "Aircraft Service Attendant",
    "onet_occupation_id": "53-6032.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "57740",
    "career_growth": 4.7
  },
  {
    "name": "Traffic Technician",
    "onet_occupation_id": "53-6041.00",
    "job_zone_id": 3,
    "hourly_income": "37",
    "annual_income": "76560",
    "career_growth": 3.5
  },
  {
    "name": "Typist",
    "onet_occupation_id": "43-9022.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "55080",
    "career_growth": -38.6
  },
  {
    "name": "Journalist",
    "onet_occupation_id": "27-3023.00",
    "job_zone_id": 4,
    "hourly_income": "77",
    "annual_income": "160260",
    "career_growth": -3.3
  },
  {
    "name": "Telemarketer",
    "onet_occupation_id": "41-9041.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "48070",
    "career_growth": -20.6
  },
  {
    "name": "Boiler Operator",
    "onet_occupation_id": "51-8021.00",
    "job_zone_id": 3,
    "hourly_income": "48",
    "annual_income": "99070",
    "career_growth": 2.8
  },
  {
    "name": "Insurance Sales Agent",
    "onet_occupation_id": "41-3021.00",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "104850",
    "career_growth": 7.9
  },
  {
    "name": "High School Teacher",
    "onet_occupation_id": "25-2031.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "92960",
    "career_growth": 1
  },
  {
    "name": "Medical Secretary",
    "onet_occupation_id": "43-6013.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "50870",
    "career_growth": 6.7
  },
  {
    "name": "Crane Operator",
    "onet_occupation_id": "53-7021.00",
    "job_zone_id": 3,
    "hourly_income": "66",
    "annual_income": "136330",
    "career_growth": 0.2
  },
  {
    "name": "Administrative Services Manager",
    "onet_occupation_id": "11-3012.00",
    "job_zone_id": 3,
    "hourly_income": "71",
    "annual_income": "146810",
    "career_growth": 5.6
  },
  {
    "name": "Lawyer",
    "onet_occupation_id": "23-1011.00",
    "job_zone_id": 5,
    "hourly_income": "109",
    "annual_income": "226510",
    "career_growth": 7.5
  },
  {
    "name": "Firefighter",
    "onet_occupation_id": "33-2011.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "78350",
    "career_growth": 3.6
  },
  {
    "name": "Server Assistant",
    "onet_occupation_id": "35-9011.00",
    "job_zone_id": 1,
    "hourly_income": "20",
    "annual_income": "41780",
    "career_growth": 4.2
  },
  {
    "name": "Talent Director",
    "onet_occupation_id": "27-2012.04",
    "job_zone_id": 4,
    "hourly_income": "72",
    "annual_income": "150240",
    "career_growth": 6.7
  },
  {
    "name": "Pile Driver Operator",
    "onet_occupation_id": "47-2072.00",
    "job_zone_id": 2,
    "hourly_income": "52",
    "annual_income": "108320",
    "career_growth": 3.2
  },
  {
    "name": "Elevator Installer",
    "onet_occupation_id": "47-4021.00",
    "job_zone_id": 3,
    "hourly_income": "60",
    "annual_income": "123910",
    "career_growth": 1.5
  },
  {
    "name": "Property Appraiser",
    "onet_occupation_id": "13-2022.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Naturopathic Physician",
    "onet_occupation_id": "29-1299.01",
    "job_zone_id": 5,
    "hourly_income": "70",
    "annual_income": "146440",
    "career_growth": 2
  },
  {
    "name": "Fiberglass Laminator",
    "onet_occupation_id": "51-2051.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "57930",
    "career_growth": -0.3
  },
  {
    "name": "Supervisor of Police and Detectives",
    "onet_occupation_id": "33-1012.00",
    "job_zone_id": 3,
    "hourly_income": "72",
    "annual_income": "150690",
    "career_growth": 3.1
  },
  {
    "name": "Mental Health Counselor",
    "onet_occupation_id": "21-1014.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Technical Education Middle School Teacher",
    "onet_occupation_id": "25-2023.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "89930",
    "career_growth": 0
  },
  {
    "name": "Solar Sales Representative",
    "onet_occupation_id": "41-4011.07",
    "job_zone_id": 3,
    "hourly_income": "77",
    "annual_income": "161060",
    "career_growth": 3.7
  },
  {
    "name": "Customs Officer",
    "onet_occupation_id": "33-3051.04",
    "job_zone_id": 3,
    "hourly_income": "50",
    "annual_income": "104010",
    "career_growth": 3.3
  },
  {
    "name": "Archivist",
    "onet_occupation_id": "25-4011.00",
    "job_zone_id": 5,
    "hourly_income": "42",
    "annual_income": "86900",
    "career_growth": 8.2
  },
  {
    "name": "Garment Presser",
    "onet_occupation_id": "51-6021.00",
    "job_zone_id": 1,
    "hourly_income": "19",
    "annual_income": "39550",
    "career_growth": -21.8
  },
  {
    "name": "Gas Plant Operator",
    "onet_occupation_id": "51-8092.00",
    "job_zone_id": 2,
    "hourly_income": "50",
    "annual_income": "104580",
    "career_growth": -11.7
  },
  {
    "name": "Dancer",
    "onet_occupation_id": "27-2031.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "0",
    "career_growth": 5.3
  },
  {
    "name": "Advertising Manager",
    "onet_occupation_id": "11-2011.00",
    "job_zone_id": 4,
    "hourly_income": "93",
    "annual_income": "194240",
    "career_growth": 1.9
  },
  {
    "name": "Nuclear Medicine Technologist",
    "onet_occupation_id": "29-2033.00",
    "job_zone_id": 3,
    "hourly_income": "62",
    "annual_income": "128900",
    "career_growth": 0.4
  },
  {
    "name": "Orthodontist",
    "onet_occupation_id": "29-1023.00",
    "job_zone_id": 5,
    "hourly_income": "124",
    "annual_income": "257550",
    "career_growth": 4.5
  },
  {
    "name": "Nuclear Power Reactor Operator",
    "onet_occupation_id": "51-8011.00",
    "job_zone_id": 3,
    "hourly_income": "62",
    "annual_income": "129830",
    "career_growth": -3.3
  },
  {
    "name": "Midwife",
    "onet_occupation_id": "29-9099.01",
    "job_zone_id": 5,
    "hourly_income": "44",
    "annual_income": "92360",
    "career_growth": 5.2
  },
  {
    "name": "Kiln Operator",
    "onet_occupation_id": "51-9051.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "63160",
    "career_growth": 0.4
  },
  {
    "name": "Supervisors of Air Crew Members",
    "onet_occupation_id": "55-2011.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Food Science Technician",
    "onet_occupation_id": "19-4013.00",
    "job_zone_id": 3,
    "hourly_income": "31",
    "annual_income": "63550",
    "career_growth": 5.2
  },
  {
    "name": "Creative Writer",
    "onet_occupation_id": "27-3043.05",
    "job_zone_id": 4,
    "hourly_income": "62",
    "annual_income": "129340",
    "career_growth": 3.7
  },
  {
    "name": "Water Resource Specialist",
    "onet_occupation_id": "11-9121.02",
    "job_zone_id": 4,
    "hourly_income": "109",
    "annual_income": "227460",
    "career_growth": 4.8
  },
  {
    "name": "Tile and Stone Setter",
    "onet_occupation_id": "47-2044.00",
    "job_zone_id": 2,
    "hourly_income": "38",
    "annual_income": "78440",
    "career_growth": 6.9
  },
  {
    "name": "Food Batchmaker",
    "onet_occupation_id": "51-3092.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43320",
    "career_growth": 7.8
  },
  {
    "name": "Chef",
    "onet_occupation_id": "35-1011.00",
    "job_zone_id": 3,
    "hourly_income": "40",
    "annual_income": "83600",
    "career_growth": 5.3
  },
  {
    "name": "House Painter",
    "onet_occupation_id": "47-2141.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "65960",
    "career_growth": 1.5
  },
  {
    "name": "Barista",
    "onet_occupation_id": "35-3023.01",
    "job_zone_id": 1,
    "hourly_income": "17",
    "annual_income": "36040",
    "career_growth": 1.5
  },
  {
    "name": "Biofuels Production Manager",
    "onet_occupation_id": "11-3051.03",
    "job_zone_id": 4,
    "hourly_income": "74",
    "annual_income": "153570",
    "career_growth": 1.6
  },
  {
    "name": "Signal and Track Switch Repairer",
    "onet_occupation_id": "49-9097.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "86970",
    "career_growth": 1.2
  },
  {
    "name": "Family Social Worker",
    "onet_occupation_id": "21-1021.00",
    "job_zone_id": 4,
    "hourly_income": "36",
    "annual_income": "75590",
    "career_growth": 5.3
  },
  {
    "name": "Industrial Engineering Technician",
    "onet_occupation_id": "17-3026.00",
    "job_zone_id": 3,
    "hourly_income": "56",
    "annual_income": "115760",
    "career_growth": 3.2
  },
  {
    "name": "Aircraft Launch and Recovery Officer",
    "onet_occupation_id": "55-1012.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Lathe Operator",
    "onet_occupation_id": "51-4034.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58090",
    "career_growth": -9.4
  },
  {
    "name": "Electrical Engineer",
    "onet_occupation_id": "17-2071.00",
    "job_zone_id": 4,
    "hourly_income": "73",
    "annual_income": "150940",
    "career_growth": 4.2
  },
  {
    "name": "Retail Salesperson",
    "onet_occupation_id": "41-2031.00",
    "job_zone_id": 2,
    "hourly_income": "19",
    "annual_income": "40000",
    "career_growth": -2.1
  },
  {
    "name": "Health Specialties Professor",
    "onet_occupation_id": "25-1071.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "157160",
    "career_growth": 19.1
  },
  {
    "name": "Mobile Home Installer",
    "onet_occupation_id": "49-9095.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52230",
    "career_growth": -21
  },
  {
    "name": "Transportation Engineer",
    "onet_occupation_id": "17-2051.01",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "119260",
    "career_growth": 5
  },
  {
    "name": "Regulatory Affairs Manager",
    "onet_occupation_id": "11-9199.01",
    "job_zone_id": 4,
    "hourly_income": "89",
    "annual_income": "184990",
    "career_growth": 3.3
  },
  {
    "name": "Order Filler",
    "onet_occupation_id": "53-7065.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "40570",
    "career_growth": 6.3
  },
  {
    "name": "Electro-Mechanical Technician",
    "onet_occupation_id": "17-3024.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "92770",
    "career_growth": -2.7
  },
  {
    "name": "Food Roaster Operator",
    "onet_occupation_id": "51-3091.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "50210",
    "career_growth": 3
  },
  {
    "name": "Butcher",
    "onet_occupation_id": "51-3021.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "48960",
    "career_growth": -1.2
  },
  {
    "name": "Supervisor of Weapons Specialists",
    "onet_occupation_id": "55-2012.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Agricultural Engineer",
    "onet_occupation_id": "17-2021.00",
    "job_zone_id": 4,
    "hourly_income": "52",
    "annual_income": "109130",
    "career_growth": 6.3
  },
  {
    "name": "Human Factors Engineer",
    "onet_occupation_id": "17-2112.01",
    "job_zone_id": 5,
    "hourly_income": "67",
    "annual_income": "140020",
    "career_growth": 11.7
  },
  {
    "name": "Hazardous Materials Removal Worker",
    "onet_occupation_id": "47-4041.00",
    "job_zone_id": 3,
    "hourly_income": "33",
    "annual_income": "69190",
    "career_growth": 0.8
  },
  {
    "name": "Airfield Operations Specialist",
    "onet_occupation_id": "53-2022.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "90540",
    "career_growth": 4.9
  },
  {
    "name": "Middle School Special Education Teacher",
    "onet_occupation_id": "25-2057.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "93630",
    "career_growth": -0.2
  },
  {
    "name": "Nutritionist",
    "onet_occupation_id": "29-1031.00",
    "job_zone_id": 5,
    "hourly_income": "40",
    "annual_income": "83550",
    "career_growth": 6.6
  },
  {
    "name": "Credit Checker",
    "onet_occupation_id": "43-4041.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "59270",
    "career_growth": -5.8
  },
  {
    "name": "Camera Repair Technician",
    "onet_occupation_id": "49-9061.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "66510",
    "career_growth": 1.1
  },
  {
    "name": "Adult Basic Education (ABE) Teacher",
    "onet_occupation_id": "25-3011.00",
    "job_zone_id": 4,
    "hourly_income": "51",
    "annual_income": "106290",
    "career_growth": -12.6
  },
  {
    "name": "Pharmacy Technician",
    "onet_occupation_id": "29-2052.00",
    "job_zone_id": 3,
    "hourly_income": "25",
    "annual_income": "52090",
    "career_growth": 5.6
  },
  {
    "name": "Bioinformatics Scientist",
    "onet_occupation_id": "19-1029.01",
    "job_zone_id": 5,
    "hourly_income": "55",
    "annual_income": "114940",
    "career_growth": 3.9
  },
  {
    "name": "Patient Representative",
    "onet_occupation_id": "29-2099.08",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67380",
    "career_growth": 6.6
  },
  {
    "name": "Emergency Medical Technician",
    "onet_occupation_id": "29-2042.00",
    "job_zone_id": 3,
    "hourly_income": "27",
    "annual_income": "56790",
    "career_growth": 5.4
  },
  {
    "name": "Bus and Truck Mechanic",
    "onet_occupation_id": "49-3031.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "66790",
    "career_growth": 0.9
  },
  {
    "name": "Project Management Specialist",
    "onet_occupation_id": "13-1082.00",
    "job_zone_id": 4,
    "hourly_income": "70",
    "annual_income": "145790",
    "career_growth": 6.2
  },
  {
    "name": "Computer User Support Specialist",
    "onet_occupation_id": "15-1232.00",
    "job_zone_id": 3,
    "hourly_income": "37",
    "annual_income": "77320",
    "career_growth": 5
  },
  {
    "name": "Machinery Maintenance Worker",
    "onet_occupation_id": "49-9043.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "79940",
    "career_growth": 5.5
  },
  {
    "name": "Nursing Instructor",
    "onet_occupation_id": "25-1072.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "106690",
    "career_growth": 18.2
  },
  {
    "name": "Nuclear Engineer",
    "onet_occupation_id": "17-2161.00",
    "job_zone_id": 4,
    "hourly_income": "93",
    "annual_income": "193040",
    "career_growth": 1.1
  },
  {
    "name": "Anthropology/Archaeology Professor",
    "onet_occupation_id": "25-1061.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "118880",
    "career_growth": 4
  },
  {
    "name": "Power Distributor",
    "onet_occupation_id": "51-8012.00",
    "job_zone_id": 3,
    "hourly_income": "63",
    "annual_income": "131020",
    "career_growth": -5
  },
  {
    "name": "Environmental Science Professor",
    "onet_occupation_id": "25-1053.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "119740",
    "career_growth": 4.2
  },
  {
    "name": "Medical Equipment Preparer",
    "onet_occupation_id": "31-9093.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59490",
    "career_growth": 5.4
  },
  {
    "name": "Model Maker",
    "onet_occupation_id": "51-4061.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "81960",
    "career_growth": -18.8
  },
  {
    "name": "Zoologist",
    "onet_occupation_id": "19-1023.00",
    "job_zone_id": 4,
    "hourly_income": "45",
    "annual_income": "92880",
    "career_growth": 3
  },
  {
    "name": "Geographic Information Systems Technician",
    "onet_occupation_id": "15-1299.02",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Statistical Assistant",
    "onet_occupation_id": "43-9111.00",
    "job_zone_id": 4,
    "hourly_income": "35",
    "annual_income": "73640",
    "career_growth": -1.7
  },
  {
    "name": "Chauffeur",
    "onet_occupation_id": "53-3053.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "44030",
    "career_growth": 8
  },
  {
    "name": "Milling Machine Operator",
    "onet_occupation_id": "51-4035.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "71820",
    "career_growth": -13.9
  },
  {
    "name": "Biofuels Product Manager",
    "onet_occupation_id": "11-9041.01",
    "job_zone_id": 4,
    "hourly_income": "93",
    "annual_income": "193600",
    "career_growth": 4.1
  },
  {
    "name": "Computer Systems Engineer",
    "onet_occupation_id": "15-1299.08",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Vehicle Cleaner",
    "onet_occupation_id": "53-7061.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "41840",
    "career_growth": 3.7
  },
  {
    "name": "Dressing Room Attendant",
    "onet_occupation_id": "39-3093.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49650",
    "career_growth": 7
  },
  {
    "name": "Business Intelligence Analyst",
    "onet_occupation_id": "15-2051.01",
    "job_zone_id": 4,
    "hourly_income": "71",
    "annual_income": "147390",
    "career_growth": 35.2
  },
  {
    "name": "Judicial Law Clerk",
    "onet_occupation_id": "23-1012.00",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "119120",
    "career_growth": 2.3
  },
  {
    "name": "Remote Sensing Scientist",
    "onet_occupation_id": "19-2099.01",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142690",
    "career_growth": 2.1
  },
  {
    "name": "Street Vendor",
    "onet_occupation_id": "41-9091.00",
    "job_zone_id": 1,
    "hourly_income": "28",
    "annual_income": "57810",
    "career_growth": -15.9
  },
  {
    "name": "Accounting Clerk",
    "onet_occupation_id": "43-3031.00",
    "job_zone_id": 3,
    "hourly_income": "30",
    "annual_income": "63060",
    "career_growth": -6.2
  },
  {
    "name": "Clinical Research Coordinator",
    "onet_occupation_id": "11-9121.01",
    "job_zone_id": 4,
    "hourly_income": "109",
    "annual_income": "227460",
    "career_growth": 4.8
  },
  {
    "name": "Civil Engineer",
    "onet_occupation_id": "17-2051.00",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "119260",
    "career_growth": 5
  },
  {
    "name": "Machine Tool Setter",
    "onet_occupation_id": "51-4081.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "54100",
    "career_growth": 0.6
  },
  {
    "name": "Automotive Engineer",
    "onet_occupation_id": "17-2141.02",
    "job_zone_id": 4,
    "hourly_income": "61",
    "annual_income": "126080",
    "career_growth": 10
  },
  {
    "name": "Insurance Policy Processing Clerk",
    "onet_occupation_id": "43-9041.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "63860",
    "career_growth": -3.2
  },
  {
    "name": "Data Warehousing Specialist",
    "onet_occupation_id": "15-1243.01",
    "job_zone_id": 4,
    "hourly_income": "77",
    "annual_income": "159650",
    "career_growth": 10
  },
  {
    "name": "Rolling Machine Operator",
    "onet_occupation_id": "51-4023.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "62230",
    "career_growth": -11
  },
  {
    "name": "Oral Surgeon",
    "onet_occupation_id": "29-1022.00",
    "job_zone_id": 5,
    "hourly_income": "149",
    "annual_income": "309410",
    "career_growth": 5
  },
  {
    "name": "Wind Energy Engineer",
    "onet_occupation_id": "17-2199.10",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Aircraft Cargo Handling Supervisor",
    "onet_occupation_id": "53-1041.00",
    "job_zone_id": 2,
    "hourly_income": "40",
    "annual_income": "82720",
    "career_growth": 8.6
  },
  {
    "name": "Marketing Manager",
    "onet_occupation_id": "11-2021.00",
    "job_zone_id": 4,
    "hourly_income": "91",
    "annual_income": "190230",
    "career_growth": 6.6
  },
  {
    "name": "Gambling Dealer",
    "onet_occupation_id": "39-3011.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58780",
    "career_growth": 1
  },
  {
    "name": "Layout Technician",
    "onet_occupation_id": "51-4192.00",
    "job_zone_id": 2,
    "hourly_income": "37",
    "annual_income": "77280",
    "career_growth": -10.2
  },
  {
    "name": "Tire Repairer",
    "onet_occupation_id": "49-3093.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "45370",
    "career_growth": 1.1
  },
  {
    "name": "Brownfield Redevelopment Specialist",
    "onet_occupation_id": "11-9199.11",
    "job_zone_id": 4,
    "hourly_income": "89",
    "annual_income": "184990",
    "career_growth": 3.3
  },
  {
    "name": "Psychology Professor",
    "onet_occupation_id": "25-1066.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "107730",
    "career_growth": 5.2
  },
  {
    "name": "Financial Risk Specialist",
    "onet_occupation_id": "13-2054.00",
    "job_zone_id": 4,
    "hourly_income": "77",
    "annual_income": "159960",
    "career_growth": 8.2
  },
  {
    "name": "Hospitalist",
    "onet_occupation_id": "29-1229.02",
    "job_zone_id": 5,
    "hourly_income": "132",
    "annual_income": "274650",
    "career_growth": 3
  },
  {
    "name": "Audiovisual Equipment Installer",
    "onet_occupation_id": "49-2097.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "60680",
    "career_growth": -6.5
  },
  {
    "name": "Engineering Professor",
    "onet_occupation_id": "25-1032.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "134290",
    "career_growth": 9.3
  },
  {
    "name": "Oil Field Roustabout",
    "onet_occupation_id": "47-5071.00",
    "job_zone_id": 1,
    "hourly_income": "37",
    "annual_income": "77740",
    "career_growth": 2.9
  },
  {
    "name": "Mechanical Insulation Worker",
    "onet_occupation_id": "47-2132.00",
    "job_zone_id": 2,
    "hourly_income": "45",
    "annual_income": "93480",
    "career_growth": 1.7
  },
  {
    "name": "Sales Engineer",
    "onet_occupation_id": "41-9031.00",
    "job_zone_id": 4,
    "hourly_income": "90",
    "annual_income": "186950",
    "career_growth": 4.7
  },
  {
    "name": "Critical Care Nurse",
    "onet_occupation_id": "29-1141.03",
    "job_zone_id": 4,
    "hourly_income": "64",
    "annual_income": "133340",
    "career_growth": 5.6
  },
  {
    "name": "Security Manager",
    "onet_occupation_id": "11-3013.01",
    "job_zone_id": 4,
    "hourly_income": "62",
    "annual_income": "128980",
    "career_growth": 4.6
  },
  {
    "name": "Health Information Technologist",
    "onet_occupation_id": "29-9021.00",
    "job_zone_id": 3,
    "hourly_income": "46",
    "annual_income": "95520",
    "career_growth": 16.5
  },
  {
    "name": "Conservation Science Professor",
    "onet_occupation_id": "25-1043.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "110560",
    "career_growth": 5.2
  },
  {
    "name": "Postal Service Clerk",
    "onet_occupation_id": "43-5051.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60890",
    "career_growth": -6.7
  },
  {
    "name": "Chief Executive",
    "onet_occupation_id": "11-1011.00",
    "job_zone_id": 5,
    "hourly_income": "156",
    "annual_income": "324370",
    "career_growth": -8.2
  },
  {
    "name": "Database Administrator",
    "onet_occupation_id": "15-1242.00",
    "job_zone_id": 4,
    "hourly_income": "59",
    "annual_income": "122410",
    "career_growth": 7
  },
  {
    "name": "Law Professor",
    "onet_occupation_id": "25-1112.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "186120",
    "career_growth": 3
  },
  {
    "name": "Medical Laboratory Technologist",
    "onet_occupation_id": "29-2011.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Semi Truck Driver",
    "onet_occupation_id": "53-3032.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "63560",
    "career_growth": 4.1
  },
  {
    "name": "Business Professor",
    "onet_occupation_id": "25-1011.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "138530",
    "career_growth": 6.9
  },
  {
    "name": "Biomass Power Plant Manager",
    "onet_occupation_id": "11-3051.04",
    "job_zone_id": 4,
    "hourly_income": "74",
    "annual_income": "153570",
    "career_growth": 1.6
  },
  {
    "name": "Climate Change Policy Analyst",
    "onet_occupation_id": "19-2041.01",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "118430",
    "career_growth": 6.1
  },
  {
    "name": "Command and Control Center Officer",
    "onet_occupation_id": "55-1015.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Fundraiser",
    "onet_occupation_id": "13-1131.00",
    "job_zone_id": 4,
    "hourly_income": "39",
    "annual_income": "81340",
    "career_growth": 5.2
  },
  {
    "name": "Fabric Pattern Designer",
    "onet_occupation_id": "51-6092.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "93160",
    "career_growth": -7.4
  },
  {
    "name": "Paramedic",
    "onet_occupation_id": "29-2043.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "90980",
    "career_growth": 5.4
  },
  {
    "name": "Automotive Engineering Technician",
    "onet_occupation_id": "17-3027.01",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "91920",
    "career_growth": 0.6
  },
  {
    "name": "Pediatrician",
    "onet_occupation_id": "29-1221.00",
    "job_zone_id": 5,
    "hourly_income": "124",
    "annual_income": "257230",
    "career_growth": 0.9
  },
  {
    "name": "Social Science Research Assistant",
    "onet_occupation_id": "19-4061.00",
    "job_zone_id": 4,
    "hourly_income": "36",
    "annual_income": "74390",
    "career_growth": 4.8
  },
  {
    "name": "Paving Equipment Operator",
    "onet_occupation_id": "47-2071.00",
    "job_zone_id": 2,
    "hourly_income": "41",
    "annual_income": "86220",
    "career_growth": 3.7
  },
  {
    "name": "Tool Sharpener",
    "onet_occupation_id": "51-4194.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "67240",
    "career_growth": -8.5
  },
  {
    "name": "Logging Equipment Operator",
    "onet_occupation_id": "45-4022.00",
    "job_zone_id": 1,
    "hourly_income": "34",
    "annual_income": "70660",
    "career_growth": -3.8
  },
  {
    "name": "Community Health Worker",
    "onet_occupation_id": "21-1094.00",
    "job_zone_id": 4,
    "hourly_income": "32",
    "annual_income": "65760",
    "career_growth": 14.1
  },
  {
    "name": "Electrical Drafter",
    "onet_occupation_id": "17-3012.00",
    "job_zone_id": 3,
    "hourly_income": "41",
    "annual_income": "85120",
    "career_growth": 0.9
  },
  {
    "name": "Advanced Psychiatric Nurse",
    "onet_occupation_id": "29-1141.02",
    "job_zone_id": 5,
    "hourly_income": "64",
    "annual_income": "133340",
    "career_growth": 5.6
  },
  {
    "name": "Mechatronics Engineer",
    "onet_occupation_id": "17-2199.05",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Data Scientist",
    "onet_occupation_id": "15-2051.00",
    "job_zone_id": 4,
    "hourly_income": "71",
    "annual_income": "147390",
    "career_growth": 35.2
  },
  {
    "name": "Middle School Teacher",
    "onet_occupation_id": "25-2022.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "93380",
    "career_growth": 0.8
  },
  {
    "name": "Industrial Engineer",
    "onet_occupation_id": "17-2112.00",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "140020",
    "career_growth": 11.7
  },
  {
    "name": "Supervisor of Groundskeepers",
    "onet_occupation_id": "37-1012.00",
    "job_zone_id": 3,
    "hourly_income": "33",
    "annual_income": "68140",
    "career_growth": 2.6
  },
  {
    "name": "Bicycle Repairer",
    "onet_occupation_id": "49-3091.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43790",
    "career_growth": 3.8
  },
  {
    "name": "Construction Equipment Operator",
    "onet_occupation_id": "47-2073.00",
    "job_zone_id": 2,
    "hourly_income": "41",
    "annual_income": "84860",
    "career_growth": 2.7
  },
  {
    "name": "Drilling Machine Operator",
    "onet_occupation_id": "51-4032.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "67900",
    "career_growth": -18.3
  },
  {
    "name": "Shipping Clerk",
    "onet_occupation_id": "43-5071.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49380",
    "career_growth": -8.4
  },
  {
    "name": "Industrial Production Manager",
    "onet_occupation_id": "11-3051.00",
    "job_zone_id": 4,
    "hourly_income": "74",
    "annual_income": "153570",
    "career_growth": 1.6
  },
  {
    "name": "Carpenter",
    "onet_occupation_id": "47-2031.00",
    "job_zone_id": 2,
    "hourly_income": "39",
    "annual_income": "80160",
    "career_growth": 0.9
  },
  {
    "name": "Farm Product Grader",
    "onet_occupation_id": "45-2041.00",
    "job_zone_id": 1,
    "hourly_income": "20",
    "annual_income": "42160",
    "career_growth": -5.1
  },
  {
    "name": "Composer",
    "onet_occupation_id": "27-2041.00",
    "job_zone_id": 4,
    "hourly_income": "63",
    "annual_income": "131670",
    "career_growth": 1.1
  },
  {
    "name": "Supervisor of Entertainment Workers",
    "onet_occupation_id": "39-1014.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "61310",
    "career_growth": 7.7
  },
  {
    "name": "Waiter/Waitress",
    "onet_occupation_id": "35-3031.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "50790",
    "career_growth": -3.1
  },
  {
    "name": "Security Management Specialist",
    "onet_occupation_id": "13-1199.07",
    "job_zone_id": 4,
    "hourly_income": "52",
    "annual_income": "108370",
    "career_growth": 4.1
  },
  {
    "name": "Calibration Technician",
    "onet_occupation_id": "17-3028.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "81240",
    "career_growth": 4.4
  },
  {
    "name": "Benefits Manager",
    "onet_occupation_id": "11-3111.00",
    "job_zone_id": 4,
    "hourly_income": "91",
    "annual_income": "189300",
    "career_growth": 2.2
  },
  {
    "name": "Sailor",
    "onet_occupation_id": "53-5011.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "68910",
    "career_growth": 2
  },
  {
    "name": "Pipelayer",
    "onet_occupation_id": "47-2151.00",
    "job_zone_id": 2,
    "hourly_income": "40",
    "annual_income": "82880",
    "career_growth": -4.4
  },
  {
    "name": "Clinical and Counseling Psychologist",
    "onet_occupation_id": "19-3033.00",
    "job_zone_id": 5,
    "hourly_income": "79",
    "annual_income": "164110",
    "career_growth": 11.4
  },
  {
    "name": "Ship Engineer",
    "onet_occupation_id": "53-5031.00",
    "job_zone_id": 3,
    "hourly_income": "76",
    "annual_income": "158430",
    "career_growth": 1.3
  },
  {
    "name": "Surgical Assistant",
    "onet_occupation_id": "29-9093.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "92610",
    "career_growth": 5.2
  },
  {
    "name": "Electrical Engineering Technician",
    "onet_occupation_id": "17-3023.00",
    "job_zone_id": 3,
    "hourly_income": "48",
    "annual_income": "98850",
    "career_growth": 0.8
  },
  {
    "name": "Transportation Inspector",
    "onet_occupation_id": "53-6051.00",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "117970",
    "career_growth": 2.7
  },
  {
    "name": "Recreation and Fitness Teacher",
    "onet_occupation_id": "25-1193.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "129100",
    "career_growth": 3.3
  },
  {
    "name": "Psychiatric Aide",
    "onet_occupation_id": "31-1133.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52160",
    "career_growth": 4.9
  },
  {
    "name": "Library Assistant",
    "onet_occupation_id": "43-4121.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "48060",
    "career_growth": -5.8
  },
  {
    "name": "Recycling Coordinator",
    "onet_occupation_id": "53-1042.01",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Community Service Director",
    "onet_occupation_id": "11-9151.00",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "103550",
    "career_growth": 9.1
  },
  {
    "name": "Ophthalmologist",
    "onet_occupation_id": "29-1241.00",
    "job_zone_id": 5,
    "hourly_income": "133",
    "annual_income": "276580",
    "career_growth": 3.6
  },
  {
    "name": "Miller",
    "onet_occupation_id": "51-9021.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "61130",
    "career_growth": -3.5
  },
  {
    "name": "Dermatologist",
    "onet_occupation_id": "29-1213.00",
    "job_zone_id": 5,
    "hourly_income": "158",
    "annual_income": "327650",
    "career_growth": 3.1
  },
  {
    "name": "Explosives Worker",
    "onet_occupation_id": "47-5032.00",
    "job_zone_id": 2,
    "hourly_income": "43",
    "annual_income": "89130",
    "career_growth": 1.1
  },
  {
    "name": "Taper",
    "onet_occupation_id": "47-2082.00",
    "job_zone_id": 2,
    "hourly_income": "47",
    "annual_income": "97270",
    "career_growth": -2.3
  },
  {
    "name": "Computer Network Support Specialist",
    "onet_occupation_id": "15-1231.00",
    "job_zone_id": 4,
    "hourly_income": "47",
    "annual_income": "97150",
    "career_growth": 7
  },
  {
    "name": "Solar Photovoltaic Installer",
    "onet_occupation_id": "47-2231.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "64180",
    "career_growth": 22.3
  },
  {
    "name": "Gem Worker",
    "onet_occupation_id": "51-9071.06",
    "job_zone_id": 3,
    "hourly_income": "31",
    "annual_income": "63880",
    "career_growth": -2.9
  },
  {
    "name": "Paperhanger",
    "onet_occupation_id": "47-2142.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "71950",
    "career_growth": 3.7
  },
  {
    "name": "Forester",
    "onet_occupation_id": "19-1032.00",
    "job_zone_id": 4,
    "hourly_income": "42",
    "annual_income": "87840",
    "career_growth": 2.4
  },
  {
    "name": "Hydrologist",
    "onet_occupation_id": "19-2043.00",
    "job_zone_id": 5,
    "hourly_income": "61",
    "annual_income": "126500",
    "career_growth": 1.5
  },
  {
    "name": "Boat Operator",
    "onet_occupation_id": "53-5022.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "63160",
    "career_growth": 2.9
  },
  {
    "name": "Plumber",
    "onet_occupation_id": "47-2152.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "87980",
    "career_growth": 2.3
  },
  {
    "name": "Communications Systems Technician",
    "onet_occupation_id": "49-2021.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "93180",
    "career_growth": 3.4
  },
  {
    "name": "Glazier",
    "onet_occupation_id": "47-2121.00",
    "job_zone_id": 2,
    "hourly_income": "38",
    "annual_income": "79520",
    "career_growth": 2.2
  },
  {
    "name": "Computer Programmer",
    "onet_occupation_id": "15-1251.00",
    "job_zone_id": 4,
    "hourly_income": "61",
    "annual_income": "126320",
    "career_growth": -11.2
  },
  {
    "name": "Statistician",
    "onet_occupation_id": "15-2041.00",
    "job_zone_id": 5,
    "hourly_income": "66",
    "annual_income": "137840",
    "career_growth": 31.6
  },
  {
    "name": "Sales Representative for Non-Scientific Products",
    "onet_occupation_id": "41-4012.00",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "95830",
    "career_growth": 0.7
  },
  {
    "name": "Shoe Machine Operator",
    "onet_occupation_id": "51-6042.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43150",
    "career_growth": -10.9
  },
  {
    "name": "Training and Development Manager",
    "onet_occupation_id": "11-3131.00",
    "job_zone_id": 4,
    "hourly_income": "89",
    "annual_income": "185100",
    "career_growth": 6.5
  },
  {
    "name": "Credit Analyst",
    "onet_occupation_id": "13-2041.00",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "139170",
    "career_growth": -4.3
  },
  {
    "name": "Dental Laboratory Technician",
    "onet_occupation_id": "51-9081.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "62570",
    "career_growth": -4.5
  },
  {
    "name": "Phlebotomist",
    "onet_occupation_id": "31-9097.00",
    "job_zone_id": 3,
    "hourly_income": "24",
    "annual_income": "50470",
    "career_growth": 7.7
  },
  {
    "name": "Fence Erector",
    "onet_occupation_id": "47-4031.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59650",
    "career_growth": -0.7
  },
  {
    "name": "Funeral Arranger",
    "onet_occupation_id": "39-4031.00",
    "job_zone_id": 3,
    "hourly_income": "40",
    "annual_income": "82940",
    "career_growth": 3.7
  },
  {
    "name": "Computer Network Architect",
    "onet_occupation_id": "15-1241.00",
    "job_zone_id": 4,
    "hourly_income": "78",
    "annual_income": "161950",
    "career_growth": 3.5
  },
  {
    "name": "Radiation Therapist",
    "onet_occupation_id": "29-1124.00",
    "job_zone_id": 3,
    "hourly_income": "68",
    "annual_income": "141910",
    "career_growth": 2.4
  },
  {
    "name": "Industrial Ecologist",
    "onet_occupation_id": "19-2041.03",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "118430",
    "career_growth": 6.1
  },
  {
    "name": "Production Worker's Helper",
    "onet_occupation_id": "51-9198.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "45700",
    "career_growth": -9.3
  },
  {
    "name": "Histotechnologist",
    "onet_occupation_id": "29-2011.04",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Geoscientist",
    "onet_occupation_id": "19-2042.00",
    "job_zone_id": 5,
    "hourly_income": "70",
    "annual_income": "145600",
    "career_growth": 5.1
  },
  {
    "name": "Neurodiagnostic Technologist",
    "onet_occupation_id": "29-2099.01",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67380",
    "career_growth": 6.6
  },
  {
    "name": "Baggage Porter",
    "onet_occupation_id": "39-6011.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "45410",
    "career_growth": 6.3
  },
  {
    "name": "Insurance Underwriter",
    "onet_occupation_id": "13-2053.00",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "110150",
    "career_growth": -2.4
  },
  {
    "name": "Economics Professor",
    "onet_occupation_id": "25-1063.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "187610",
    "career_growth": 3.8
  },
  {
    "name": "Microsystems Engineer",
    "onet_occupation_id": "17-2199.06",
    "job_zone_id": 5,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Biological Technician",
    "onet_occupation_id": "19-4021.00",
    "job_zone_id": 4,
    "hourly_income": "31",
    "annual_income": "63730",
    "career_growth": 4.7
  },
  {
    "name": "Orthotist/Prosthetist",
    "onet_occupation_id": "29-2091.00",
    "job_zone_id": 5,
    "hourly_income": "52",
    "annual_income": "107430",
    "career_growth": 15.4
  },
  {
    "name": "Solar Thermal Installer",
    "onet_occupation_id": "47-2152.04",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "87980",
    "career_growth": 2.3
  },
  {
    "name": "Fire Inspector",
    "onet_occupation_id": "33-2021.00",
    "job_zone_id": 3,
    "hourly_income": "50",
    "annual_income": "103680",
    "career_growth": 3.7
  },
  {
    "name": "Athletic Trainer",
    "onet_occupation_id": "29-9091.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "77100",
    "career_growth": 14.1
  },
  {
    "name": "Radio Frequency Identification Device (RFID) Specialist",
    "onet_occupation_id": "17-2072.01",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "139240",
    "career_growth": 7.2
  },
  {
    "name": "Telecommunications Engineering Specialist",
    "onet_occupation_id": "15-1241.01",
    "job_zone_id": 3,
    "hourly_income": "78",
    "annual_income": "161950",
    "career_growth": 3.5
  },
  {
    "name": "Criminal Justice Professor",
    "onet_occupation_id": "25-1111.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "139510",
    "career_growth": 2.7
  },
  {
    "name": "Mechanical Engineer",
    "onet_occupation_id": "17-2141.00",
    "job_zone_id": 4,
    "hourly_income": "61",
    "annual_income": "126080",
    "career_growth": 10
  },
  {
    "name": "Preschool Special Education Teacher",
    "onet_occupation_id": "25-2051.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "99620",
    "career_growth": 1.9
  },
  {
    "name": "Nursing Assistant",
    "onet_occupation_id": "31-1131.00",
    "job_zone_id": 3,
    "hourly_income": "21",
    "annual_income": "44420",
    "career_growth": 4.1
  },
  {
    "name": "Residential Advisor",
    "onet_occupation_id": "39-9041.00",
    "job_zone_id": 3,
    "hourly_income": "22",
    "annual_income": "46630",
    "career_growth": 6
  },
  {
    "name": "Pest Control Worker",
    "onet_occupation_id": "37-2021.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52290",
    "career_growth": 3.1
  },
  {
    "name": "Audiologist",
    "onet_occupation_id": "29-1181.00",
    "job_zone_id": 5,
    "hourly_income": "62",
    "annual_income": "129580",
    "career_growth": 10.6
  },
  {
    "name": "Freight Forwarder",
    "onet_occupation_id": "43-5011.01",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "71440",
    "career_growth": 9.7
  },
  {
    "name": "Podiatrist",
    "onet_occupation_id": "29-1081.00",
    "job_zone_id": 5,
    "hourly_income": "106",
    "annual_income": "220200",
    "career_growth": 1
  },
  {
    "name": "Hearing Aid Specialist",
    "onet_occupation_id": "29-2092.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "80620",
    "career_growth": 14.5
  },
  {
    "name": "Medical Laboratory Technician",
    "onet_occupation_id": "29-2012.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Fire-Prevention and Protection Engineer",
    "onet_occupation_id": "17-2111.02",
    "job_zone_id": 4,
    "hourly_income": "62",
    "annual_income": "128220",
    "career_growth": 3.7
  },
  {
    "name": "Spa Manager",
    "onet_occupation_id": "11-9179.02",
    "job_zone_id": 3,
    "hourly_income": "49",
    "annual_income": "102470",
    "career_growth": 4.3
  },
  {
    "name": "Farmer/Rancher",
    "onet_occupation_id": "11-9013.00",
    "job_zone_id": 4,
    "hourly_income": "49",
    "annual_income": "101280",
    "career_growth": -4.6
  },
  {
    "name": "Model",
    "onet_occupation_id": "41-9012.00",
    "job_zone_id": 2,
    "hourly_income": "42",
    "annual_income": "86940",
    "career_growth": 0.5
  },
  {
    "name": "Cardiologist",
    "onet_occupation_id": "29-1212.00",
    "job_zone_id": 5,
    "hourly_income": "203",
    "annual_income": "421330",
    "career_growth": 3.1
  },
  {
    "name": "Car Electronics Installer",
    "onet_occupation_id": "49-2096.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "75400",
    "career_growth": -18
  },
  {
    "name": "Supervisor of Housekeepers/Janitors",
    "onet_occupation_id": "37-1011.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "69910",
    "career_growth": 3
  },
  {
    "name": "Financial Services Agent",
    "onet_occupation_id": "41-3031.00",
    "job_zone_id": 4,
    "hourly_income": "85",
    "annual_income": "176380",
    "career_growth": 7.4
  },
  {
    "name": "Metal Caster",
    "onet_occupation_id": "51-4052.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53290",
    "career_growth": -9.1
  },
  {
    "name": "Registered Nurse",
    "onet_occupation_id": "29-1141.00",
    "job_zone_id": 4,
    "hourly_income": "64",
    "annual_income": "133340",
    "career_growth": 5.6
  },
  {
    "name": "Chemical Plant Operator",
    "onet_occupation_id": "51-8091.00",
    "job_zone_id": 2,
    "hourly_income": "45",
    "annual_income": "93260",
    "career_growth": -3.2
  },
  {
    "name": "Adjudicator",
    "onet_occupation_id": "23-1021.00",
    "job_zone_id": 5,
    "hourly_income": "73",
    "annual_income": "152160",
    "career_growth": 1.1
  },
  {
    "name": "Lodging Manager",
    "onet_occupation_id": "11-9081.00",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "118380",
    "career_growth": 6.7
  },
  {
    "name": "Construction Laborer",
    "onet_occupation_id": "47-2061.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "66650",
    "career_growth": 4.4
  },
  {
    "name": "Maintenance and Repair Worker",
    "onet_occupation_id": "49-9071.00",
    "job_zone_id": 3,
    "hourly_income": "26",
    "annual_income": "54260",
    "career_growth": 3.6
  },
  {
    "name": "Physical Therapist Aide",
    "onet_occupation_id": "31-2022.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "45950",
    "career_growth": 3
  },
  {
    "name": "Semiconductor Processing Technician",
    "onet_occupation_id": "51-9141.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55790",
    "career_growth": 8
  },
  {
    "name": "Logistics Analyst",
    "onet_occupation_id": "13-1081.02",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "109860",
    "career_growth": 18.3
  },
  {
    "name": "Information Systems Manager",
    "onet_occupation_id": "11-3021.00",
    "job_zone_id": 4,
    "hourly_income": "105",
    "annual_income": "217630",
    "career_growth": 15.4
  },
  {
    "name": "Bridge Operator",
    "onet_occupation_id": "53-6011.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "69270",
    "career_growth": -0.8
  },
  {
    "name": "Healthcare Social Worker",
    "onet_occupation_id": "21-1022.00",
    "job_zone_id": 5,
    "hourly_income": "42",
    "annual_income": "88380",
    "career_growth": 9.6
  },
  {
    "name": "Baker",
    "onet_occupation_id": "51-3011.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47070",
    "career_growth": 4.9
  },
  {
    "name": "Quality Control Analyst",
    "onet_occupation_id": "19-4099.01",
    "job_zone_id": 3,
    "hourly_income": "43",
    "annual_income": "90440",
    "career_growth": 3.8
  },
  {
    "name": "Treasurer",
    "onet_occupation_id": "11-3031.01",
    "job_zone_id": 4,
    "hourly_income": "110",
    "annual_income": "228160",
    "career_growth": 16
  },
  {
    "name": "Tree Faller",
    "onet_occupation_id": "45-4021.00",
    "job_zone_id": 1,
    "hourly_income": "42",
    "annual_income": "88180",
    "career_growth": -9.4
  },
  {
    "name": "Eligibility Interviewer for Government Programs",
    "onet_occupation_id": "43-4061.00",
    "job_zone_id": 3,
    "hourly_income": "34",
    "annual_income": "70000",
    "career_growth": 2.2
  },
  {
    "name": "Labor Relations Specialist",
    "onet_occupation_id": "13-1075.00",
    "job_zone_id": 4,
    "hourly_income": "54",
    "annual_income": "111550",
    "career_growth": -0.8
  },
  {
    "name": "Crematory Operator",
    "onet_occupation_id": "39-4012.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "65860",
    "career_growth": 3.9
  },
  {
    "name": "Emergency Medicine Physician",
    "onet_occupation_id": "29-1214.00",
    "job_zone_id": 5,
    "hourly_income": "152",
    "annual_income": "316600",
    "career_growth": 2.8
  },
  {
    "name": "Concierge",
    "onet_occupation_id": "39-6012.00",
    "job_zone_id": 3,
    "hourly_income": "25",
    "annual_income": "51900",
    "career_growth": 5.6
  },
  {
    "name": "Receptionist",
    "onet_occupation_id": "43-4171.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "42250",
    "career_growth": 0.4
  },
  {
    "name": "Sports Coach/Scout",
    "onet_occupation_id": "27-2022.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "83200",
    "career_growth": 9.2
  },
  {
    "name": "Welder",
    "onet_occupation_id": "51-4121.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "71790",
    "career_growth": 1.4
  },
  {
    "name": "Railroad Engineer",
    "onet_occupation_id": "53-4013.00",
    "job_zone_id": 2,
    "hourly_income": "39",
    "annual_income": "81950",
    "career_growth": 2.4
  },
  {
    "name": "Cafeteria Cook",
    "onet_occupation_id": "35-2012.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "45080",
    "career_growth": 2.1
  },
  {
    "name": "Grinding Machine Operator",
    "onet_occupation_id": "51-4033.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51280",
    "career_growth": -9.5
  },
  {
    "name": "Ophthalmic Laboratory Technician",
    "onet_occupation_id": "51-9083.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60910",
    "career_growth": 1.1
  },
  {
    "name": "Plumber's Helper",
    "onet_occupation_id": "47-3015.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47570",
    "career_growth": 2.3
  },
  {
    "name": "Translator",
    "onet_occupation_id": "27-3091.00",
    "job_zone_id": 4,
    "hourly_income": "41",
    "annual_income": "85390",
    "career_growth": 4.3
  },
  {
    "name": "Non-Emergency Dispatcher",
    "onet_occupation_id": "43-5032.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "68630",
    "career_growth": -1.1
  },
  {
    "name": "Industrial Painter",
    "onet_occupation_id": "51-9124.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "57010",
    "career_growth": 0.6
  },
  {
    "name": "Elementary School Special Education Teacher",
    "onet_occupation_id": "25-2056.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Interviewer",
    "onet_occupation_id": "43-4111.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "60050",
    "career_growth": -10.1
  },
  {
    "name": "Kitchen Manager",
    "onet_occupation_id": "35-1012.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "57210",
    "career_growth": 4.9
  },
  {
    "name": "Detective",
    "onet_occupation_id": "33-3021.00",
    "job_zone_id": 3,
    "hourly_income": "64",
    "annual_income": "133890",
    "career_growth": 1.5
  },
  {
    "name": "Claims Adjuster",
    "onet_occupation_id": "13-1031.00",
    "job_zone_id": 4,
    "hourly_income": "43",
    "annual_income": "90120",
    "career_growth": -3.1
  },
  {
    "name": "Computer System Analyst",
    "onet_occupation_id": "15-1211.00",
    "job_zone_id": 3,
    "hourly_income": "62",
    "annual_income": "129330",
    "career_growth": 9.6
  },
  {
    "name": "Social Service Assistant",
    "onet_occupation_id": "21-1093.00",
    "job_zone_id": 4,
    "hourly_income": "29",
    "annual_income": "60930",
    "career_growth": 8.6
  },
  {
    "name": "Secretary",
    "onet_occupation_id": "43-6014.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "54790",
    "career_growth": -11.6
  },
  {
    "name": "Recreational Vehicle Service Technician",
    "onet_occupation_id": "49-3092.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "54350",
    "career_growth": 9.8
  },
  {
    "name": "Environmental Engineering Technician",
    "onet_occupation_id": "17-3025.00",
    "job_zone_id": 4,
    "hourly_income": "39",
    "annual_income": "80440",
    "career_growth": 1.3
  },
  {
    "name": "Private Detective",
    "onet_occupation_id": "33-9021.00",
    "job_zone_id": 3,
    "hourly_income": "34",
    "annual_income": "70640",
    "career_growth": 6.1
  },
  {
    "name": "Curator",
    "onet_occupation_id": "25-4012.00",
    "job_zone_id": 5,
    "hourly_income": "48",
    "annual_income": "98900",
    "career_growth": 11.1
  },
  {
    "name": "Drywall and Ceiling Tile Installer",
    "onet_occupation_id": "47-2081.00",
    "job_zone_id": 2,
    "hourly_income": "42",
    "annual_income": "86930",
    "career_growth": 1.6
  },
  {
    "name": "Preschool Teacher",
    "onet_occupation_id": "25-2011.00",
    "job_zone_id": 3,
    "hourly_income": "28",
    "annual_income": "59110",
    "career_growth": 3.4
  },
  {
    "name": "Concrete Finisher",
    "onet_occupation_id": "47-2051.00",
    "job_zone_id": 1,
    "hourly_income": "38",
    "annual_income": "78210",
    "career_growth": -4
  },
  {
    "name": "Mail Superintendent",
    "onet_occupation_id": "11-9131.00",
    "job_zone_id": 2,
    "hourly_income": "44",
    "annual_income": "92130",
    "career_growth": -6.7
  },
  {
    "name": "Stonemason",
    "onet_occupation_id": "47-2022.00",
    "job_zone_id": 3,
    "hourly_income": "40",
    "annual_income": "83570",
    "career_growth": -5.9
  },
  {
    "name": "Compliance Manager",
    "onet_occupation_id": "11-9199.02",
    "job_zone_id": 4,
    "hourly_income": "89",
    "annual_income": "184990",
    "career_growth": 3.3
  },
  {
    "name": "Career/Technical Education Professor",
    "onet_occupation_id": "25-1194.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "92930",
    "career_growth": -0.5
  },
  {
    "name": "Rehabilitation Counselor",
    "onet_occupation_id": "21-1015.00",
    "job_zone_id": 4,
    "hourly_income": "33",
    "annual_income": "68090",
    "career_growth": 1.9
  },
  {
    "name": "Fine Artist",
    "onet_occupation_id": "27-1013.00",
    "job_zone_id": 3,
    "hourly_income": "62",
    "annual_income": "129950",
    "career_growth": 5.2
  },
  {
    "name": "Septic Tank Servicer",
    "onet_occupation_id": "47-4071.00",
    "job_zone_id": 1,
    "hourly_income": "30",
    "annual_income": "62040",
    "career_growth": 7.4
  },
  {
    "name": "Earth/Marine Sciences Professor",
    "onet_occupation_id": "25-1051.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "161460",
    "career_growth": 3.6
  },
  {
    "name": "Arbitrator/Mediator",
    "onet_occupation_id": "23-1022.00",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "119280",
    "career_growth": 5
  },
  {
    "name": "Transit and Railroad Police",
    "onet_occupation_id": "33-3052.00",
    "job_zone_id": 3,
    "hourly_income": "43",
    "annual_income": "88480",
    "career_growth": 3.2
  },
  {
    "name": "Museum Conservator",
    "onet_occupation_id": "25-4013.00",
    "job_zone_id": 4,
    "hourly_income": "37",
    "annual_income": "77830",
    "career_growth": 9.9
  },
  {
    "name": "Library Science Professor",
    "onet_occupation_id": "25-1082.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "132860",
    "career_growth": 4.1
  },
  {
    "name": "Backhoe Operator",
    "onet_occupation_id": "47-5022.00",
    "job_zone_id": 2,
    "hourly_income": "43",
    "annual_income": "89050",
    "career_growth": -3.8
  },
  {
    "name": "Vision Rehabilitation Therapist",
    "onet_occupation_id": "29-1122.01",
    "job_zone_id": 5,
    "hourly_income": "53",
    "annual_income": "109220",
    "career_growth": 11.5
  },
  {
    "name": "Industrial Machinery Mechanic",
    "onet_occupation_id": "49-9041.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "78950",
    "career_growth": 14.9
  },
  {
    "name": "Gas Compressor Operator",
    "onet_occupation_id": "53-7071.00",
    "job_zone_id": 2,
    "hourly_income": "43",
    "annual_income": "89870",
    "career_growth": -2.1
  },
  {
    "name": "Ship Captain",
    "onet_occupation_id": "53-5021.00",
    "job_zone_id": 3,
    "hourly_income": "60",
    "annual_income": "124510",
    "career_growth": 1.7
  },
  {
    "name": "Telecommunications Line Installer",
    "onet_occupation_id": "49-9052.00",
    "job_zone_id": 2,
    "hourly_income": "45",
    "annual_income": "94160",
    "career_growth": 6.7
  },
  {
    "name": "Prosthodontist",
    "onet_occupation_id": "29-1024.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 5.7
  },
  {
    "name": "Commercial Pilot",
    "onet_occupation_id": "53-2012.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "198120",
    "career_growth": 4
  },
  {
    "name": "Search Marketing Strategist",
    "onet_occupation_id": "13-1161.01",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "103430",
    "career_growth": 13.4
  },
  {
    "name": "Commercial Diver",
    "onet_occupation_id": "49-9092.00",
    "job_zone_id": 3,
    "hourly_income": "75",
    "annual_income": "156020",
    "career_growth": 6
  },
  {
    "name": "Solar Energy Installation Manager",
    "onet_occupation_id": "47-1011.03",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98600",
    "career_growth": 2.5
  },
  {
    "name": "Library Technician",
    "onet_occupation_id": "25-4031.00",
    "job_zone_id": 3,
    "hourly_income": "28",
    "annual_income": "58120",
    "career_growth": -6.1
  },
  {
    "name": "Postal Service Mail Sorter",
    "onet_occupation_id": "43-5053.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "57600",
    "career_growth": -11.3
  },
  {
    "name": "Veterinary Technician",
    "onet_occupation_id": "29-2056.00",
    "job_zone_id": 3,
    "hourly_income": "25",
    "annual_income": "51680",
    "career_growth": 20.5
  },
  {
    "name": "Biologist",
    "onet_occupation_id": "19-1029.04",
    "job_zone_id": 5,
    "hourly_income": "55",
    "annual_income": "114940",
    "career_growth": 3.9
  },
  {
    "name": "Crossing Guard",
    "onet_occupation_id": "33-9091.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58340",
    "career_growth": 4.1
  },
  {
    "name": "Clockmaker",
    "onet_occupation_id": "51-2061.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46480",
    "career_growth": -18.7
  },
  {
    "name": "Sales Representative for Scientific Products",
    "onet_occupation_id": "41-4011.00",
    "job_zone_id": 4,
    "hourly_income": "77",
    "annual_income": "161060",
    "career_growth": 3.7
  },
  {
    "name": "Electronics Repairer for Transportation Equipment",
    "onet_occupation_id": "49-2093.00",
    "job_zone_id": 3,
    "hourly_income": "52",
    "annual_income": "107320",
    "career_growth": 4.8
  },
  {
    "name": "Network and Computer Systems Administrator",
    "onet_occupation_id": "15-1244.00",
    "job_zone_id": 4,
    "hourly_income": "56",
    "annual_income": "116470",
    "career_growth": 2.5
  },
  {
    "name": "Geothermal Technician",
    "onet_occupation_id": "49-9099.01",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72290",
    "career_growth": 1.5
  },
  {
    "name": "Press Machine Operator",
    "onet_occupation_id": "51-4031.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "50600",
    "career_growth": -10.9
  },
  {
    "name": "Terrazzo Finisher",
    "onet_occupation_id": "47-2053.00",
    "job_zone_id": 2,
    "hourly_income": "53",
    "annual_income": "110850",
    "career_growth": -12.2
  },
  {
    "name": "Bus Driver",
    "onet_occupation_id": "53-3052.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "73130",
    "career_growth": 5.4
  },
  {
    "name": "Sociology Professor",
    "onet_occupation_id": "25-1067.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "113910",
    "career_growth": 3.7
  },
  {
    "name": "Broadcast Technician",
    "onet_occupation_id": "27-4012.00",
    "job_zone_id": 3,
    "hourly_income": "41",
    "annual_income": "84860",
    "career_growth": -1.1
  },
  {
    "name": "Public Relations Manager",
    "onet_occupation_id": "11-2032.00",
    "job_zone_id": 4,
    "hourly_income": "99",
    "annual_income": "205330",
    "career_growth": 5.9
  },
  {
    "name": "Food Scientist",
    "onet_occupation_id": "19-1012.00",
    "job_zone_id": 4,
    "hourly_income": "48",
    "annual_income": "99480",
    "career_growth": 7.5
  },
  {
    "name": "Nurse Anesthetist",
    "onet_occupation_id": "29-1151.00",
    "job_zone_id": 5,
    "hourly_income": "119",
    "annual_income": "246510",
    "career_growth": 9
  },
  {
    "name": "Lighting Technician",
    "onet_occupation_id": "27-4015.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "93170",
    "career_growth": -3.4
  },
  {
    "name": "Judge",
    "onet_occupation_id": "23-1023.00",
    "job_zone_id": 5,
    "hourly_income": "104",
    "annual_income": "215530",
    "career_growth": 2.2
  },
  {
    "name": "Home Health Aide",
    "onet_occupation_id": "31-1121.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Electric Motor Mechanic",
    "onet_occupation_id": "49-2092.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "79680",
    "career_growth": 2.5
  },
  {
    "name": "Bioengineer",
    "onet_occupation_id": "17-2031.00",
    "job_zone_id": 4,
    "hourly_income": "64",
    "annual_income": "133790",
    "career_growth": 5.1
  },
  {
    "name": "Motorboat Mechanic",
    "onet_occupation_id": "49-3051.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "60270",
    "career_growth": 5.7
  },
  {
    "name": "Energy Auditor",
    "onet_occupation_id": "47-4011.01",
    "job_zone_id": 3,
    "hourly_income": "46",
    "annual_income": "94880",
    "career_growth": -1.5
  },
  {
    "name": "Wholesale Buyer",
    "onet_occupation_id": "13-1022.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Meat Trimmer",
    "onet_occupation_id": "51-3022.00",
    "job_zone_id": 1,
    "hourly_income": "21",
    "annual_income": "43200",
    "career_growth": 2
  },
  {
    "name": "Petroleum Pump System Operator",
    "onet_occupation_id": "51-8093.00",
    "job_zone_id": 2,
    "hourly_income": "51",
    "annual_income": "105520",
    "career_growth": -2.3
  },
  {
    "name": "Biostatistician",
    "onet_occupation_id": "15-2041.01",
    "job_zone_id": 5,
    "hourly_income": "66",
    "annual_income": "137840",
    "career_growth": 31.6
  },
  {
    "name": "Underground Roof Bolter",
    "onet_occupation_id": "47-5043.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72910",
    "career_growth": -28.5
  },
  {
    "name": "Environmental Restoration Planner",
    "onet_occupation_id": "19-2041.02",
    "job_zone_id": 5,
    "hourly_income": "57",
    "annual_income": "118430",
    "career_growth": 6.1
  },
  {
    "name": "Pharmacist",
    "onet_occupation_id": "29-1051.00",
    "job_zone_id": 5,
    "hourly_income": "73",
    "annual_income": "151280",
    "career_growth": 2.6
  },
  {
    "name": "Choreographer",
    "onet_occupation_id": "27-2032.00",
    "job_zone_id": 4,
    "hourly_income": "55",
    "annual_income": "113380",
    "career_growth": 5
  },
  {
    "name": "Forest and Conservation Technician",
    "onet_occupation_id": "19-4071.00",
    "job_zone_id": 3,
    "hourly_income": "27",
    "annual_income": "56420",
    "career_growth": 0.6
  },
  {
    "name": "Restaurant Cook",
    "onet_occupation_id": "35-2014.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "42710",
    "career_growth": 20.4
  },
  {
    "name": "Fundraising Manager",
    "onet_occupation_id": "11-2033.00",
    "job_zone_id": 4,
    "hourly_income": "83",
    "annual_income": "171720",
    "career_growth": 5.1
  },
  {
    "name": "Rehabilitation Physician",
    "onet_occupation_id": "29-1229.04",
    "job_zone_id": 5,
    "hourly_income": "132",
    "annual_income": "274650",
    "career_growth": 3
  },
  {
    "name": "Manager of Sales",
    "onet_occupation_id": "41-1012.00",
    "job_zone_id": 4,
    "hourly_income": "59",
    "annual_income": "122600",
    "career_growth": -2.2
  },
  {
    "name": "Biofuels Processing Technician",
    "onet_occupation_id": "51-8099.01",
    "job_zone_id": 2,
    "hourly_income": "47",
    "annual_income": "98200",
    "career_growth": -0.2
  },
  {
    "name": "Shampooer",
    "onet_occupation_id": "39-5093.00",
    "job_zone_id": 2,
    "hourly_income": "16",
    "annual_income": "33760",
    "career_growth": 11.2
  },
  {
    "name": "Cartographer",
    "onet_occupation_id": "17-1021.00",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "96460",
    "career_growth": 5
  },
  {
    "name": "File Clerk",
    "onet_occupation_id": "43-4071.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59980",
    "career_growth": -16
  },
  {
    "name": "Electrician's Helper",
    "onet_occupation_id": "47-3013.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "56640",
    "career_growth": -3.5
  },
  {
    "name": "Urologist",
    "onet_occupation_id": "29-1229.03",
    "job_zone_id": 5,
    "hourly_income": "132",
    "annual_income": "274650",
    "career_growth": 3
  },
  {
    "name": "Librarian",
    "onet_occupation_id": "25-4022.00",
    "job_zone_id": 5,
    "hourly_income": "43",
    "annual_income": "89610",
    "career_growth": 3
  },
  {
    "name": "Housekeeping Cleaner",
    "onet_occupation_id": "37-2012.00",
    "job_zone_id": 1,
    "hourly_income": "23",
    "annual_income": "47350",
    "career_growth": 0.1
  },
  {
    "name": "Nuclear Technician",
    "onet_occupation_id": "19-4051.00",
    "job_zone_id": 3,
    "hourly_income": "52",
    "annual_income": "108520",
    "career_growth": -1.3
  },
  {
    "name": "Author",
    "onet_occupation_id": "27-3043.00",
    "job_zone_id": 4,
    "hourly_income": "62",
    "annual_income": "129340",
    "career_growth": 3.7
  },
  {
    "name": "Food Preparation Worker",
    "onet_occupation_id": "35-2021.00",
    "job_zone_id": 1,
    "hourly_income": "19",
    "annual_income": "38670",
    "career_growth": -4.8
  },
  {
    "name": "Radiologist",
    "onet_occupation_id": "29-1224.00",
    "job_zone_id": 5,
    "hourly_income": "158",
    "annual_income": "329080",
    "career_growth": 3.6
  },
  {
    "name": "Web Administrator",
    "onet_occupation_id": "15-1299.01",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Agricultural Technician",
    "onet_occupation_id": "19-4012.00",
    "job_zone_id": 3,
    "hourly_income": "26",
    "annual_income": "55010",
    "career_growth": 4.5
  },
  {
    "name": "Supervisor of Firefighters",
    "onet_occupation_id": "33-1021.00",
    "job_zone_id": 3,
    "hourly_income": "59",
    "annual_income": "121680",
    "career_growth": 3.6
  },
  {
    "name": "Speech-Language Pathology Assistant",
    "onet_occupation_id": "31-9099.01",
    "job_zone_id": 3,
    "hourly_income": "28",
    "annual_income": "58360",
    "career_growth": 5.7
  },
  {
    "name": "Mechanical Engineering Technician",
    "onet_occupation_id": "17-3027.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "91920",
    "career_growth": 0.6
  },
  {
    "name": "Advertising Sales Agent",
    "onet_occupation_id": "41-3011.00",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "109750",
    "career_growth": -7.2
  },
  {
    "name": "Media Programming Director",
    "onet_occupation_id": "27-2012.03",
    "job_zone_id": 4,
    "hourly_income": "72",
    "annual_income": "150240",
    "career_growth": 6.7
  },
  {
    "name": "Training and Development Specialist",
    "onet_occupation_id": "13-1151.00",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "95680",
    "career_growth": 6.3
  },
  {
    "name": "Architect",
    "onet_occupation_id": "17-1011.00",
    "job_zone_id": 5,
    "hourly_income": "56",
    "annual_income": "116990",
    "career_growth": 4.8
  },
  {
    "name": "Short Order Cook",
    "onet_occupation_id": "35-2015.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "40660",
    "career_growth": -6.1
  },
  {
    "name": "Jeweler",
    "onet_occupation_id": "51-9071.00",
    "job_zone_id": 3,
    "hourly_income": "31",
    "annual_income": "63880",
    "career_growth": -2.9
  },
  {
    "name": "Continuous Mining Machine Operator",
    "onet_occupation_id": "47-5041.00",
    "job_zone_id": 2,
    "hourly_income": "43",
    "annual_income": "88680",
    "career_growth": -3.2
  },
  {
    "name": "Childcare Director",
    "onet_occupation_id": "11-9031.00",
    "job_zone_id": 4,
    "hourly_income": "44",
    "annual_income": "91130",
    "career_growth": -3
  },
  {
    "name": "Occupational Health and Safety Technician",
    "onet_occupation_id": "19-5012.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "93530",
    "career_growth": 10.1
  },
  {
    "name": "Skincare Specialist",
    "onet_occupation_id": "39-5094.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "78360",
    "career_growth": 9.1
  },
  {
    "name": "Industrial-Organizational Psychologist",
    "onet_occupation_id": "19-3032.00",
    "job_zone_id": 5,
    "hourly_income": "70",
    "annual_income": "144610",
    "career_growth": 5.9
  },
  {
    "name": "Financial Analyst",
    "onet_occupation_id": "13-2051.00",
    "job_zone_id": 4,
    "hourly_income": "68",
    "annual_income": "140880",
    "career_growth": 7.6
  },
  {
    "name": "Air Traffic Controller",
    "onet_occupation_id": "53-2021.00",
    "job_zone_id": 3,
    "hourly_income": "76",
    "annual_income": "158890",
    "career_growth": 1.4
  },
  {
    "name": "Floor Layer",
    "onet_occupation_id": "47-2042.00",
    "job_zone_id": 2,
    "hourly_income": "50",
    "annual_income": "104270",
    "career_growth": 7.3
  },
  {
    "name": "Physicist",
    "onet_occupation_id": "19-2012.00",
    "job_zone_id": 5,
    "hourly_income": "94",
    "annual_income": "195160",
    "career_growth": 4.7
  },
  {
    "name": "Fashion Designer",
    "onet_occupation_id": "27-1022.00",
    "job_zone_id": 3,
    "hourly_income": "50",
    "annual_income": "103960",
    "career_growth": 3.3
  },
  {
    "name": "Urban Planner",
    "onet_occupation_id": "19-3051.00",
    "job_zone_id": 5,
    "hourly_income": "54",
    "annual_income": "113060",
    "career_growth": 3.7
  },
  {
    "name": "Florist",
    "onet_occupation_id": "27-1023.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "45440",
    "career_growth": -18
  },
  {
    "name": "Carpenter's Helper",
    "onet_occupation_id": "47-3012.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "48910",
    "career_growth": -5.3
  },
  {
    "name": "Glass Blower",
    "onet_occupation_id": "51-9195.04",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55510",
    "career_growth": 2.4
  },
  {
    "name": "Materials Engineer",
    "onet_occupation_id": "17-2131.00",
    "job_zone_id": 4,
    "hourly_income": "63",
    "annual_income": "131490",
    "career_growth": 5.1
  },
  {
    "name": "Database Architect",
    "onet_occupation_id": "15-1243.00",
    "job_zone_id": 4,
    "hourly_income": "77",
    "annual_income": "159650",
    "career_growth": 10
  },
  {
    "name": "Quality Assurance Inspector",
    "onet_occupation_id": "51-9061.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "69860",
    "career_growth": -3.9
  },
  {
    "name": "Market Research Analyst",
    "onet_occupation_id": "13-1161.00",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "103430",
    "career_growth": 13.4
  },
  {
    "name": "Customs Broker",
    "onet_occupation_id": "13-1041.08",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "General Internal Medicine Physician",
    "onet_occupation_id": "29-1216.00",
    "job_zone_id": 5,
    "hourly_income": "137",
    "annual_income": "285200",
    "career_growth": 2.5
  },
  {
    "name": "Brickmason",
    "onet_occupation_id": "47-2021.00",
    "job_zone_id": 2,
    "hourly_income": "40",
    "annual_income": "83420",
    "career_growth": 0.5
  },
  {
    "name": "Electrical Power-Line Installer",
    "onet_occupation_id": "49-9051.00",
    "job_zone_id": 2,
    "hourly_income": "53",
    "annual_income": "109670",
    "career_growth": 2.8
  },
  {
    "name": "Emergency Dispatcher",
    "onet_occupation_id": "43-5031.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "73430",
    "career_growth": 3.4
  },
  {
    "name": "Self-Enrichment Teacher",
    "onet_occupation_id": "25-3021.00",
    "job_zone_id": 3,
    "hourly_income": "31",
    "annual_income": "65160",
    "career_growth": 3.2
  },
  {
    "name": "Human Resources Assistant",
    "onet_occupation_id": "43-4161.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "60010",
    "career_growth": -4.9
  },
  {
    "name": "Animal Scientist",
    "onet_occupation_id": "19-1011.00",
    "job_zone_id": 5,
    "hourly_income": "73",
    "annual_income": "152140",
    "career_growth": 5.7
  },
  {
    "name": "Theatrical Makeup Artist",
    "onet_occupation_id": "39-5091.00",
    "job_zone_id": 3,
    "hourly_income": "65",
    "annual_income": "134740",
    "career_growth": 8.6
  },
  {
    "name": "Hoist and Winch Operator",
    "onet_occupation_id": "53-7041.00",
    "job_zone_id": 2,
    "hourly_income": "57",
    "annual_income": "118740",
    "career_growth": -1.8
  },
  {
    "name": "Dental Assistant",
    "onet_occupation_id": "31-9091.00",
    "job_zone_id": 3,
    "hourly_income": "28",
    "annual_income": "58890",
    "career_growth": 6.9
  },
  {
    "name": "Supervisor of Personal Service Workers",
    "onet_occupation_id": "39-1022.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67430",
    "career_growth": 8.3
  },
  {
    "name": "Adhesive Bonding Machine Operator",
    "onet_occupation_id": "51-9191.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59780",
    "career_growth": -2.2
  },
  {
    "name": "Order Clerk",
    "onet_occupation_id": "43-4151.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51960",
    "career_growth": -18.2
  },
  {
    "name": "Nanotechnology Engineering Technician",
    "onet_occupation_id": "17-3026.01",
    "job_zone_id": 4,
    "hourly_income": "56",
    "annual_income": "115760",
    "career_growth": 3.2
  },
  {
    "name": "Welding Machine Operator",
    "onet_occupation_id": "51-4122.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60730",
    "career_growth": -8.4
  },
  {
    "name": "Security and Fire Alarm Systems Installer",
    "onet_occupation_id": "49-2098.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67340",
    "career_growth": 2.8
  },
  {
    "name": "Human Resources Specialist",
    "onet_occupation_id": "13-1071.00",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "103650",
    "career_growth": 5.9
  },
  {
    "name": "Cost Estimator",
    "onet_occupation_id": "13-1051.00",
    "job_zone_id": 4,
    "hourly_income": "48",
    "annual_income": "100100",
    "career_growth": -2.8
  },
  {
    "name": "Anthropologist/Archeologist",
    "onet_occupation_id": "19-3091.00",
    "job_zone_id": 5,
    "hourly_income": "58",
    "annual_income": "120560",
    "career_growth": 4.2
  },
  {
    "name": "Animal Control Worker",
    "onet_occupation_id": "33-9011.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "64110",
    "career_growth": 4
  },
  {
    "name": "Tailor",
    "onet_occupation_id": "51-6052.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60510",
    "career_growth": -13.6
  },
  {
    "name": "Management Analyst",
    "onet_occupation_id": "13-1111.00",
    "job_zone_id": 4,
    "hourly_income": "61",
    "annual_income": "126000",
    "career_growth": 9.7
  },
  {
    "name": "Political Scientist",
    "onet_occupation_id": "19-3094.00",
    "job_zone_id": 5,
    "hourly_income": "65",
    "annual_income": "136200",
    "career_growth": 6.6
  },
  {
    "name": "Executive Secretary",
    "onet_occupation_id": "43-6011.00",
    "job_zone_id": 3,
    "hourly_income": "41",
    "annual_income": "84990",
    "career_growth": -21.1
  },
  {
    "name": "Supervisor of Passenger Attendants",
    "onet_occupation_id": "53-1044.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Cytotechnologist",
    "onet_occupation_id": "29-2011.02",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Manager of Retail Sales Workers",
    "onet_occupation_id": "41-1011.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "59020",
    "career_growth": -6.7
  },
  {
    "name": "Health and Safety Engineer",
    "onet_occupation_id": "17-2111.00",
    "job_zone_id": 4,
    "hourly_income": "62",
    "annual_income": "128220",
    "career_growth": 3.7
  },
  {
    "name": "Sports Medicine Physician",
    "onet_occupation_id": "29-1229.06",
    "job_zone_id": 5,
    "hourly_income": "132",
    "annual_income": "274650",
    "career_growth": 3
  },
  {
    "name": "Parking Attendant",
    "onet_occupation_id": "53-6021.00",
    "job_zone_id": 2,
    "hourly_income": "19",
    "annual_income": "38530",
    "career_growth": 1.5
  },
  {
    "name": "Nanny",
    "onet_occupation_id": "39-9011.01",
    "job_zone_id": 2,
    "hourly_income": "19",
    "annual_income": "40370",
    "career_growth": -2
  },
  {
    "name": "Railroad Conductor",
    "onet_occupation_id": "53-4031.00",
    "job_zone_id": 2,
    "hourly_income": "43",
    "annual_income": "88860",
    "career_growth": 1.1
  },
  {
    "name": "Camera Operator",
    "onet_occupation_id": "27-4031.00",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98120",
    "career_growth": 2.8
  },
  {
    "name": "Set Designer",
    "onet_occupation_id": "27-1027.00",
    "job_zone_id": 5,
    "hourly_income": "41",
    "annual_income": "84280",
    "career_growth": 7.3
  },
  {
    "name": "Foundry Mold Maker",
    "onet_occupation_id": "51-4071.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53620",
    "career_growth": -23.5
  },
  {
    "name": "Potter",
    "onet_occupation_id": "51-9195.05",
    "job_zone_id": 3,
    "hourly_income": "27",
    "annual_income": "55510",
    "career_growth": 2.4
  },
  {
    "name": "Mechanical Drafter",
    "onet_occupation_id": "17-3013.00",
    "job_zone_id": 3,
    "hourly_income": "37",
    "annual_income": "76260",
    "career_growth": -7.2
  },
  {
    "name": "Farm Purchasing Agent",
    "onet_occupation_id": "13-1021.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Acupuncturist",
    "onet_occupation_id": "29-1291.00",
    "job_zone_id": 5,
    "hourly_income": "60",
    "annual_income": "124670",
    "career_growth": 5.3
  },
  {
    "name": "Infantry Soldier",
    "onet_occupation_id": "55-3016.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Web and Digital Interface Designer",
    "onet_occupation_id": "15-1255.00",
    "job_zone_id": 4,
    "hourly_income": "70",
    "annual_income": "145560",
    "career_growth": 15.2
  },
  {
    "name": "Farm Labor Contractor",
    "onet_occupation_id": "13-1074.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "62280",
    "career_growth": 7.4
  },
  {
    "name": "College Teaching Assistant",
    "onet_occupation_id": "25-9044.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "59470",
    "career_growth": 4.2
  },
  {
    "name": "Media Technical Director",
    "onet_occupation_id": "27-2012.05",
    "job_zone_id": 4,
    "hourly_income": "72",
    "annual_income": "150240",
    "career_growth": 6.7
  },
  {
    "name": "Infantry Officer",
    "onet_occupation_id": "55-1016.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Court, Municipal, and License Clerk",
    "onet_occupation_id": "43-4031.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60080",
    "career_growth": 3
  },
  {
    "name": "Non-Destructive Testing Specialist",
    "onet_occupation_id": "17-3029.01",
    "job_zone_id": 2,
    "hourly_income": "50",
    "annual_income": "103110",
    "career_growth": 3.1
  },
  {
    "name": "Non-Restaurant Food Server",
    "onet_occupation_id": "35-3041.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43920",
    "career_growth": 3.1
  },
  {
    "name": "Robotics Engineer",
    "onet_occupation_id": "17-2199.08",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Title Examiner",
    "onet_occupation_id": "23-2093.00",
    "job_zone_id": 2,
    "hourly_income": "39",
    "annual_income": "82090",
    "career_growth": 1.7
  },
  {
    "name": "Computer and Information Research Scientist",
    "onet_occupation_id": "15-1221.00",
    "job_zone_id": 5,
    "hourly_income": "112",
    "annual_income": "232210",
    "career_growth": 22.7
  },
  {
    "name": "Desktop Publisher",
    "onet_occupation_id": "43-9031.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "74730",
    "career_growth": -13.5
  },
  {
    "name": "Ironworker",
    "onet_occupation_id": "47-2171.00",
    "job_zone_id": 2,
    "hourly_income": "46",
    "annual_income": "95510",
    "career_growth": 1.2
  },
  {
    "name": "Molding Machine Operator",
    "onet_occupation_id": "51-4072.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49410",
    "career_growth": -3.7
  },
  {
    "name": "Credit Counselor",
    "onet_occupation_id": "13-2071.00",
    "job_zone_id": 4,
    "hourly_income": "40",
    "annual_income": "82360",
    "career_growth": 5.4
  },
  {
    "name": "Speech-Language Pathologist",
    "onet_occupation_id": "29-1127.00",
    "job_zone_id": 5,
    "hourly_income": "53",
    "annual_income": "110470",
    "career_growth": 19.3
  },
  {
    "name": "Prepress Technician",
    "onet_occupation_id": "51-5111.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "92230",
    "career_growth": -17.1
  },
  {
    "name": "Supervisor of Gambling Services Workers",
    "onet_occupation_id": "39-1013.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "71180",
    "career_growth": 3.2
  },
  {
    "name": "Audio and Video Technician",
    "onet_occupation_id": "27-4011.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "75400",
    "career_growth": 4.7
  },
  {
    "name": "Producer/Director",
    "onet_occupation_id": "27-2012.00",
    "job_zone_id": 4,
    "hourly_income": "72",
    "annual_income": "150240",
    "career_growth": 6.7
  },
  {
    "name": "Supply Chain Manager",
    "onet_occupation_id": "11-3071.04",
    "job_zone_id": 4,
    "hourly_income": "68",
    "annual_income": "141490",
    "career_growth": 8.2
  },
  {
    "name": "Family Medicine Physician",
    "onet_occupation_id": "29-1215.00",
    "job_zone_id": 5,
    "hourly_income": "128",
    "annual_income": "266800",
    "career_growth": 3.7
  },
  {
    "name": "Veterinarian",
    "onet_occupation_id": "29-1131.00",
    "job_zone_id": 5,
    "hourly_income": "75",
    "annual_income": "155570",
    "career_growth": 19.7
  },
  {
    "name": "Supervisor of Security Workers",
    "onet_occupation_id": "33-1091.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "70810",
    "career_growth": -3.9
  },
  {
    "name": "Conservation Scientist",
    "onet_occupation_id": "19-1031.00",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "96100",
    "career_growth": 4.1
  },
  {
    "name": "Orthopedic Surgeon",
    "onet_occupation_id": "29-1242.00",
    "job_zone_id": 5,
    "hourly_income": "179",
    "annual_income": "371400",
    "career_growth": 1.6
  },
  {
    "name": "Armored Assault Vehicle Crew Member",
    "onet_occupation_id": "55-3013.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Forensic Science Technician",
    "onet_occupation_id": "19-4092.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "93900",
    "career_growth": 12.6
  },
  {
    "name": "Optometrist",
    "onet_occupation_id": "29-1041.00",
    "job_zone_id": 5,
    "hourly_income": "87",
    "annual_income": "180470",
    "career_growth": 8.8
  },
  {
    "name": "Command and Control Center Specialist",
    "onet_occupation_id": "55-3015.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Farm Equipment Operator",
    "onet_occupation_id": "45-2091.00",
    "job_zone_id": 1,
    "hourly_income": "23",
    "annual_income": "47860",
    "career_growth": 8.6
  },
  {
    "name": "Tax Preparer",
    "onet_occupation_id": "13-2082.00",
    "job_zone_id": 3,
    "hourly_income": "38",
    "annual_income": "78190",
    "career_growth": 1.5
  },
  {
    "name": "Engraver",
    "onet_occupation_id": "51-9194.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58440",
    "career_growth": -0.2
  },
  {
    "name": "Manicurist/Pedicurist",
    "onet_occupation_id": "39-5092.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "60610",
    "career_growth": 8.7
  },
  {
    "name": "Physician Assistant",
    "onet_occupation_id": "29-1071.00",
    "job_zone_id": 5,
    "hourly_income": "70",
    "annual_income": "145390",
    "career_growth": 26.5
  },
  {
    "name": "Environmental Engineer",
    "onet_occupation_id": "17-2081.00",
    "job_zone_id": 4,
    "hourly_income": "61",
    "annual_income": "127390",
    "career_growth": 6.1
  },
  {
    "name": "Woodworking Machine Operator",
    "onet_occupation_id": "51-7042.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47150",
    "career_growth": -1
  },
  {
    "name": "Truck Loader",
    "onet_occupation_id": "53-7121.00",
    "job_zone_id": 2,
    "hourly_income": "42",
    "annual_income": "86330",
    "career_growth": 1.6
  },
  {
    "name": "Talent Agent",
    "onet_occupation_id": "13-1011.00",
    "job_zone_id": 4,
    "hourly_income": "68",
    "annual_income": "140810",
    "career_growth": 8.2
  },
  {
    "name": "Telephone Operator",
    "onet_occupation_id": "43-2021.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53370",
    "career_growth": -26.6
  },
  {
    "name": "Mental Health Social Worker",
    "onet_occupation_id": "21-1023.00",
    "job_zone_id": 5,
    "hourly_income": "45",
    "annual_income": "92850",
    "career_growth": 10.6
  },
  {
    "name": "Vending Machine Mechanic",
    "onet_occupation_id": "49-9091.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59830",
    "career_growth": -1.7
  },
  {
    "name": "Farm Equipment Mechanic",
    "onet_occupation_id": "49-3041.00",
    "job_zone_id": 3,
    "hourly_income": "28",
    "annual_income": "59130",
    "career_growth": 8.1
  },
  {
    "name": "Car Mechanic",
    "onet_occupation_id": "49-3023.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "61090",
    "career_growth": 1.6
  },
  {
    "name": "Medical Appliance Technician",
    "onet_occupation_id": "51-9082.00",
    "job_zone_id": 3,
    "hourly_income": "35",
    "annual_income": "73200",
    "career_growth": 2.5
  },
  {
    "name": "Civil Engineering Technician",
    "onet_occupation_id": "17-3022.00",
    "job_zone_id": 3,
    "hourly_income": "46",
    "annual_income": "96700",
    "career_growth": 0.9
  },
  {
    "name": "Environmental Economist",
    "onet_occupation_id": "19-3011.01",
    "job_zone_id": 5,
    "hourly_income": "73",
    "annual_income": "151850",
    "career_growth": 6.3
  },
  {
    "name": "Kindergarten Teacher",
    "onet_occupation_id": "25-2012.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "94310",
    "career_growth": 0.5
  },
  {
    "name": "Video Game Designer",
    "onet_occupation_id": "15-1255.01",
    "job_zone_id": 4,
    "hourly_income": "70",
    "annual_income": "145560",
    "career_growth": 15.2
  },
  {
    "name": "Dyeing Machine Operator",
    "onet_occupation_id": "51-6061.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "44070",
    "career_growth": -9.5
  },
  {
    "name": "Property Manager",
    "onet_occupation_id": "11-9141.00",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "110520",
    "career_growth": 5.4
  },
  {
    "name": "Private Household Cook",
    "onet_occupation_id": "35-2013.00",
    "job_zone_id": 3,
    "hourly_income": "25",
    "annual_income": "52680",
    "career_growth": -5.5
  },
  {
    "name": "Air Crew Member",
    "onet_occupation_id": "55-3011.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Animal Caretaker",
    "onet_occupation_id": "39-2021.00",
    "job_zone_id": 2,
    "hourly_income": "19",
    "annual_income": "39450",
    "career_growth": 15.5
  },
  {
    "name": "Interior Designer",
    "onet_occupation_id": "27-1025.00",
    "job_zone_id": 4,
    "hourly_income": "43",
    "annual_income": "88870",
    "career_growth": 3.9
  },
  {
    "name": "Genetic Counselor",
    "onet_occupation_id": "29-9092.00",
    "job_zone_id": 5,
    "hourly_income": "63",
    "annual_income": "131800",
    "career_growth": 16.1
  },
  {
    "name": "Legal Secretary",
    "onet_occupation_id": "43-6012.00",
    "job_zone_id": 3,
    "hourly_income": "40",
    "annual_income": "83500",
    "career_growth": -21.8
  },
  {
    "name": "Park Naturalist",
    "onet_occupation_id": "19-1031.03",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "96100",
    "career_growth": 4.1
  },
  {
    "name": "Fabric Cutter",
    "onet_occupation_id": "51-9031.00",
    "job_zone_id": 1,
    "hourly_income": "22",
    "annual_income": "46210",
    "career_growth": -28.2
  },
  {
    "name": "Diagnostic Medical Sonographer",
    "onet_occupation_id": "29-2032.00",
    "job_zone_id": 3,
    "hourly_income": "55",
    "annual_income": "114480",
    "career_growth": 14.3
  },
  {
    "name": "Histology Technician",
    "onet_occupation_id": "29-2012.01",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Forming Machine Operator",
    "onet_occupation_id": "51-9041.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "54940",
    "career_growth": -1.3
  },
  {
    "name": "Document Management Specialist",
    "onet_occupation_id": "15-1299.03",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "142520",
    "career_growth": 9.7
  },
  {
    "name": "Software Quality Assurance Tester",
    "onet_occupation_id": "15-1253.00",
    "job_zone_id": 4,
    "hourly_income": "66",
    "annual_income": "137690",
    "career_growth": 20.3
  },
  {
    "name": "Sports Writer",
    "onet_occupation_id": "39-3012.00",
    "job_zone_id": 2,
    "hourly_income": "49",
    "annual_income": "102160",
    "career_growth": -4
  },
  {
    "name": "Graphic Designer",
    "onet_occupation_id": "27-1024.00",
    "job_zone_id": 4,
    "hourly_income": "42",
    "annual_income": "87980",
    "career_growth": 3.3
  },
  {
    "name": "Agricultural Science Professor",
    "onet_occupation_id": "25-1041.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "140930",
    "career_growth": 4.8
  },
  {
    "name": "Hotel Desk Clerk",
    "onet_occupation_id": "43-4081.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52430",
    "career_growth": -2.6
  },
  {
    "name": "Materials Scientist",
    "onet_occupation_id": "19-2032.00",
    "job_zone_id": 5,
    "hourly_income": "83",
    "annual_income": "172880",
    "career_growth": 5.1
  },
  {
    "name": "Anesthesiologist Assistant",
    "onet_occupation_id": "29-1071.01",
    "job_zone_id": 5,
    "hourly_income": "70",
    "annual_income": "145390",
    "career_growth": 26.5
  },
  {
    "name": "Postal Service Mail Carrier",
    "onet_occupation_id": "43-5052.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58160",
    "career_growth": -6.7
  },
  {
    "name": "Textile Cutting Machine Operator",
    "onet_occupation_id": "51-6062.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43410",
    "career_growth": -10.8
  },
  {
    "name": "Repair Worker's Helper",
    "onet_occupation_id": "49-9098.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "64990",
    "career_growth": 2.5
  },
  {
    "name": "Wind Turbine Services Technician",
    "onet_occupation_id": "49-9081.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "87050",
    "career_growth": 44.9
  },
  {
    "name": "Respiratory Therapist",
    "onet_occupation_id": "29-1126.00",
    "job_zone_id": 3,
    "hourly_income": "46",
    "annual_income": "96150",
    "career_growth": 12.5
  },
  {
    "name": "Cell Biologist",
    "onet_occupation_id": "19-1029.02",
    "job_zone_id": 5,
    "hourly_income": "55",
    "annual_income": "114940",
    "career_growth": 3.9
  },
  {
    "name": "Paralegal",
    "onet_occupation_id": "23-2011.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "91880",
    "career_growth": 4.2
  },
  {
    "name": "Derrick Operator",
    "onet_occupation_id": "47-5011.00",
    "job_zone_id": 1,
    "hourly_income": "36",
    "annual_income": "75460",
    "career_growth": 0.5
  },
  {
    "name": "School Bus Driver",
    "onet_occupation_id": "53-3051.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59340",
    "career_growth": 1.4
  },
  {
    "name": "Atmospheric Scientist",
    "onet_occupation_id": "19-2021.00",
    "job_zone_id": 4,
    "hourly_income": "82",
    "annual_income": "170840",
    "career_growth": 4.4
  },
  {
    "name": "Extrusion Machine Operator",
    "onet_occupation_id": "51-4021.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55260",
    "career_growth": -1.9
  },
  {
    "name": "Tutor",
    "onet_occupation_id": "25-3041.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67120",
    "career_growth": 3.3
  },
  {
    "name": "Mathematician",
    "onet_occupation_id": "15-2021.00",
    "job_zone_id": 5,
    "hourly_income": "65",
    "annual_income": "135220",
    "career_growth": 2.2
  },
  {
    "name": "Medical Records Specialist",
    "onet_occupation_id": "29-2072.00",
    "job_zone_id": 3,
    "hourly_income": "33",
    "annual_income": "68500",
    "career_growth": 8.5
  },
  {
    "name": "Licensed Practical Nurse",
    "onet_occupation_id": "29-2061.00",
    "job_zone_id": 3,
    "hourly_income": "34",
    "annual_income": "69950",
    "career_growth": 5.3
  },
  {
    "name": "Architecture Professor",
    "onet_occupation_id": "25-1031.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "144560",
    "career_growth": 3.8
  },
  {
    "name": "Real Estate Appraiser",
    "onet_occupation_id": "13-2023.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Forging Machine Operator",
    "onet_occupation_id": "51-4022.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58540",
    "career_growth": -14.6
  },
  {
    "name": "Fisherman/Hunter",
    "onet_occupation_id": "45-3031.00",
    "job_zone_id": 1,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": -0.4
  },
  {
    "name": "Math Professor",
    "onet_occupation_id": "25-1022.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "127090",
    "career_growth": 3.2
  },
  {
    "name": "Physical Therapist",
    "onet_occupation_id": "29-1123.00",
    "job_zone_id": 5,
    "hourly_income": "55",
    "annual_income": "114230",
    "career_growth": 15.1
  },
  {
    "name": "Computer Science Professor",
    "onet_occupation_id": "25-1021.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "173240",
    "career_growth": 5.3
  },
  {
    "name": "Rail Maintenance Worker",
    "onet_occupation_id": "47-4061.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72040",
    "career_growth": 1.4
  },
  {
    "name": "Allergist/Immunologist",
    "onet_occupation_id": "29-1229.01",
    "job_zone_id": 5,
    "hourly_income": "132",
    "annual_income": "274650",
    "career_growth": 3
  },
  {
    "name": "Retail Loss Prevention Specialist",
    "onet_occupation_id": "33-9099.02",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "66050",
    "career_growth": 3
  },
  {
    "name": "Recreation Manager",
    "onet_occupation_id": "11-9072.00",
    "job_zone_id": 4,
    "hourly_income": "56",
    "annual_income": "116320",
    "career_growth": 8.3
  },
  {
    "name": "School Principal",
    "onet_occupation_id": "11-9032.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "142810",
    "career_growth": 1.1
  },
  {
    "name": "Education Teacher",
    "onet_occupation_id": "25-1081.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "103120",
    "career_growth": 3.7
  },
  {
    "name": "Food Cooking Machine Operator",
    "onet_occupation_id": "51-3093.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46340",
    "career_growth": 1.6
  },
  {
    "name": "Computer Repair Technician",
    "onet_occupation_id": "49-2011.00",
    "job_zone_id": 3,
    "hourly_income": "27",
    "annual_income": "56140",
    "career_growth": -10.8
  },
  {
    "name": "Barber",
    "onet_occupation_id": "39-5011.00",
    "job_zone_id": 3,
    "hourly_income": "40",
    "annual_income": "84100",
    "career_growth": 7
  },
  {
    "name": "Obstetrician/Gynecologist",
    "onet_occupation_id": "29-1218.00",
    "job_zone_id": 5,
    "hourly_income": "148",
    "annual_income": "308230",
    "career_growth": 2.2
  },
  {
    "name": "Construction Manager",
    "onet_occupation_id": "11-9021.00",
    "job_zone_id": 4,
    "hourly_income": "79",
    "annual_income": "165010",
    "career_growth": 4.5
  },
  {
    "name": "Auto Insurance Appraiser",
    "onet_occupation_id": "13-1032.00",
    "job_zone_id": 3,
    "hourly_income": "44",
    "annual_income": "90750",
    "career_growth": -2.6
  },
  {
    "name": "Musical Instrument Repairer",
    "onet_occupation_id": "49-9063.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "60960",
    "career_growth": -9.4
  },
  {
    "name": "Meat Packer",
    "onet_occupation_id": "51-3023.00",
    "job_zone_id": 2,
    "hourly_income": "20",
    "annual_income": "41160",
    "career_growth": -1
  },
  {
    "name": "Professional Athlete",
    "onet_occupation_id": "27-2021.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "358080",
    "career_growth": 9.1
  },
  {
    "name": "Travel Guide",
    "onet_occupation_id": "39-7012.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Automotive Glass Installer",
    "onet_occupation_id": "49-3022.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "54510",
    "career_growth": 1.7
  },
  {
    "name": "Electromechanical Equipment Assembler",
    "onet_occupation_id": "51-2023.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Correctional Officer",
    "onet_occupation_id": "33-3012.00",
    "job_zone_id": 2,
    "hourly_income": "41",
    "annual_income": "84520",
    "career_growth": -7.5
  },
  {
    "name": "Facilities Manager",
    "onet_occupation_id": "11-3013.00",
    "job_zone_id": 3,
    "hourly_income": "62",
    "annual_income": "128980",
    "career_growth": 4.6
  },
  {
    "name": "Nanosystems Engineer",
    "onet_occupation_id": "17-2199.09",
    "job_zone_id": 5,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Machine Feeder",
    "onet_occupation_id": "53-7063.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51800",
    "career_growth": 5.5
  },
  {
    "name": "Meter Reader",
    "onet_occupation_id": "43-5041.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "70120",
    "career_growth": -12.2
  },
  {
    "name": "Health Informatics Specialist",
    "onet_occupation_id": "15-1211.01",
    "job_zone_id": 4,
    "hourly_income": "62",
    "annual_income": "129330",
    "career_growth": 9.6
  },
  {
    "name": "Machinist",
    "onet_occupation_id": "51-4041.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "75240",
    "career_growth": 1.9
  },
  {
    "name": "Exercise Instructor",
    "onet_occupation_id": "39-9031.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "74220",
    "career_growth": 13.7
  },
  {
    "name": "Subway/Streetcar Operator",
    "onet_occupation_id": "53-4041.00",
    "job_zone_id": 2,
    "hourly_income": "38",
    "annual_income": "78820",
    "career_growth": 3.5
  },
  {
    "name": "Special Forces Officer",
    "onet_occupation_id": "55-1017.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Actor",
    "onet_occupation_id": "27-2011.00",
    "job_zone_id": 2,
    "hourly_income": "63",
    "annual_income": "0",
    "career_growth": 3.2
  },
  {
    "name": "Energy Engineer",
    "onet_occupation_id": "17-2199.03",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Photonics Engineer",
    "onet_occupation_id": "17-2199.07",
    "job_zone_id": 4,
    "hourly_income": "69",
    "annual_income": "143680",
    "career_growth": 3.3
  },
  {
    "name": "Medical Scientist",
    "onet_occupation_id": "19-1042.00",
    "job_zone_id": 5,
    "hourly_income": "68",
    "annual_income": "141440",
    "career_growth": 9.8
  },
  {
    "name": "Music Artist",
    "onet_occupation_id": "27-2042.00",
    "job_zone_id": 4,
    "hourly_income": "81",
    "annual_income": "0",
    "career_growth": 1.2
  },
  {
    "name": "Brewer/Winemaker",
    "onet_occupation_id": "51-9012.00",
    "job_zone_id": 2,
    "hourly_income": "40",
    "annual_income": "83150",
    "career_growth": 3.4
  },
  {
    "name": "Nurse Midwife",
    "onet_occupation_id": "29-1161.00",
    "job_zone_id": 5,
    "hourly_income": "82",
    "annual_income": "169530",
    "career_growth": 6.4
  },
  {
    "name": "Manufacturing Engineer",
    "onet_occupation_id": "17-2112.03",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "140020",
    "career_growth": 11.7
  },
  {
    "name": "Dental Hygienist",
    "onet_occupation_id": "29-1292.00",
    "job_zone_id": 3,
    "hourly_income": "55",
    "annual_income": "113770",
    "career_growth": 7.4
  },
  {
    "name": "Industrial Truck Operator",
    "onet_occupation_id": "53-7051.00",
    "job_zone_id": 2,
    "hourly_income": "32",
    "annual_income": "67020",
    "career_growth": 3.7
  },
  {
    "name": "Armored Assault Vehicle Officer",
    "onet_occupation_id": "55-1013.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Accountant",
    "onet_occupation_id": "13-2011.00",
    "job_zone_id": 4,
    "hourly_income": "53",
    "annual_income": "110750",
    "career_growth": 4.4
  },
  {
    "name": "Endoscopy Technician",
    "onet_occupation_id": "31-9099.02",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58360",
    "career_growth": 5.7
  },
  {
    "name": "Reservation Ticket Agent",
    "onet_occupation_id": "43-4181.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53650",
    "career_growth": 1.3
  },
  {
    "name": "Motorcycle Mechanic",
    "onet_occupation_id": "49-3052.00",
    "job_zone_id": 3,
    "hourly_income": "27",
    "annual_income": "56950",
    "career_growth": 1.8
  },
  {
    "name": "Surveying and Mapping Technician",
    "onet_occupation_id": "17-3031.00",
    "job_zone_id": 3,
    "hourly_income": "35",
    "annual_income": "73730",
    "career_growth": 3.3
  },
  {
    "name": "Medical Assistant",
    "onet_occupation_id": "31-9092.00",
    "job_zone_id": 3,
    "hourly_income": "24",
    "annual_income": "50350",
    "career_growth": 13.9
  },
  {
    "name": "Clinical Data Manager",
    "onet_occupation_id": "15-2051.02",
    "job_zone_id": 4,
    "hourly_income": "71",
    "annual_income": "147390",
    "career_growth": 35.2
  },
  {
    "name": "Stone Cutter",
    "onet_occupation_id": "51-9195.03",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55510",
    "career_growth": 2.4
  },
  {
    "name": "Cargo and Freight Agent",
    "onet_occupation_id": "43-5011.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "71440",
    "career_growth": 9.7
  },
  {
    "name": "Procurement Clerk",
    "onet_occupation_id": "43-3061.00",
    "job_zone_id": 2,
    "hourly_income": "29",
    "annual_income": "59570",
    "career_growth": -7.6
  },
  {
    "name": "Sewing Machine Operator",
    "onet_occupation_id": "51-6031.00",
    "job_zone_id": 1,
    "hourly_income": "21",
    "annual_income": "44550",
    "career_growth": -15.2
  },
  {
    "name": "Court Reporter",
    "onet_occupation_id": "27-3092.00",
    "job_zone_id": 3,
    "hourly_income": "51",
    "annual_income": "106340",
    "career_growth": 2.8
  },
  {
    "name": "Environmental Scientist",
    "onet_occupation_id": "19-2041.00",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "118430",
    "career_growth": 6.1
  },
  {
    "name": "Switchboard Operator",
    "onet_occupation_id": "43-2011.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "48550",
    "career_growth": -25.1
  },
  {
    "name": "Preventive Medicine Physician",
    "onet_occupation_id": "29-1229.05",
    "job_zone_id": 5,
    "hourly_income": "132",
    "annual_income": "274650",
    "career_growth": 3
  },
  {
    "name": "Anesthesiologist",
    "onet_occupation_id": "29-1211.00",
    "job_zone_id": 5,
    "hourly_income": "146",
    "annual_income": "302970",
    "career_growth": 2.6
  },
  {
    "name": "Architectural/Engineering Manager",
    "onet_occupation_id": "11-9041.00",
    "job_zone_id": 5,
    "hourly_income": "93",
    "annual_income": "193600",
    "career_growth": 4.1
  },
  {
    "name": "Transportation Planner",
    "onet_occupation_id": "19-3099.01",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "117750",
    "career_growth": 1.7
  },
  {
    "name": "Airline Pilot",
    "onet_occupation_id": "53-2011.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "252110",
    "career_growth": 3.9
  },
  {
    "name": "Billing Clerk",
    "onet_occupation_id": "43-3021.00",
    "job_zone_id": 3,
    "hourly_income": "35",
    "annual_income": "72460",
    "career_growth": 0
  },
  {
    "name": "Emergency Management Director",
    "onet_occupation_id": "11-9161.00",
    "job_zone_id": 4,
    "hourly_income": "78",
    "annual_income": "161790",
    "career_growth": 3.3
  },
  {
    "name": "Wind Energy Development Manager",
    "onet_occupation_id": "11-9199.10",
    "job_zone_id": 4,
    "hourly_income": "89",
    "annual_income": "184990",
    "career_growth": 3.3
  },
  {
    "name": "Janitor",
    "onet_occupation_id": "37-2011.00",
    "job_zone_id": 3,
    "hourly_income": "20",
    "annual_income": "41010",
    "career_growth": 1.3
  },
  {
    "name": "Service Sales Representative",
    "onet_occupation_id": "41-3091.00",
    "job_zone_id": 2,
    "hourly_income": "51",
    "annual_income": "107100",
    "career_growth": 4.1
  },
  {
    "name": "Boilermaker",
    "onet_occupation_id": "47-2011.00",
    "job_zone_id": 3,
    "hourly_income": "51",
    "annual_income": "105050",
    "career_growth": -4
  },
  {
    "name": "Survey Researcher",
    "onet_occupation_id": "19-3022.00",
    "job_zone_id": 5,
    "hourly_income": "54",
    "annual_income": "113150",
    "career_growth": -3.8
  },
  {
    "name": "Acute Care Nurse",
    "onet_occupation_id": "29-1141.01",
    "job_zone_id": 3,
    "hourly_income": "64",
    "annual_income": "133340",
    "career_growth": 5.6
  },
  {
    "name": "Office Clerk",
    "onet_occupation_id": "43-9061.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51700",
    "career_growth": -6.6
  },
  {
    "name": "Surgical Technologist",
    "onet_occupation_id": "29-2055.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "73960",
    "career_growth": 5.4
  },
  {
    "name": "Chemist",
    "onet_occupation_id": "19-2031.00",
    "job_zone_id": 4,
    "hourly_income": "63",
    "annual_income": "131080",
    "career_growth": 6.2
  },
  {
    "name": "Geography Professor",
    "onet_occupation_id": "25-1064.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "117620",
    "career_growth": 4.4
  },
  {
    "name": "Orthoptist",
    "onet_occupation_id": "29-1299.02",
    "job_zone_id": 5,
    "hourly_income": "70",
    "annual_income": "146440",
    "career_growth": 2
  },
  {
    "name": "Photo Lab Technician",
    "onet_occupation_id": "51-9151.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "69210",
    "career_growth": -12.5
  },
  {
    "name": "Psychiatrist",
    "onet_occupation_id": "29-1223.00",
    "job_zone_id": 5,
    "hourly_income": "124",
    "annual_income": "258580",
    "career_growth": 6.7
  },
  {
    "name": "Sales Manager",
    "onet_occupation_id": "11-2022.00",
    "job_zone_id": 4,
    "hourly_income": "111",
    "annual_income": "231380",
    "career_growth": 4
  },
  {
    "name": "Occupational Health and Safety Specialist",
    "onet_occupation_id": "19-5011.00",
    "job_zone_id": 4,
    "hourly_income": "48",
    "annual_income": "100200",
    "career_growth": 13.3
  },
  {
    "name": "Water/Wastewater Engineer",
    "onet_occupation_id": "17-2051.02",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "119260",
    "career_growth": 5
  },
  {
    "name": "Geological Technician",
    "onet_occupation_id": "19-4043.00",
    "job_zone_id": 4,
    "hourly_income": "57",
    "annual_income": "117800",
    "career_growth": 3.1
  },
  {
    "name": "Office Manager",
    "onet_occupation_id": "43-1011.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "87090",
    "career_growth": -5.2
  },
  {
    "name": "Locksmith",
    "onet_occupation_id": "49-9094.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "67740",
    "career_growth": -11.1
  },
  {
    "name": "Government Property Inspector",
    "onet_occupation_id": "13-1041.04",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "Animal Breeder",
    "onet_occupation_id": "45-2021.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58580",
    "career_growth": 2.1
  },
  {
    "name": "Foreign Language Professor",
    "onet_occupation_id": "25-1124.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "127720",
    "career_growth": 1.4
  },
  {
    "name": "Range Manager",
    "onet_occupation_id": "19-1031.02",
    "job_zone_id": 4,
    "hourly_income": "46",
    "annual_income": "96100",
    "career_growth": 4.1
  },
  {
    "name": "New Accounts Clerk",
    "onet_occupation_id": "43-4141.00",
    "job_zone_id": 3,
    "hourly_income": "33",
    "annual_income": "68290",
    "career_growth": -14.4
  },
  {
    "name": "Nurse Practitioner",
    "onet_occupation_id": "29-1171.00",
    "job_zone_id": 5,
    "hourly_income": "76",
    "annual_income": "158130",
    "career_growth": 44.5
  },
  {
    "name": "Travel Agent",
    "onet_occupation_id": "41-3041.00",
    "job_zone_id": 3,
    "hourly_income": "29",
    "annual_income": "60630",
    "career_growth": 3.5
  },
  {
    "name": "Information Security Analyst",
    "onet_occupation_id": "15-1212.00",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "138730",
    "career_growth": 31.5
  },
  {
    "name": "Actuary",
    "onet_occupation_id": "15-2011.00",
    "job_zone_id": 4,
    "hourly_income": "77",
    "annual_income": "160090",
    "career_growth": 23.2
  },
  {
    "name": "Radio/Broadcast Announcer",
    "onet_occupation_id": "27-3011.00",
    "job_zone_id": 4,
    "hourly_income": "75",
    "annual_income": "155730",
    "career_growth": -11.4
  },
  {
    "name": "Warehouse Worker",
    "onet_occupation_id": "53-7062.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46060",
    "career_growth": 5.3
  },
  {
    "name": "Machine Assembler",
    "onet_occupation_id": "51-2031.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "61630",
    "career_growth": -18.9
  },
  {
    "name": "Upholsterer",
    "onet_occupation_id": "51-6093.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53260",
    "career_growth": -2.5
  },
  {
    "name": "Communications Professor",
    "onet_occupation_id": "25-1122.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "129430",
    "career_growth": 3.4
  },
  {
    "name": "Tour Guide",
    "onet_occupation_id": "39-7011.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Recreation Attendant",
    "onet_occupation_id": "39-3091.00",
    "job_zone_id": 1,
    "hourly_income": "17",
    "annual_income": "36110",
    "career_growth": 4.2
  },
  {
    "name": "Cutting Machine Operator",
    "onet_occupation_id": "51-9032.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "48130",
    "career_growth": -4.1
  },
  {
    "name": "Construction Foreman",
    "onet_occupation_id": "47-1011.00",
    "job_zone_id": 3,
    "hourly_income": "47",
    "annual_income": "98600",
    "career_growth": 2.5
  },
  {
    "name": "Merchandise Displayer",
    "onet_occupation_id": "27-1026.00",
    "job_zone_id": 3,
    "hourly_income": "22",
    "annual_income": "45370",
    "career_growth": 2.4
  },
  {
    "name": "Building Inspector",
    "onet_occupation_id": "47-4011.00",
    "job_zone_id": 3,
    "hourly_income": "46",
    "annual_income": "94880",
    "career_growth": -1.5
  },
  {
    "name": "Ophthalmic Medical Technician",
    "onet_occupation_id": "29-2057.00",
    "job_zone_id": 3,
    "hourly_income": "28",
    "annual_income": "59140",
    "career_growth": 12.7
  },
  {
    "name": "Metal-Refining Furnace Operator",
    "onet_occupation_id": "51-4051.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "69160",
    "career_growth": -8.2
  },
  {
    "name": "Geodetic Surveyor",
    "onet_occupation_id": "17-1022.01",
    "job_zone_id": 4,
    "hourly_income": "48",
    "annual_income": "98990",
    "career_growth": 4.6
  },
  {
    "name": "Shoe Repairer",
    "onet_occupation_id": "51-6041.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47630",
    "career_growth": -11
  },
  {
    "name": "Usher",
    "onet_occupation_id": "39-3031.00",
    "job_zone_id": 2,
    "hourly_income": "18",
    "annual_income": "37100",
    "career_growth": 1.6
  },
  {
    "name": "Art Therapist",
    "onet_occupation_id": "29-1129.01",
    "job_zone_id": 5,
    "hourly_income": "65",
    "annual_income": "135180",
    "career_growth": 12.2
  },
  {
    "name": "Weatherization Installer",
    "onet_occupation_id": "47-4099.03",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Mail Clerk",
    "onet_occupation_id": "43-9051.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "48150",
    "career_growth": -4.7
  },
  {
    "name": "Nuclear Monitoring Technician",
    "onet_occupation_id": "19-4051.02",
    "job_zone_id": 3,
    "hourly_income": "52",
    "annual_income": "108520",
    "career_growth": -1.3
  },
  {
    "name": "Veterinary Assistant",
    "onet_occupation_id": "31-9096.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43190",
    "career_growth": 20.5
  },
  {
    "name": "Fleet Manager",
    "onet_occupation_id": "53-1043.00",
    "job_zone_id": 3,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Roofer",
    "onet_occupation_id": "47-2181.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72410",
    "career_growth": 2
  },
  {
    "name": "Hand Polishing Worker",
    "onet_occupation_id": "51-9022.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49730",
    "career_growth": -19.5
  },
  {
    "name": "Art/Drama/Music Professor",
    "onet_occupation_id": "25-1121.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "132490",
    "career_growth": 3.2
  },
  {
    "name": "Environmental Science Technician",
    "onet_occupation_id": "19-4042.00",
    "job_zone_id": 4,
    "hourly_income": "32",
    "annual_income": "66690",
    "career_growth": 5.8
  },
  {
    "name": "Mechanical Door Repairer",
    "onet_occupation_id": "49-9011.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58350",
    "career_growth": 9.6
  },
  {
    "name": "College Administrator",
    "onet_occupation_id": "11-9033.00",
    "job_zone_id": 5,
    "hourly_income": "81",
    "annual_income": "168370",
    "career_growth": 3.5
  },
  {
    "name": "Animal Trainer",
    "onet_occupation_id": "39-2011.00",
    "job_zone_id": 2,
    "hourly_income": "31",
    "annual_income": "63980",
    "career_growth": 16
  },
  {
    "name": "Tax Collector",
    "onet_occupation_id": "13-2081.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "92560",
    "career_growth": 0.6
  },
  {
    "name": "Payroll Clerk",
    "onet_occupation_id": "43-3051.00",
    "job_zone_id": 3,
    "hourly_income": "32",
    "annual_income": "67090",
    "career_growth": -16.4
  },
  {
    "name": "Hand Sewer",
    "onet_occupation_id": "51-6051.00",
    "job_zone_id": 2,
    "hourly_income": "21",
    "annual_income": "43840",
    "career_growth": -15.8
  },
  {
    "name": "Police Records Officer",
    "onet_occupation_id": "33-3021.02",
    "job_zone_id": 3,
    "hourly_income": "64",
    "annual_income": "133890",
    "career_growth": 1.5
  },
  {
    "name": "School Psychologist",
    "onet_occupation_id": "19-3034.00",
    "job_zone_id": 5,
    "hourly_income": "51",
    "annual_income": "106890",
    "career_growth": 1.3
  },
  {
    "name": "Fuel Cell Engineer",
    "onet_occupation_id": "17-2141.01",
    "job_zone_id": 4,
    "hourly_income": "61",
    "annual_income": "126080",
    "career_growth": 10
  },
  {
    "name": "HVAC Mechanic",
    "onet_occupation_id": "49-9021.00",
    "job_zone_id": 3,
    "hourly_income": "36",
    "annual_income": "74660",
    "career_growth": 5.5
  },
  {
    "name": "Pesticide Handler",
    "onet_occupation_id": "37-3012.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55200",
    "career_growth": 2
  },
  {
    "name": "Clergy",
    "onet_occupation_id": "21-2011.00",
    "job_zone_id": 5,
    "hourly_income": "39",
    "annual_income": "81560",
    "career_growth": 0.6
  },
  {
    "name": "Landscape Architect",
    "onet_occupation_id": "17-1012.00",
    "job_zone_id": 4,
    "hourly_income": "50",
    "annual_income": "104660",
    "career_growth": 0.7
  },
  {
    "name": "Rental Counter Clerk",
    "onet_occupation_id": "41-2021.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47550",
    "career_growth": 2.7
  },
  {
    "name": "Orderly",
    "onet_occupation_id": "31-1132.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "49520",
    "career_growth": 4.2
  },
  {
    "name": "Marriage and Family Therapist",
    "onet_occupation_id": "21-1013.00",
    "job_zone_id": 5,
    "hourly_income": "43",
    "annual_income": "88980",
    "career_growth": 14.9
  },
  {
    "name": "Real Estate Sales Agent",
    "onet_occupation_id": "41-9022.00",
    "job_zone_id": 3,
    "hourly_income": "46",
    "annual_income": "96070",
    "career_growth": 3.2
  },
  {
    "name": "Mixer Operator",
    "onet_occupation_id": "51-9023.00",
    "job_zone_id": 2,
    "hourly_income": "69",
    "annual_income": "142890",
    "career_growth": 1.5
  },
  {
    "name": "Parking Enforcement Worker",
    "onet_occupation_id": "33-3041.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "61550",
    "career_growth": -1.4
  },
  {
    "name": "Craft Artist",
    "onet_occupation_id": "27-1012.00",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "92980",
    "career_growth": 3.9
  },
  {
    "name": "Photographer",
    "onet_occupation_id": "27-4021.00",
    "job_zone_id": 3,
    "hourly_income": "39",
    "annual_income": "80400",
    "career_growth": 4.1
  },
  {
    "name": "General Manager",
    "onet_occupation_id": "11-1021.00",
    "job_zone_id": 4,
    "hourly_income": "86",
    "annual_income": "179190",
    "career_growth": 4.2
  },
  {
    "name": "English Professor",
    "onet_occupation_id": "25-1123.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "116880",
    "career_growth": 1.2
  },
  {
    "name": "Chemical Engineer",
    "onet_occupation_id": "17-2041.00",
    "job_zone_id": 4,
    "hourly_income": "71",
    "annual_income": "148150",
    "career_growth": 8.1
  },
  {
    "name": "Plasterer",
    "onet_occupation_id": "47-2161.00",
    "job_zone_id": 1,
    "hourly_income": "41",
    "annual_income": "86220",
    "career_growth": 2.1
  },
  {
    "name": "Sustainability Specialist",
    "onet_occupation_id": "13-1199.05",
    "job_zone_id": 4,
    "hourly_income": "52",
    "annual_income": "108370",
    "career_growth": 4.1
  },
  {
    "name": "Vehicle Inspector",
    "onet_occupation_id": "53-6051.07",
    "job_zone_id": 2,
    "hourly_income": "57",
    "annual_income": "117970",
    "career_growth": 2.7
  },
  {
    "name": "Hydroelectric Plant Technician",
    "onet_occupation_id": "51-8013.04",
    "job_zone_id": 3,
    "hourly_income": "56",
    "annual_income": "116410",
    "career_growth": -12.5
  },
  {
    "name": "Sociologist",
    "onet_occupation_id": "19-3041.00",
    "job_zone_id": 5,
    "hourly_income": "63",
    "annual_income": "131700",
    "career_growth": 4.5
  },
  {
    "name": "Office Machine Operator",
    "onet_occupation_id": "43-9071.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "47580",
    "career_growth": -14.2
  },
  {
    "name": "Electronic Equipment Assembler",
    "onet_occupation_id": "51-2022.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Decorator",
    "onet_occupation_id": "51-9123.00",
    "job_zone_id": 1,
    "hourly_income": "27",
    "annual_income": "55750",
    "career_growth": 0.1
  },
  {
    "name": "Bank Teller",
    "onet_occupation_id": "43-3071.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46510",
    "career_growth": -14.5
  },
  {
    "name": "Surveyor",
    "onet_occupation_id": "17-1022.00",
    "job_zone_id": 4,
    "hourly_income": "48",
    "annual_income": "98990",
    "career_growth": 4.6
  },
  {
    "name": "Packer",
    "onet_occupation_id": "53-7064.00",
    "job_zone_id": 2,
    "hourly_income": "18",
    "annual_income": "38250",
    "career_growth": -1
  },
  {
    "name": "Regulatory Affairs Specialist",
    "onet_occupation_id": "13-1041.07",
    "job_zone_id": 4,
    "hourly_income": "47",
    "annual_income": "98690",
    "career_growth": 4.6
  },
  {
    "name": "Fast Food and Counter Worker",
    "onet_occupation_id": "35-3023.00",
    "job_zone_id": 1,
    "hourly_income": "17",
    "annual_income": "36040",
    "career_growth": 1.5
  },
  {
    "name": "Computer Hardware Engineer",
    "onet_occupation_id": "17-2061.00",
    "job_zone_id": 4,
    "hourly_income": "80",
    "annual_income": "166940",
    "career_growth": 4.6
  },
  {
    "name": "Roofer's Helper",
    "onet_occupation_id": "47-3016.00",
    "job_zone_id": 2,
    "hourly_income": "26",
    "annual_income": "53350",
    "career_growth": 2
  },
  {
    "name": "Electronics Engineer",
    "onet_occupation_id": "17-2072.00",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "139240",
    "career_growth": 7.2
  },
  {
    "name": "Assembly Line Worker",
    "onet_occupation_id": "51-2092.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Plating Machine Operator",
    "onet_occupation_id": "51-4193.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "50650",
    "career_growth": -9.4
  },
  {
    "name": "Personal Financial Advisor",
    "onet_occupation_id": "13-2052.00",
    "job_zone_id": 4,
    "hourly_income": "110",
    "annual_income": "229730",
    "career_growth": 12.8
  },
  {
    "name": "Millwright",
    "onet_occupation_id": "49-9044.00",
    "job_zone_id": 2,
    "hourly_income": "36",
    "annual_income": "75530",
    "career_growth": 3
  },
  {
    "name": "Chemistry Professor",
    "onet_occupation_id": "25-1052.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "120210",
    "career_growth": 3.5
  },
  {
    "name": "Dentist",
    "onet_occupation_id": "29-1021.00",
    "job_zone_id": 5,
    "hourly_income": "99",
    "annual_income": "206270",
    "career_growth": 4.4
  },
  {
    "name": "Natural Sciences Manager",
    "onet_occupation_id": "11-9121.00",
    "job_zone_id": 5,
    "hourly_income": "109",
    "annual_income": "227460",
    "career_growth": 4.8
  },
  {
    "name": "Intelligence Analyst",
    "onet_occupation_id": "33-3021.06",
    "job_zone_id": 4,
    "hourly_income": "64",
    "annual_income": "133890",
    "career_growth": 1.5
  },
  {
    "name": "Substitute Teacher",
    "onet_occupation_id": "25-3031.00",
    "job_zone_id": 4,
    "hourly_income": "31",
    "annual_income": "63740",
    "career_growth": 4.1
  },
  {
    "name": "Pharmacy Aide",
    "onet_occupation_id": "31-9095.00",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46400",
    "career_growth": -2.8
  },
  {
    "name": "Geneticist",
    "onet_occupation_id": "19-1029.03",
    "job_zone_id": 5,
    "hourly_income": "55",
    "annual_income": "114940",
    "career_growth": 3.9
  },
  {
    "name": "Textile Weaving Machine Operator",
    "onet_occupation_id": "51-6063.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "52250",
    "career_growth": -10.5
  },
  {
    "name": "Oil and Gas Rotary Drill Operator",
    "onet_occupation_id": "47-5012.00",
    "job_zone_id": 2,
    "hourly_income": "37",
    "annual_income": "77440",
    "career_growth": 0.1
  },
  {
    "name": "Disc Jockey",
    "onet_occupation_id": "27-2091.00",
    "job_zone_id": 2,
    "hourly_income": "48",
    "annual_income": "0",
    "career_growth": 4.2
  },
  {
    "name": "Physics Professor",
    "onet_occupation_id": "25-1054.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "146850",
    "career_growth": 3.8
  },
  {
    "name": "Editor",
    "onet_occupation_id": "27-3041.00",
    "job_zone_id": 4,
    "hourly_income": "52",
    "annual_income": "107450",
    "career_growth": -4
  },
  {
    "name": "Costume Attendant",
    "onet_occupation_id": "39-3092.00",
    "job_zone_id": 2,
    "hourly_income": "35",
    "annual_income": "72510",
    "career_growth": 5
  },
  {
    "name": "Human Resource Manager",
    "onet_occupation_id": "11-3121.00",
    "job_zone_id": 4,
    "hourly_income": "92",
    "annual_income": "190340",
    "career_growth": 5.2
  },
  {
    "name": "Funeral Home Manager",
    "onet_occupation_id": "11-9171.00",
    "job_zone_id": 3,
    "hourly_income": "66",
    "annual_income": "138210",
    "career_growth": 2.2
  },
  {
    "name": "Physical Education (PE) Teacher",
    "onet_occupation_id": "25-2059.01",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "114810",
    "career_growth": 2.9
  },
  {
    "name": "Dishwasher",
    "onet_occupation_id": "35-9021.00",
    "job_zone_id": 1,
    "hourly_income": "18",
    "annual_income": "37370",
    "career_growth": -2.3
  },
  {
    "name": "Soil and Plant Scientist",
    "onet_occupation_id": "19-1013.00",
    "job_zone_id": 5,
    "hourly_income": "46",
    "annual_income": "95090",
    "career_growth": 4.7
  },
  {
    "name": "Underground Miner",
    "onet_occupation_id": "47-5044.00",
    "job_zone_id": 2,
    "hourly_income": "44",
    "annual_income": "92260",
    "career_growth": -17.7
  },
  {
    "name": "Elementary School Teacher",
    "onet_occupation_id": "25-2021.00",
    "job_zone_id": 4,
    "hourly_income": "0",
    "annual_income": "90470",
    "career_growth": 0.7
  },
  {
    "name": "Conveyor Operator",
    "onet_occupation_id": "53-7011.00",
    "job_zone_id": 1,
    "hourly_income": "30",
    "annual_income": "61590",
    "career_growth": 5.8
  },
  {
    "name": "Petroleum Engineer",
    "onet_occupation_id": "17-2171.00",
    "job_zone_id": 4,
    "hourly_income": "100",
    "annual_income": "208980",
    "career_growth": 2.5
  },
  {
    "name": "Substance Abuse and Behavioral Disorder Counselor",
    "onet_occupation_id": "21-1011.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Agricultural Inspector",
    "onet_occupation_id": "45-2011.00",
    "job_zone_id": 2,
    "hourly_income": "36",
    "annual_income": "74450",
    "career_growth": 2
  },
  {
    "name": "Tree Trimmer",
    "onet_occupation_id": "37-3013.00",
    "job_zone_id": 2,
    "hourly_income": "33",
    "annual_income": "67630",
    "career_growth": 2.5
  },
  {
    "name": "Web Developer",
    "onet_occupation_id": "15-1254.00",
    "job_zone_id": 3,
    "hourly_income": "65",
    "annual_income": "135490",
    "career_growth": 17
  },
  {
    "name": "Occupational Therapy Aide",
    "onet_occupation_id": "31-2012.00",
    "job_zone_id": 3,
    "hourly_income": "37",
    "annual_income": "77360",
    "career_growth": 4.9
  },
  {
    "name": "Parts Cleaner",
    "onet_occupation_id": "51-9192.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51580",
    "career_growth": 3.8
  },
  {
    "name": "Director of Religious Activities",
    "onet_occupation_id": "21-2021.00",
    "job_zone_id": 4,
    "hourly_income": "43",
    "annual_income": "89560",
    "career_growth": -0.2
  },
  {
    "name": "Police Officer",
    "onet_occupation_id": "33-3051.00",
    "job_zone_id": 3,
    "hourly_income": "50",
    "annual_income": "104010",
    "career_growth": 3.3
  },
  {
    "name": "Transportation Security Screener",
    "onet_occupation_id": "33-9093.00",
    "job_zone_id": 2,
    "hourly_income": "30",
    "annual_income": "62850",
    "career_growth": 0
  },
  {
    "name": "Electrician",
    "onet_occupation_id": "47-2111.00",
    "job_zone_id": 3,
    "hourly_income": "43",
    "annual_income": "88630",
    "career_growth": 6.4
  },
  {
    "name": "Biomass Plant Technician",
    "onet_occupation_id": "51-8013.03",
    "job_zone_id": 2,
    "hourly_income": "56",
    "annual_income": "116410",
    "career_growth": -12.5
  },
  {
    "name": "Wellhead Pumper",
    "onet_occupation_id": "53-7073.00",
    "job_zone_id": 2,
    "hourly_income": "34",
    "annual_income": "69950",
    "career_growth": -2.2
  },
  {
    "name": "Maintenance Supervisor",
    "onet_occupation_id": "49-1011.00",
    "job_zone_id": 3,
    "hourly_income": "43",
    "annual_income": "88630",
    "career_growth": 2.4
  },
  {
    "name": "Recycling and Reclamation Worker",
    "onet_occupation_id": "53-7062.04",
    "job_zone_id": 2,
    "hourly_income": "22",
    "annual_income": "46060",
    "career_growth": 5.3
  },
  {
    "name": "Supervisor of Laborers",
    "onet_occupation_id": "53-1042.00",
    "job_zone_id": 2,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Chemical Equipment Operator",
    "onet_occupation_id": "51-9011.00",
    "job_zone_id": 2,
    "hourly_income": "42",
    "annual_income": "88360",
    "career_growth": -5.5
  },
  {
    "name": "Neuropsychologist",
    "onet_occupation_id": "19-3039.02",
    "job_zone_id": 5,
    "hourly_income": "58",
    "annual_income": "120220",
    "career_growth": 5.1
  },
  {
    "name": "Fish and Game Warden",
    "onet_occupation_id": "33-3031.00",
    "job_zone_id": 4,
    "hourly_income": "41",
    "annual_income": "85650",
    "career_growth": -6.3
  },
  {
    "name": "Career Counselor",
    "onet_occupation_id": "21-1012.00",
    "job_zone_id": 5,
    "hourly_income": "39",
    "annual_income": "81100",
    "career_growth": 5.4
  },
  {
    "name": "Validation Engineer",
    "onet_occupation_id": "17-2112.02",
    "job_zone_id": 4,
    "hourly_income": "67",
    "annual_income": "140020",
    "career_growth": 11.7
  },
  {
    "name": "Medical Dosimetrist",
    "onet_occupation_id": "29-2036.00",
    "job_zone_id": 3,
    "hourly_income": "85",
    "annual_income": "176170",
    "career_growth": 3.4
  },
  {
    "name": "Special Forces Member",
    "onet_occupation_id": "55-3018.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Physician/Pathologist",
    "onet_occupation_id": "29-1222.00",
    "job_zone_id": 5,
    "hourly_income": "122",
    "annual_income": "252850",
    "career_growth": 4.6
  },
  {
    "name": "Data Entry Keyer",
    "onet_occupation_id": "43-9021.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51790",
    "career_growth": -26
  },
  {
    "name": "Injection Molding Machine Operator",
    "onet_occupation_id": "51-9195.00",
    "job_zone_id": 2,
    "hourly_income": "27",
    "annual_income": "55510",
    "career_growth": 2.4
  },
  {
    "name": "Customer Service Representative",
    "onet_occupation_id": "43-4051.00",
    "job_zone_id": 2,
    "hourly_income": "25",
    "annual_income": "51930",
    "career_growth": -5.5
  },
  {
    "name": "Philosophy/Religion Professor",
    "onet_occupation_id": "25-1126.00",
    "job_zone_id": 5,
    "hourly_income": "0",
    "annual_income": "132660",
    "career_growth": 2.7
  },
  {
    "name": "Distribution Manager",
    "onet_occupation_id": "11-3071.00",
    "job_zone_id": 4,
    "hourly_income": "68",
    "annual_income": "141490",
    "career_growth": 8.2
  },
  {
    "name": "Paper Goods Machine Operator",
    "onet_occupation_id": "51-9196.00",
    "job_zone_id": 2,
    "hourly_income": "28",
    "annual_income": "58800",
    "career_growth": -11
  },
  {
    "name": "Earth Driller",
    "onet_occupation_id": "47-5023.00",
    "job_zone_id": 2,
    "hourly_income": "36",
    "annual_income": "75910",
    "career_growth": 2.2
  },
  {
    "name": "Artillery and Missile Officer",
    "onet_occupation_id": "55-1014.00",
    "job_zone_id": null,
    "hourly_income": "0",
    "annual_income": "0",
    "career_growth": 0
  },
  {
    "name": "Parts Salesperson",
    "onet_occupation_id": "41-2022.00",
    "job_zone_id": 2,
    "hourly_income": "24",
    "annual_income": "48940",
    "career_growth": 1
  },
  {
    "name": "Robotics Technician",
    "onet_occupation_id": "17-3024.01",
    "job_zone_id": 3,
    "hourly_income": "45",
    "annual_income": "92770",
    "career_growth": -2.7
  },
  {
    "name": "Clinical Nurse Specialist",
    "onet_occupation_id": "29-1141.04",
    "job_zone_id": 5,
    "hourly_income": "64",
    "annual_income": "133340",
    "career_growth": 5.6
  },
  {
    "name": "Gambling Cage Worker",
    "onet_occupation_id": "43-3041.00",
    "job_zone_id": 2,
    "hourly_income": "23",
    "annual_income": "46900",
    "career_growth": -5.4
  },
  {
    "name": "Medical Equipment Repairer",
    "onet_occupation_id": "49-9062.00",
    "job_zone_id": 3,
    "hourly_income": "42",
    "annual_income": "86670",
    "career_growth": 13.2
  }
]